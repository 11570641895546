import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../../models/UserTypes';
import { QuizMode, TestType } from '../../models/SharedTypes';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from '../../utils/Analytics';
import { QuizProgress } from '../../utils/quiz/types';
import { QuizInitParams } from '../../hooks/useQuiz';

interface StudyOptionsComponentProps {
  userData: User;
  initializeQuizWithParams: (params: QuizInitParams) => Promise<QuizProgress>;
}

interface TestConfig {
  name: string;
  description: string;
  requiresSubscription: boolean;
  disabled?: boolean;
  modes: QuizMode[];          // Available quiz modes for this test
  defaultMode: QuizMode;      // Default mode to start with
}

// Map test types to their configurations
const TEST_CONFIGS: Record<TestType, TestConfig> = {
  [TestType.LCSW]: {
    name: 'LCSW Full Test',
    description: 'Complete LCSW practice exam with 170 questions',
    requiresSubscription: true,
    modes: [QuizMode.PRACTICE, QuizMode.FULL, QuizMode.CATEGORY],
    defaultMode: QuizMode.PRACTICE
  },
  [TestType.LCSW_FREE]: {
    name: 'LCSW Free Quiz',
    description: '30 free LCSW practice questions',
    requiresSubscription: false,
    modes: [QuizMode.PRACTICE],
    defaultMode: QuizMode.PRACTICE
  },
  [TestType.LMSW]: {
    name: 'LMSW Full Test',
    description: 'Complete LMSW practice exam with 170 questions',
    requiresSubscription: true,
    modes: [QuizMode.PRACTICE, QuizMode.FULL, QuizMode.CATEGORY],
    defaultMode: QuizMode.PRACTICE
  },
  [TestType.LBSW]: {
    name: 'LBSW Test (Coming Soon)',
    description: 'LBSW practice exam - Available soon!',
    requiresSubscription: true,
    disabled: true,
    modes: [QuizMode.PRACTICE],
    defaultMode: QuizMode.PRACTICE
  }
};

interface QuizConfirmationModalProps {
  testConfig: TestConfig;
  testType: TestType;
  onConfirm: () => void;
  onClose: () => void;
}

const QuizConfirmationModal: React.FC<QuizConfirmationModalProps> = ({
  testConfig,
  testType,
  onConfirm,
  onClose
}) => {
  return (
    <div className="fixed inset-0 bg-black/50 dark:bg-black/70 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg max-w-lg w-full p-6 shadow-xl">
        <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-4">
          Start {testConfig.name}?
        </h3>
        <p className="text-gray-600 dark:text-gray-400 mb-4">
          {testConfig.description}
        </p>

        <div className="flex space-x-3">
          <button
            onClick={onConfirm}
            className="flex-1 px-4 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 
                     dark:bg-blue-500 dark:hover:bg-blue-600 text-white"
          >
            Start Test
          </button>
          <button
            onClick={onClose}
            className="flex-1 border border-gray-300 dark:border-gray-600 
                     px-4 py-2 rounded-lg text-gray-700 dark:text-gray-300
                     hover:bg-gray-50 dark:hover:bg-gray-700"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const StudyOptionsComponent: React.FC<StudyOptionsComponentProps> = ({
  userData,
  initializeQuizWithParams,
}) => {
  const navigate = useNavigate();
  const [selectedTest, setSelectedTest] = useState<TestType | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleStartQuiz = async (testType: TestType) => {
    setError(null);
    const testConfig = TEST_CONFIGS[testType];
    
    if (testConfig.requiresSubscription && !userData.isSubscribed) {
      logAnalyticsEvent(ANALYTICS_EVENTS.QUIZ_TYPE_SELECTED, {
        source: 'study_options',
        quiz_id: 0,
        test_type: testType,
        quiz_mode: testConfig.defaultMode
      });
      navigate('/pricing');
      return;
    }

    if (testConfig.disabled) {
      return;
    }

    setSelectedTest(testType);
  };

  const handleConfirm = async () => {
    if (!selectedTest) return;

    setIsLoading(true);
    setError(null);

    try {
      const testConfig = TEST_CONFIGS[selectedTest];
      const progress = await initializeQuizWithParams({
        testType: selectedTest,
        mode: testConfig.defaultMode
      });

      logAnalyticsEvent(ANALYTICS_EVENTS.QUIZ_STARTED, {
        quiz_id: Number(progress.quizId),
        quiz_type: selectedTest,
        category: undefined
      });

      // Navigate using React Router instead of window.location
      navigate(`/quiz/${progress.quizId}`);
      
    } catch (error) {
      console.error('Error starting quiz:', error);
      
      let errorMessage = 'Failed to start quiz. Please try again.';
      if (error instanceof Error) {
        if (error.message.includes('process is not defined') || 
            error.message.includes('performance.memory')) {
          errorMessage = 'Unable to initialize quiz metrics. Please refresh the page and try again.';
        } else if (error.message.includes('Failed to save quiz progress')) {
          errorMessage = 'Unable to save quiz progress. Please check your connection and try again.';
        } else {
          errorMessage = error.message;
        }
      }
      
      setError(errorMessage);
      
      logAnalyticsEvent(ANALYTICS_EVENTS.QUIZ_START_ERROR, {
        test_type: selectedTest,
        quiz_mode: TEST_CONFIGS[selectedTest].defaultMode,
        error: error instanceof Error ? error.message : 'Unknown error',
        error_type: error instanceof Error ? error.name : 'Unknown',
        stack: error instanceof Error ? error.stack : undefined
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-gray-100">
        Available Tests
      </h2>
      
      {error && (
        <div className="mb-4 p-4 bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-100 rounded-lg">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {Object.entries(TEST_CONFIGS).map(([key, config]) => (
          <div
            key={key}
            className={`p-4 border rounded-lg ${
              config.disabled
                ? 'bg-gray-100 dark:bg-gray-700 cursor-not-allowed'
                : 'hover:border-blue-500 dark:hover:border-blue-400 cursor-pointer'
            }`}
          >
            <h3 className="font-semibold text-lg mb-2 text-gray-900 dark:text-gray-100">
              {config.name}
            </h3>
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">
              {config.description}
            </p>
            <button
              onClick={() => handleStartQuiz(key as TestType)}
              disabled={config.disabled || isLoading}
              className={`w-full px-4 py-2 rounded-lg text-white ${
                config.disabled || isLoading
                  ? 'bg-gray-400 cursor-not-allowed'
                  : config.requiresSubscription && !userData.isSubscribed
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-blue-600 hover:bg-blue-700'
              }`}
            >
              {isLoading 
                ? 'Starting...' 
                : config.disabled
                ? 'Coming Soon'
                : config.requiresSubscription && !userData.isSubscribed
                ? 'Subscribe to Access'
                : 'Start Test'}
            </button>
          </div>
        ))}
      </div>

      {selectedTest && (
        <QuizConfirmationModal
          testConfig={TEST_CONFIGS[selectedTest]}
          testType={selectedTest}
          onConfirm={handleConfirm}
          onClose={() => {
            setSelectedTest(null);
            setError(null);
          }}
        />
      )}
    </div>
  );
};

export default StudyOptionsComponent;