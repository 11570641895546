import { Question } from './QuizTypes'
import { AdaptiveMetrics, AdaptiveState } from './AdaptiveState';
import { logger } from '../utils/logger';
import { AdaptiveLearningEngine } from '../utils/adaptiveLearning';
import { QuizType, TestType, QuizMode } from './SharedTypes';

export interface User {
    id: string
    email: string
    profile: UserProfile | null;
    quizProgress: QuizProgress | null
    quizzes: Record<string, QuizProgress>
    testResults: Record<string, TestResult>
    wrongAnswers: WrongAnswer[]
    subscriptionStatus: string | ''
    adaptiveLearning?: AdaptiveMetrics;
    stripeCustomerId?: string;
    subscriptionId?: string;
    // Stats for dashboard
    totalQuestionsAttempted?: number;
    studyStreak?: number;
    masteryLevel?: number;
    averageScore?: number;
    scoreTrend?: {
        direction: 'up' | 'down';
        percentage: number;
    };
    completedTests?: number;
    totalStudyHours?: number;
    studyHoursTrend?: {
        direction: 'up' | 'down';
        percentage: number;
    };
}

export interface UserProfile {
  name: string;
  examDate: string | null;
  studyGoal: string;
  educationLevel?: EducationLevel;
  preferredStudyTime?: PreferredStudyTime;
  challengingTopics?: ChallengingTopic[];
  learningStyle?: LearningStyle;
  onboardingCompleted: boolean;
  lastUpdated: string;
}

export interface QuizProgress {
    quizId: string;
    currentQuestionIndex?: number;
    initialQuestionIndex?: number;
    score?: number;
    date?: string;
    wrongAnswers?: WrongAnswer[];
    questions?: Question[];
    questionIds?: string[];
    lastUpdated: string;
    type?: QuizType;        // @deprecated use testType and mode instead
    testType: TestType;     // The specific test being taken (LCSW, LMSW, etc.)
    mode: QuizMode;         // How the quiz is being taken (practice, full, category)
    category: string;
    completed?: boolean;
    lastQuestionStartTime?: number;
    questionTimes?: { [questionId: string]: number };
    adaptiveState: AdaptiveState;
    lastSavedIndex?: number; // Track the last successfully saved question index
}

export interface TestResult {
    id: string;
    date: string;
    score: number;
    totalQuestions: number;
    percentage: number;
    questions?: Question[];
    questionIds: string[];
    wrongAnswers: WrongAnswer[];
    type: QuizType;
    category?: string;
    timeTaken?: number;
    adaptiveMetrics: AdaptiveMetrics;
    adaptiveState?: AdaptiveState;
}

export interface WrongAnswer {
    question: {
        id: string;
        category: string;
        question: string;
        correct_answer: string;
        selected_answer?: string;
    };
    explanation: string;
    note?: string; 
    date: string;
    isReviewed?: boolean;
    attempts?: number;
}

export enum StudyGoal {
    PASS_EXAM = "Pass the LCSW exam",
    IMPROVE_SKILLS = "Improve clinical skills",
    CAREER_ADVANCEMENT = "Career advancement"
}

export enum EducationLevel {
    BACHELORS = "Bachelor's in Social Work",
    MASTERS = "Master's in Social Work",
    OTHER = "Other related degree"
}

export enum WorkExperience {
    LESS_THAN_2 = "Less than 2 years",
    TWO_TO_5 = "2-5 years",
    FIVE_TO_10 = "5-10 years",
    MORE_THAN_10 = "More than 10 years"
}

export enum PreferredStudyTime {
    MORNING = "Morning",
    AFTERNOON = "Afternoon",
    EVENING = "Evening",
    NIGHT = "Night"
}

export enum ChallengingTopic {
    ETHICS = "Ethics",
    DIAGNOSIS = "Diagnosis",
    TREATMENT_PLANNING = "Treatment Planning",
    HUMAN_DEVELOPMENT = "Human Development",
    PSYCHOTHERAPY = "Psychotherapy",
    DIVERSITY = "Diversity and Cultural Competence",
    RESEARCH = "Research and Program Evaluation"
}

export enum LearningStyle {
    VISUAL = "Visual",
    AUDITORY = "Auditory",
    READING_WRITING = "Reading/Writing",
    KINESTHETIC = "Kinesthetic"
}

// Convert interface to class for User
export class User {
    id: string;
    email: string;
    profile: UserProfile | null;
    quizProgress: QuizProgress | null;
    testResults: Record<string, TestResult> = {};
    wrongAnswers: WrongAnswer[];
    quizzes: Record<string, QuizProgress>;
    subscriptionStatus: string;
    adaptiveLearning?: AdaptiveMetrics;
    stripeCustomerId?: string;
    subscriptionId?: string;

    // Dashboard statistics
    totalQuestionsAttempted?: number;
    studyStreak?: number;
    masteryLevel?: number;
    averageScore?: number;
    scoreTrend?: {
        direction: 'up' | 'down';
        percentage: number;
    };
    completedTests?: number;
    totalStudyHours?: number;
    studyHoursTrend?: {
        direction: 'up' | 'down';
        percentage: number;
    };

    constructor(data: {
        id: string;
        email: string;
        profile: UserProfile | null;
        quizProgress: QuizProgress | null;
        testResults: Record<string, TestResult>;
        wrongAnswers: WrongAnswer[];
        quizzes: Record<string, QuizProgress>;
        subscriptionStatus: string;
        adaptiveLearning?: AdaptiveMetrics;
        stripeCustomerId?: string;
        subscriptionId?: string;
    }) {
        this.id = data.id;
        this.email = data.email;
        this.profile = data.profile;
        this.quizProgress = data.quizProgress;
        this.wrongAnswers = data.wrongAnswers || [];
        this.quizzes = data.quizzes || {};
        this.subscriptionStatus = data.subscriptionStatus;
        this.adaptiveLearning = data.adaptiveLearning;
        this.stripeCustomerId = data.stripeCustomerId;
        this.subscriptionId = data.subscriptionId;

        try {
            // Initialize dashboard statistics
            if (this.adaptiveLearning) {
                const engine = new AdaptiveLearningEngine(
                    this.profile,
                    this.testResults,
                    this.wrongAnswers
                );
                const dashboardStats = engine.calculateDashboardStats();
                
                // Update user stats
                this.totalQuestionsAttempted = dashboardStats.totalQuestionsAttempted;
                this.studyStreak = dashboardStats.studyStreak;
                this.masteryLevel = dashboardStats.masteryLevel;
                this.averageScore = dashboardStats.averageScore;
                this.scoreTrend = dashboardStats.scoreTrend;
                this.completedTests = dashboardStats.completedTests;
                this.totalStudyHours = dashboardStats.totalStudyHours;
                this.studyHoursTrend = dashboardStats.studyHoursTrend;
            }

            // Handle test results conversion with validation
            if (Array.isArray(data.testResults)) {
                this.testResults = Object.fromEntries(
                    data.testResults
                        .filter(result => {
                            const isValid = result && result.id;
                            if (!isValid) {
                                logger.warn('User', 'Invalid test result found', { result });
                            }
                            return isValid;
                        })
                        .map(result => {
                            // Ensure required properties exist
                            const safeResult: TestResult = {
                                id: result.id.toString(),
                                date: result.date || new Date().toISOString(),
                                score: result.score || 0,
                                totalQuestions: result.totalQuestions || 0,
                                percentage: result.percentage || 0,
                                questionIds: result.questionIds || result.questions?.map((q: Question) => q.id) || [],
                                wrongAnswers: result.wrongAnswers?.map((wa: WrongAnswer) => ({
                                    question: {
                                        id: wa.question.id,
                                        category: wa.question.category,
                                        question: wa.question.question,
                                        correct_answer: wa.question.correct_answer
                                    },
                                    explanation: wa.explanation || '',
                                    date: wa.date || new Date().toISOString(),
                                    isReviewed: wa.isReviewed || false,
                                    attempts: wa.attempts || 1,
                                    note: wa.note
                                })) || [],
                                type: result.type || QuizType.SAMPLE,
                                category: result.category || '',
                                timeTaken: result.timeTaken || 0,
                                adaptiveMetrics: result.adaptiveMetrics || {
                                    startingDifficulty: 5,
                                    endingDifficulty: 5,
                                    averageResponseTime: 0,
                                    categoryPerformance: {},
                                    overallSuccessRate: 0,
                                    totalQuestionsAttempted: 0,
                                    currentStreak: 0,
                                    longestStreak: 0,
                                    lastSessionDate: new Date().toISOString(),
                                    weeklyActivityStreak: 0,
                                    studyTimeThisWeek: 0,
                                    estimatedExamReadiness: 0,
                                    completedCategories: [],
                                    mastery: {
                                        beginner: [],
                                        intermediate: [],
                                        advanced: []
                                    },
                                    recentPerformance: [],
                                    preferredTimes: [],
                                    difficultyProgression: []
                                }
                            };
                            return [safeResult.id, safeResult];
                        })
                );
            }

            logger.debug('User', 'Test results processed', {
                resultCount: Object.keys(this.testResults).length,
                sampleResult: Object.values(this.testResults)[0]
            });

        } catch (error) {
            logger.error('User', 'Error constructing user', error);
            this.testResults = {};
        }
    }

    // Getter for subscription status
    get isSubscribed(): boolean {
        return this.subscriptionStatus === 'active' || this.subscriptionStatus === 'trialing';
    }

    // Optional: Add other useful getters
    get latestTestResult(): TestResult | null {
        const results = Object.values(this.testResults);
        if (!results.length) return null;
        return results.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0];
    }

    get hasCompletedOnboarding(): boolean {
        return !!this.profile?.onboardingCompleted;
    }

    // Get all wrong answers from both direct wrongAnswers and test results
    get totalWrongAnswers(): WrongAnswer[] {
        try {
            const cleanedWrongAnswers: WrongAnswer[] = [];
            
            // Safety check for test results
            if (!this.testResults || !Object.values(this.testResults).length) {
                logger.debug('User', 'No test results for wrong answers');
                return cleanedWrongAnswers;
            }

            // Process test results in reverse chronological order
            const sortedTests = Object.values(this.testResults)
                .filter(test => test && test.wrongAnswers)
                .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

            logger.debug('User', 'Processing wrong answers', {
                testCount: sortedTests.length,
                totalWrongAnswers: sortedTests.reduce((sum, test) => 
                    sum + (test.wrongAnswers?.length || 0), 0)
            });

            for (const test of sortedTests) {
                for (const wrongAnswer of test.wrongAnswers) {
                    const questionText = wrongAnswer?.question?.question;
                    if (!questionText) continue;

                    const existing = cleanedWrongAnswers.find(
                        wa => wa.question.question === questionText
                    );

                    if (!existing) {
                        cleanedWrongAnswers.push({
                            ...wrongAnswer,
                            attempts: this.countQuestionAttempts(questionText),
                            note: wrongAnswer.note // Highlight if there's a note
                        });
                        continue;
                    }

                    // Update existing wrong answer
                    existing.attempts = (existing.attempts || 0) + 1;
                    if (new Date(wrongAnswer.date) > new Date(existing.date)) {
                        existing.attempts++;
                    }
                    // Ensure highlighted if there's a note
                    existing.note = wrongAnswer.note;
                }
            }

            return cleanedWrongAnswers;
        } catch (error) {
            logger.error('User', 'Error calculating total wrong answers', error);
            return [];
        }
    }

    // Helper method to count attempts for a specific question
    private countQuestionAttempts(questionText: string): number {
        logger.debug('User', 'Counting question attempts', {
            questionText,
            hasWrongAnswers: !!this.wrongAnswers,
            isWrongAnswersArray: Array.isArray(this.wrongAnswers),
            hasTestResults: !!this.testResults
        });

        try {
            // Count from direct wrong answers with safety check
            const directWrongCount = Array.isArray(this.wrongAnswers)
                ? this.wrongAnswers.filter(wa => 
                    wa?.question?.question === questionText
                  ).length
                : 0;

            // Count from test results with safety checks
            const testWrongCount = this.testResults
                ? Object.values(this.testResults)
                    .reduce((acc, test) => {
                        if (!test?.wrongAnswers) return acc;
                        
                        const count = test.wrongAnswers.filter(wa => 
                            wa?.question?.question === questionText
                        ).length;
                        
                        return acc + count;
                    }, 0)
                : 0;

            logger.debug('User', 'Question attempt counts', {
                directWrongCount,
                testWrongCount,
                totalCount: directWrongCount + testWrongCount
            });

            return Math.max(directWrongCount + testWrongCount, 1); // Ensure at least 1 attempt

        } catch (error) {
            logger.error('User', 'Error counting question attempts', {
                error,
                questionText
            });
            return 1; // Safe default
        }
    }
}