export class QuizError extends Error {
  constructor(
    message: string,
    public code: string,
    public recoverable: boolean = true
  ) {
    super(message);
    this.name = 'QuizError';
  }
}

export const QuizErrorCodes = {
  SAVE_FAILED: 'QUIZ_SAVE_FAILED' as const,
  STATE_INCONSISTENT: 'QUIZ_STATE_INCONSISTENT' as const,
  PROGRESS_CORRUPTED: 'QUIZ_PROGRESS_CORRUPTED' as const,
  NETWORK_ERROR: 'QUIZ_NETWORK_ERROR' as const,
  INVALID_DATA: 'INVALID_DATA' as const,
  CONCURRENT_UPDATE: 'CONCURRENT_UPDATE' as const,
  USER_NOT_FOUND: 'USER_NOT_FOUND' as const,
  DOC_SIZE_EXCEEDED: 'DOC_SIZE_EXCEEDED' as const,
  DELETE_FAILED: 'QUIZ_DELETE_FAILED' as const,
  QUIZ_NOT_FOUND: 'QUIZ_NOT_FOUND' as const
} as const; 