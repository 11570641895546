import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import LoadingSpinner from './components/LoadingSpinner';
import { useAuth } from './contexts/AuthContext';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from './utils/Analytics';
import { logger } from './utils/logger';

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn, createUser } = useAuth();
  const pageLoadTime = useRef(Date.now());
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle');
  const [error, setError] = useState<string | null>(null);

  // Track page view
  useEffect(() => {
    logAnalyticsEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
      page_name: 'sign_in',
      is_authenticated: false,
      timestamp: new Date().toISOString(),
      referrer: document.referrer,
      mode: isSignUp ? 'signup' : 'signin',
      time_on_page: Date.now() - pageLoadTime.current
    });
  }, [isSignUp]);

  // Track mode changes
  useEffect(() => {
    if (status !== 'idle') {
      logAnalyticsEvent(ANALYTICS_EVENTS.AUTH_METHOD_SELECTED, {
        method: isSignUp ? 'signup' : 'signin',
        timestamp: Date.now()
      });
    }
  }, [isSignUp]);

  const handleAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('loading');
    setError(null);

    const authStartTime = Date.now();

    try {
      // Track auth start
      logAnalyticsEvent(ANALYTICS_EVENTS.AUTH_STARTED, {
        method: isSignUp ? 'signup' : 'signin',
        email_provided: !!email,
        has_referrer: !!location.search,
        timestamp: Date.now()
      });

      if (isSignUp) {
        if (password !== confirmPassword) {
          throw new Error('Passwords do not match.');
        }
        await createUser(email, password);
      } else {
        await signIn(email, password);
      }
      
      const params = new URLSearchParams(location.search);
      const from = params.get('from');
      const redirectPath = from ? decodeURIComponent(from) : '/dashboard';
      
      // Track successful auth
      logAnalyticsEvent(ANALYTICS_EVENTS.AUTH_COMPLETED, {
        method: isSignUp ? 'signup' : 'signin',
        time_taken: Date.now() - authStartTime,
        has_redirect: !!from,
        redirect_path: redirectPath,
        timestamp: Date.now()
      });

      // Track session start
      logAnalyticsEvent(ANALYTICS_EVENTS.AUTH_SESSION_STARTED, {
        method: isSignUp ? 'signup' : 'signin',
        timestamp: Date.now()
      });
      
      navigate(redirectPath, { replace: true });
      
    } catch (error) {
      logger.error('SignIn', 'Authentication error:', { error, isSignUp });
      setStatus('error');

      let errorMessage = 'An unexpected error occurred. Please try again.';
      let errorType = 'unknown_error';

      if (error instanceof Error) {
        switch (error.message) {
          case 'Firebase: Error (auth/user-not-found).':
            errorMessage = 'No account found with this email. Please check your email or sign up.';
            errorType = 'user_not_found';
            break;
          case 'Firebase: Error (auth/wrong-password).':
            errorMessage = 'Incorrect password. Please try again or reset your password.';
            errorType = 'wrong_password';
            break;
          case 'Firebase: Error (auth/invalid-email).':
            errorMessage = 'Invalid email address. Please enter a valid email.';
            errorType = 'invalid_email';
            break;
          case 'Firebase: Error (auth/user-disabled).':
            errorMessage = 'This account has been disabled. Please contact support.';
            errorType = 'user_disabled';
            break;
          default:
            errorMessage = 'An error occurred during sign in. Please try again.';
            errorType = 'other';
        }
      }

      setError(errorMessage);

      // Track auth error
      logAnalyticsEvent(ANALYTICS_EVENTS.AUTH_ERROR, {
        method: isSignUp ? 'signup' : 'signin',
        error_type: errorType,
        error_message: errorMessage,
        time_taken: Date.now() - authStartTime,
        email_provided: !!email,
        timestamp: Date.now()
      });
    }
  };

  return (
    <div className="new-home-container">
      <div className="new-home-content">
        <div className="new-home-inner">
          <form onSubmit={handleAuth} className="space-y-4">
            <h1 className="text-3xl font-bold mb-4 text-center text-gray-800 dark:text-gray-200">
              {isSignUp ? 'Sign Up' : 'Sign In'}
            </h1>
            {status === 'error' && (
              <div className="error-message bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative dark:bg-red-900 dark:border-red-700 dark:text-red-100" role="alert">
                <span className="block sm:inline">{error}</span>
              </div>
            )}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
              />
            </div>
            {isSignUp && (
              <div>
                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Confirm Password</label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
                />
              </div>
            )}
            {!isSignUp && (
              <div className="flex items-center justify-between">
                <Link to="/forgot-password" className="text-sm text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300">
                  Forgot your password?
                </Link>
              </div>
            )}
            <button
              type="submit"
              disabled={status === 'loading'}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600 disabled:opacity-50"
            >
              {status === 'loading' ? <LoadingSpinner /> : (isSignUp ? 'Sign Up' : 'Sign In')}
            </button>
          </form>
          <div className="mt-4 text-center">
            <p className="text-sm text-gray-600 dark:text-gray-400">
              {isSignUp ? 'Already have an account?' : 'Don\'t have an account?'}{' '}
              <button 
                onClick={() => setIsSignUp(!isSignUp)}
                className="font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300"
              >
                {isSignUp ? 'Sign in here' : 'Sign up here'}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;