import React from 'react';
import QuizSummary from '../QuizSummary';
import { ImprovementStatus, QuizProgress } from '../../utils/quiz/types';

const mockQuizProgress: QuizProgress = {
    questions: Array(20).fill(null).map((_, i) => ({
        id: i.toString(),
        question: `Mock Question ${i}`,
        correct_answer: 'A',
        selected_answer: Math.random() > 0.7 ? 'A' : 'B',
        category: ['Ethics', 'Assessment', 'Intervention', 'Human Development'][Math.floor(Math.random() * 4)],
        option_a: 'Option A',
        option_b: 'Option B',
        option_c: 'Option C',
        option_d: 'Option D',
        explanation: 'Explanation for the question',
        hint: 'Hint for the question',
        difficulty_level: 'Easy',
        difficulty: 1,
        recommendedTime: 45
    })),
    completed: true,
    currentQuestionIndex: 19,
    adaptiveState: {
        categoryStrengths: {
            'Ethics': { totalCount: 5, correctCount: 4, category: 'Ethics', averageTime: 45, lastAttempted: new Date() },
            'Assessment': { totalCount: 5, correctCount: 3, category: 'Assessment', averageTime: 45, lastAttempted: new Date() },
            'Intervention': { totalCount: 5, correctCount: 2, category: 'Intervention', averageTime: 45, lastAttempted: new Date() },
            'Human Development': { totalCount: 5, correctCount: 4, category: 'Human Development', averageTime: 45, lastAttempted: new Date() }
        },
        insights: {
            timeManagement: {
                averageQuestionTime: 45,
                timeManagementAdvice: 'You are maintaining a good pace',
                recommendedTimePerQuestion: 45
            },
            weakCategories: [
                { category: 'Intervention', successRate: 0.4, recommendedFocus: ['Intervention'] },
                { category: 'Assessment', successRate: 0.6, recommendedFocus: ['Assessment'] }
            ],
            recommendedDifficulty: 5,
            suggestedPracticeFrequency: '3x/week',
            performanceTrend: 'up' as ImprovementStatus
        }
    }
};

const QuizSummaryPreview: React.FC = () => {
    return (
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900 py-8">
            <QuizSummary
                quizProgress={mockQuizProgress}
                timeSpent={900}
                onRestartQuiz={() => window.history.back()}
                showFeedbackButton={true}
            />
        </div>
    );
};

export default QuizSummaryPreview; 