import { useNavigate } from 'react-router-dom';
import { getAllActiveQuizzes, deleteQuiz } from '../../utils/firebase/quiz';
import React, { useState, useEffect, useCallback } from 'react';
import { QuizProgress } from '../../models/UserTypes';
import { TestType } from '../../models/SharedTypes';
import { BookOpen, Target, Folder, Trash2, MoreVertical } from 'lucide-react';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from '../../utils/Analytics';
import { useAuth } from '../../contexts/AuthContext';
import { logger } from '../../utils/logger';
import { QuizError, QuizErrorCodes } from '../../utils/quiz/errors';
import { toast } from 'react-hot-toast';

interface QuizActionsProps {
    isSubscribed: boolean;
}

const QuizActions: React.FC<QuizActionsProps> = ({ isSubscribed }) => {
    const navigate = useNavigate();
    const { currentUser, userData } = useAuth();
    const [activeQuizzes, setActiveQuizzes] = useState<QuizProgress[]>([]);
    const [isDeleting, setIsDeleting] = useState<string | null>(null);
    const [selectedQuiz, setSelectedQuiz] = useState<string | null>(null);

    const loadActiveQuizzes = useCallback(async () => {
        if (!userData) return;
        try {
            logger.debug('QuizActions', 'Loading active quizzes', {
                userId: currentUser?.uid,
                hasUserData: !!userData
            });

            const activeQuizzesList = await getAllActiveQuizzes(currentUser?.uid ?? '');
            
            logger.debug('QuizActions', 'Active quizzes loaded', {
                count: activeQuizzesList.length,
                quizIds: activeQuizzesList.map(q => q.quizId)
            });

            const sortedQuizzes = activeQuizzesList.sort(
                (a, b) => new Date(b.lastUpdated || b.date || '').getTime() - new Date(a.lastUpdated || a.date || '').getTime()
            );
            setActiveQuizzes(sortedQuizzes);
        } catch (error) {
            logger.error('QuizActions', 'Error loading active quizzes', { error });
            setActiveQuizzes([]);
        }
    }, [userData, currentUser?.uid]);

    useEffect(() => {
        loadActiveQuizzes();
        
        // Refresh active quizzes every 30 seconds
        const intervalId = setInterval(loadActiveQuizzes, 30000);
        
        return () => clearInterval(intervalId);
    }, [loadActiveQuizzes]);

    const handleDeleteQuiz = async (quizId: string) => {
        try {
            setIsDeleting(quizId);
            await deleteQuiz(quizId);
            setActiveQuizzes(prev => prev.filter(quiz => quiz.quizId !== quizId));
            toast.success('Quiz deleted successfully');
            logAnalyticsEvent(ANALYTICS_EVENTS.DELETE_QUIZ, {
                quizId,
                success: true
            });
        } catch (error) {
            let message = 'Failed to delete quiz';
            if (error instanceof QuizError) {
                switch (error.code) {
                    case QuizErrorCodes.QUIZ_NOT_FOUND:
                        message = 'Quiz not found';
                        break;
                    case QuizErrorCodes.DELETE_FAILED:
                        message = 'Unable to delete quiz';
                        break;
                }
            }
            toast.error(message);
            logAnalyticsEvent(ANALYTICS_EVENTS.DELETE_QUIZ, {
                quizId,
                success: false,
                error: error instanceof Error ? error.message : 'Unknown error'
            });
        } finally {
            setIsDeleting(null);
            setSelectedQuiz(null);
        }
    };

    const getQuizIcon = (type: TestType) => {
        switch (type) {
            case TestType.LCSW:
                return <BookOpen className="w-5 h-5" />;
            case TestType.LMSW:
                return <BookOpen className="w-5 h-5" />;
            case TestType.LBSW:
                return <BookOpen className="w-5 h-5" />;
            case TestType.LCSW_FREE:
                return <Target className="w-5 h-5" />;
            default:
                return <Target className="w-5 h-5" />;
        }
    };

    const getQuizLabel = (quiz: QuizProgress) => {
        switch (quiz.testType) {
            case TestType.LCSW:
                return 'LCSW 170 Question Exam';
            case TestType.LMSW:
                return 'LMSW 170 Question Exam';
            case TestType.LCSW_FREE:
                return 'LCSW 30 Question Practice Quiz';
            case TestType.LBSW:
                return 'LBSW 170 Question Exam';
            default:
                return 'Quiz';
        }
    };

    const handleContinueQuiz = useCallback((quizId: string) => {
        const quiz = activeQuizzes.find(q => q.quizId === quizId);
        
        if (!quiz) {
            logger.warn('QuizActions', 'Quiz not found', { quizId });
            return;
        }
        
        if (quiz.completed) {
            logger.warn('QuizActions', 'Attempting to continue completed quiz', {
                quizId,
                completed: quiz.completed
            });
            return;
        }

        logAnalyticsEvent(ANALYTICS_EVENTS.CONTINUE_SAVED_QUIZ, {
            quizId,
            quizType: quiz.type || 'sample',
            progress: Math.round((quiz.currentQuestionIndex || 0) / (quiz.questionIds?.length || 1) * 100),
            questionCount: quiz.questionIds?.length || 0,
            timeElapsed: quiz.date ? Date.now() - new Date(quiz.date).getTime() : 0,
            isSubscribed
        });
        
        navigate(`/quiz/${quizId}`);
    }, [activeQuizzes, navigate, isSubscribed]);

    return (
        <div className="h-full overflow-hidden flex flex-col">
            {activeQuizzes.length > 0 && (
                <div className="flex-1 min-h-0">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-3">
                        Continue Previous Quizzes ({activeQuizzes.filter(q => !q.completed).length})
                    </h3>
                    <div className="overflow-y-auto max-h-[150px] pr-2 space-y-2">
                        {activeQuizzes
                            .filter(quiz => !quiz.completed)
                            .map((quiz) => (
                                <div
                                    key={quiz.quizId}
                                    className="relative group w-full text-left p-3 bg-white dark:bg-gray-800 rounded-lg 
                                             shadow hover:shadow-md transition-all border border-gray-200 
                                             dark:border-gray-700"
                                >
                                    <div 
                                        onClick={() => handleContinueQuiz(quiz.quizId)}
                                        className="flex-1 w-full cursor-pointer"
                                    >
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center space-x-3 min-w-0">
                                                <div className="text-gray-600 dark:text-gray-400 flex-shrink-0">
                                                    {getQuizIcon(quiz.testType)}
                                                </div>
                                                <div className="min-w-0">
                                                    <div className="font-medium text-gray-900 dark:text-gray-100 truncate">
                                                        {getQuizLabel(quiz)}
                                                    </div>
                                                    <div className="text-xs text-gray-500 dark:text-gray-400">
                                                        Started {quiz.date ? new Date(quiz.date).toLocaleDateString() : ''}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* More options button - Now outside the clickable area */}
                                            <div className="relative ml-2" onClick={e => e.stopPropagation()}>
                                                <button
                                                    onClick={() => setSelectedQuiz(selectedQuiz === quiz.quizId ? null : quiz.quizId)}
                                                    className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 
                                                             text-gray-500 dark:text-gray-400 transition-colors"
                                                    aria-label="More options"
                                                >
                                                    <MoreVertical className="w-4 h-4" />
                                                </button>
                                                
                                                {selectedQuiz === quiz.quizId && (
                                                    <div className="absolute right-0 mt-1 w-48 bg-white dark:bg-gray-800 
                                                                  rounded-md shadow-lg border border-gray-200 dark:border-gray-700 
                                                                  z-10 py-1">
                                                        <button
                                                            onClick={() => handleDeleteQuiz(quiz.quizId)}
                                                            disabled={isDeleting === quiz.quizId}
                                                            className="w-full px-4 py-2 text-left text-sm text-red-600 
                                                                     dark:text-red-400 hover:bg-gray-100 dark:hover:bg-gray-700 
                                                                     flex items-center space-x-2 disabled:opacity-50"
                                                        >
                                                            <Trash2 className="w-4 h-4" />
                                                            <span>
                                                                {isDeleting === quiz.quizId ? 'Deleting...' : 'Delete Quiz'}
                                                            </span>
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mt-2 flex items-center justify-between border-t border-gray-100 dark:border-gray-700 pt-2">
                                            <div className="text-xs text-gray-500 dark:text-gray-400">
                                                Question {quiz.currentQuestionIndex ?? 0} of {quiz.questionIds?.length ?? 0}
                                            </div>
                                            <div className="text-sm font-medium text-blue-600 dark:text-blue-400">
                                                {quiz.currentQuestionIndex ? Math.round((quiz.currentQuestionIndex / (quiz.questionIds?.length || 1)) * 100) : 0}% Complete
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuizActions; 
