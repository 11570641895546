import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaSun, FaMoon, FaSignInAlt, FaSignOutAlt } from 'react-icons/fa';
import useDarkMode from './hooks/useDarkMode';
import { useAuth } from './contexts/AuthContext';

const Header: React.FC = () => {
  const [quizInProgress, setQuizInProgress] = useState(false);
  const [currentQuizId, setCurrentQuizId] = useState<number | null>(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isDarkMode, toggleDarkMode: toggleTheme } = useDarkMode();
  const navigate = useNavigate();
  const location = useLocation();
  
  const { currentUser, userData, logOut } = useAuth();
  const isLoggedIn = !!currentUser;
  const isSubscribed = userData?.isSubscribed ?? false

  const checkQuizStatus = useCallback(async () => {
    try {
      // const inProgress = await getActiveQuiz();
      // setQuizInProgress(inProgress.length !== 0);
      // setCurrentQuizId(inProgress[0].quizId);
    } catch (error) {
      console.error('Error checking quiz status:', error);
    }
  }, []);

  useEffect(() => {
    checkQuizStatus();

    const handleQuizProgressUpdate = () => {
      checkQuizStatus();
    };

    const handleQuizCompleted = () => {
      setQuizInProgress(false);
      setCurrentQuizId(null);
    };

    window.addEventListener('quizProgressUpdated', handleQuizProgressUpdate);
    window.addEventListener('storage', checkQuizStatus);
    window.addEventListener('quizDataCleared', checkQuizStatus);
    window.addEventListener('quizCompleted', handleQuizCompleted);

    return () => {
      window.removeEventListener('quizProgressUpdated', handleQuizProgressUpdate);
      window.removeEventListener('storage', checkQuizStatus);
      window.removeEventListener('quizDataCleared', checkQuizStatus);
      window.removeEventListener('quizCompleted', handleQuizCompleted);
    };
  }, [checkQuizStatus]);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  const handleSignInOut = async () => {
    if (isLoggedIn) {
      try {
        await logOut();
        navigate('/');
      } catch (error) {
        console.error('Error signing out:', error);
      }
    } else {
      navigate(isSubscribed ? '/dashboard' : '/signin');
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const handleContinueQuiz = () => {
    if (currentQuizId) {
      navigate(`/quiz/${currentQuizId}`);
    } else {
      navigate('/');
    }
    closeMobileMenu();
  };

  const handleClearData = async () => {
    // await clearAllQuizData();
    setQuizInProgress(false);
    setCurrentQuizId(null);
    alert('All quiz data has been cleared.');
  };

  useEffect(() => {
    const checkSubscription = async () => {
      if (isLoggedIn) {
        if (isSubscribed && window.location.pathname === '/') {
          navigate('/dashboard');
        }
      }
    };
    
    checkSubscription();
  }, [isLoggedIn, navigate]);

  const handleDarkModeToggle = () => {
    toggleTheme();
    closeMobileMenu();
  };

  const isActivePath = (path: string) => {
    return location.pathname === path;
  };

  return (
    <div className="sticky top-0 z-50 w-full">
      <header className="bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <Link 
                to={isLoggedIn ? "/dashboard" : "/"} 
                onClick={closeMobileMenu} 
                className="flex items-center space-x-2"
              >
                <span className="text-xl font-bold text-gray-900 dark:text-white">
                  Social Work Test Prep Academy
                </span>
              </Link>
            </div>

            <nav className="hidden md:flex items-center space-x-4">
              {isLoggedIn && (
                <>
                  <Link 
                    to="/dashboard" 
                    className={`nav-link ${isActivePath('/dashboard') 
                      ? 'text-blue-600 dark:text-blue-400 font-semibold' 
                      : 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white'
                    }`}
                  >
                    Dashboard
                  </Link>
                  <div className="h-4 w-px bg-gray-300 dark:bg-gray-600"></div>
                </>
              )}
              
              <Link 
                to="/" 
                className={`nav-link ${isActivePath('/') 
                  ? 'text-blue-600 dark:text-blue-400 font-semibold' 
                  : 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white'
                }`}
              >
                Home
              </Link>
              
              <Link 
                to="/about" 
                className={`nav-link ${isActivePath('/about') 
                  ? 'text-blue-600 dark:text-blue-400 font-semibold' 
                  : 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white'
                }`}
              >
                About Us
              </Link>
              
              <Link 
                to="/pricing" 
                className={`nav-link ${isActivePath('/pricing') 
                  ? 'text-blue-600 dark:text-blue-400 font-semibold' 
                  : 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white'
                }`}
              >
                Pricing
              </Link>

              {isLoggedIn && (
                <>
                  <div className="h-4 w-px bg-gray-300 dark:bg-gray-600"></div>
                  <Link 
                    to="/settings" 
                    className={`nav-link ${isActivePath('/settings') 
                      ? 'text-blue-600 dark:text-blue-400 font-semibold' 
                      : 'text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white'
                    }`}
                  >
                    Settings
                  </Link>
                  {quizInProgress && (
                    <button 
                      onClick={handleContinueQuiz}
                      className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Continue Quiz
                    </button>
                  )}
                </>
              )}

              <div className="flex items-center space-x-4 ml-4">
                <button
                  onClick={handleDarkModeToggle}
                  className="p-2 text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  aria-label="Toggle dark mode"
                >
                  {isDarkMode ? <FaSun className="w-5 h-5" /> : <FaMoon className="w-5 h-5" />}
                </button>

                {!isLoggedIn && (
                  <Link
                    to="/signin"
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <FaSignInAlt className="mr-2" />
                    Login
                  </Link>
                )}

                <button
                  onClick={handleSignInOut}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  {isLoggedIn ? (
                    <>
                      <FaSignOutAlt className="mr-2" />
                      Sign Out
                    </>
                  ) : (
                    <>
                      <FaSignInAlt className="mr-2" />
                      Sign Up
                    </>
                  )}
                </button>
              </div>
            </nav>

            <button 
              className="md:hidden p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700"
              onClick={toggleMobileMenu}
            >
              <span className="sr-only">Open menu</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        <div className={`md:hidden ${mobileMenuOpen ? 'block' : 'hidden'}`}>
          <div className="px-2 pt-2 pb-3 space-y-1">
            {isLoggedIn && (
              <Link
                to="/dashboard"
                className={`block px-3 py-2 rounded-md text-base font-medium ${
                  isActivePath('/dashboard')
                    ? 'text-blue-600 dark:text-blue-400 bg-blue-50 dark:bg-blue-900'
                    : 'text-gray-700 dark:text-gray-300 hover:text-gray-900 hover:bg-gray-50 dark:hover:bg-gray-700'
                }`}
                onClick={closeMobileMenu}
              >
                Dashboard
              </Link>
            )}
            
            <Link
              to="/"
              className={`block px-3 py-2 rounded-md text-base font-medium ${
                isActivePath('/')
                  ? 'text-blue-600 dark:text-blue-400 bg-blue-50 dark:bg-blue-900'
                  : 'text-gray-700 dark:text-gray-300 hover:text-gray-900 hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
              onClick={closeMobileMenu}
            >
              Home
            </Link>
            
            <Link
              to="/about"
              className={`block px-3 py-2 rounded-md text-base font-medium ${
                isActivePath('/about')
                  ? 'text-blue-600 dark:text-blue-400 bg-blue-50 dark:bg-blue-900'
                  : 'text-gray-700 dark:text-gray-300 hover:text-gray-900 hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
              onClick={closeMobileMenu}
            >
              About Us
            </Link>
            
            <Link
              to="/pricing"
              className={`block px-3 py-2 rounded-md text-base font-medium ${
                isActivePath('/pricing')
                  ? 'text-blue-600 dark:text-blue-400 bg-blue-50 dark:bg-blue-900'
                  : 'text-gray-700 dark:text-gray-300 hover:text-gray-900 hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
              onClick={closeMobileMenu}
            >
              Pricing
            </Link>

            {isLoggedIn && (
              <>
                <Link
                  to="/settings"
                  className={`block px-3 py-2 rounded-md text-base font-medium ${
                    isActivePath('/settings')
                      ? 'text-blue-600 dark:text-blue-400 bg-blue-50 dark:bg-blue-900'
                      : 'text-gray-700 dark:text-gray-300 hover:text-gray-900 hover:bg-gray-50 dark:hover:bg-gray-700'
                  }`}
                  onClick={closeMobileMenu}
                >
                  Settings
                </Link>
                {quizInProgress && (
                  <button
                    onClick={() => {
                      handleContinueQuiz();
                      closeMobileMenu();
                    }}
                    className="w-full text-left px-3 py-2 text-base font-medium text-blue-600 dark:text-blue-400 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md"
                  >
                    Continue Quiz
                  </button>
                )}
              </>
            )}

            <div className="px-3 py-2 space-y-2">
              <button
                onClick={() => {
                  handleDarkModeToggle();
                  closeMobileMenu();
                }}
                className="w-full flex items-center px-3 py-2 text-base font-medium text-gray-700 dark:text-gray-300 hover:text-gray-900 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md"
              >
                {isDarkMode ? (
                  <>
                    <FaSun className="w-5 h-5 mr-3" />
                    Light Mode
                  </>
                ) : (
                  <>
                    <FaMoon className="w-5 h-5 mr-3" />
                    Dark Mode
                  </>
                )}
              </button>

              <button
                onClick={() => {
                  handleSignInOut();
                  closeMobileMenu();
                }}
                className="w-full flex items-center px-3 py-2 text-base font-medium text-gray-700 dark:text-gray-300 hover:text-gray-900 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md"
              >
                {isLoggedIn ? (
                  <>
                    <FaSignOutAlt className="w-5 h-5 mr-3" />
                    Sign Out
                  </>
                ) : (
                  <>
                    <FaSignInAlt className="w-5 h-5 mr-3" />
                    Sign In
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;