import { getAnalytics, logEvent, Analytics } from 'firebase/analytics';
import { app } from '../firebase';
import { logger } from '../utils/logger';

// Define strict types for analytics events and parameters
export type AnalyticsEventName = keyof typeof ANALYTICS_EVENTS;

interface AnalyticsEventParams {
  quiz_started: {
    quiz_id: number;
    quiz_type: string;
    category?: string;
  };
  quiz_completed: {
    quiz_id: number;
    score: number;
    totalQuestions: number;
    percentage: number;
    timeSpent: number;
    questionIds: number[];
  };
  question_answered: {
    questionId: number;
    isCorrect: boolean;
    timeSpent: number;
    attemptCount: number;
    hintUsed: boolean;
  };
  error_occurred: {
    error_type: string;
    error_message: string;
    stack_trace?: string;
    component?: string;
    quiz_id?: number;
    question_id?: number;
    userId?: string;
    timestamp?: number;
    time_taken?: number;
  };
  page_viewed: {
    page_name: string;
    is_authenticated: boolean;
    timestamp: string;
    referrer?: string;
    user_id?: string;
    mode?: string;
    time_on_page?: number;
    viewport?: {
      width: number;
      height: number;
    };
    loadTime?: number;
  };
  settings_update_profile: {
    userId: string;
    timestamp: number;
  };
  settings_manage_subscription: {
    userId: string;
    currentStatus: string;
    timestamp: number;
  };
  settings_change_password: {
    userId: string;
    timestamp: number;
  };
  settings_send_feedback: {
    userId: string;
    feedbackLength: number;
    timestamp: number;
  };
  settings_page_viewed: {
    userId: string;
    isSubscribed: boolean;
    timestamp: number;
  };
  delete_quiz: {
    quizId: string | number;
    success: boolean;
    error?: string;
  };
  subscription_step: {
    step: string;
    source?: string;
    status?: string;
    userId?: string;
    timestamp: number;
    timeOnPage?: number;
    isSubscribed?: boolean;
    loadTime?: number;
  };
  subscription_clicked: {
    subscriptionStatus: string | null;
    isLoggedIn: boolean;
    source?: string;
    timestamp?: number;
    timeOnPage?: number;
  };
  auth_started: {
    method: string;
    email_provided: boolean;
    has_referrer: boolean;
    timestamp: number;
  };
  auth_completed: {
    method: string;
    time_taken: number;
    has_redirect: boolean;
    redirect_path: string;
    timestamp: number;
  };
  auth_error: {
    method: string;
    error_type: string;
    error_message: string;
    time_taken: number;
    email_provided: boolean;
    timestamp: number;
  };
  auth_method_selected: {
    method: string;
    timestamp: number;
  };
  scroll_depth: {
    depth: number;
    timestamp: number;
    timeOnPage: number;
  };
  feature_usage: {
    feature: string;
    action: string;
    timestamp: number;
    timeOnPage: number;
  };
  [key: string]: any;
}

let analyticsInstance: Analytics | null = null;

try {
  analyticsInstance = getAnalytics(app);
} catch (error) {
  logger.error('Analytics', 'Failed to initialize analytics:', error);
}

// Define all analytics events as a const object or enum
export const ANALYTICS_EVENTS = {
  QUIZ_STARTED: 'quiz_started' as const,
  QUIZ_COMPLETED: 'quiz_completed' as const,
  QUIZ_START_ERROR: 'quiz_start_error' as const,
  QUESTION_ANSWERED: 'question_answered' as const,
  PROFILE_VIEWED: 'profile_viewed',
  RETAKE_QUIZ: 'retake_quiz',
  CONTINUE_SAVED_QUIZ: 'continue_saved_quiz',
  DELETE_QUIZ: 'delete_quiz' as const,
  ONBOARDING_STARTED: 'onboarding_started',
  ONBOARDING_COMPLETED: 'onboarding_completed',
  PRICING_PAGE_VIEWED: 'pricing_page_viewed',
  SUBSCRIPTION_CLICKED: 'subscription_clicked',
  DASHBOARD_VIEWED: 'dashboard_viewed',
  FLASHCARD_VIEWED: 'flashcard_viewed',
  FLASHCARD_FLIPPED: 'flashcard_flipped',
  FLASHCARD_EDITED: 'flashcard_edited',
  TEST_SCORE_VIEWED: 'test_score_viewed',
  ERROR_OCCURRED: 'error_occurred',
  API_ERROR: 'api_error',
  PAGE_LOAD_TIME: 'page_load_time',
  API_RESPONSE_TIME: 'api_response_time',
  SCROLL_DEPTH: 'scroll_depth',
  TIME_ON_PAGE: 'time_on_page',
  BUTTON_CLICK: 'button_click',
  LINK_CLICK: 'link_click',
  QUIZ_TIME_SPENT: 'quiz_time_spent',
  QUESTION_TIME_SPENT: 'question_time_spent',
  ANSWER_CHANGED: 'answer_changed',
  HINT_VIEWED: 'hint_viewed',
  ONBOARDING_STEP_COMPLETED: 'onboarding_step_completed',
  SUBSCRIPTION_STEP: 'subscription_step',
  FEATURE_USAGE: 'feature_usage',
  MEMORY_USAGE: 'memory_usage',
  JS_ERROR: 'js_error',
  NETWORK_ERROR: 'network_error',
  QUIZ_STATE_CHANGED: 'quiz_state_changed',
  QUIZ_SAVE_ATTEMPTED: 'quiz_save_attempted',
  QUIZ_SAVE_FAILED: 'quiz_save_failed',
  QUIZ_LOAD_FAILED: 'quiz_load_failed',
  QUESTION_VALIDATION_FAILED: 'question_validation_failed',
  QUIZ_PROGRESS_MIGRATION: 'quiz_progress_migration',
  QUIZ_PROGRESS_CORRUPTED: 'quiz_progress_corrupted',
  EXAM_DATE_UPDATED: 'exam_date_updated',
  SHARE_RESULT: 'share_result',
  QUIZ_TYPE_SELECTED: 'quiz_type_selected',
  FLASHCARD_EXPLANATION_UPDATED: 'flashcard_explanation_updated',
  ERROR: 'error',
  
  // Dashboard specific events
  DASHBOARD_INTERACTION: 'dashboard_interaction',
  QUIZ_FILTER_CHANGED: 'quiz_filter_changed',
  FLASHCARD_MARKED_COMPLETE: 'flashcard_marked_complete',
  PAGE_VIEWED: 'page_viewed',
  
  // Settings specific events
  SETTINGS_UPDATE_PROFILE: 'settings_update_profile',
  SETTINGS_MANAGE_SUBSCRIPTION: 'settings_manage_subscription',
  SETTINGS_CHANGE_PASSWORD: 'settings_change_password',
  SETTINGS_SEND_FEEDBACK: 'settings_send_feedback',
  SETTINGS_PAGE_VIEWED: 'settings_page_viewed',
  SETTINGS_USER_LOGOUT: 'settings_user_logout',
  SETTINGS_SUPPORT_CONTACT_CLICKED: 'settings_support_contact_clicked',
  
  // Auth Flow Events
  AUTH_STARTED: 'auth_started',
  AUTH_COMPLETED: 'auth_completed',
  AUTH_ERROR: 'auth_error',
  AUTH_METHOD_SELECTED: 'auth_method_selected',
  AUTH_RESET_PASSWORD: 'auth_reset_password',
  AUTH_EMAIL_VERIFICATION: 'auth_email_verification',
  AUTH_SESSION_STARTED: 'auth_session_started',
  AUTH_SESSION_ENDED: 'auth_session_ended'
} as const;

// Change the type to use values instead of keys
export type AnalyticsEventType = typeof ANALYTICS_EVENTS[keyof typeof ANALYTICS_EVENTS];

// First, let's ensure our analytics events are properly typed
export const logAnalyticsEvent = <T extends AnalyticsEventType>(
  eventName: T,
  properties: AnalyticsEventParams[T]
) => {
  if (!analyticsInstance) {
    logger.warn('Analytics', 'Analytics not initialized');
    return;
  }

  try {
    if (process.env.NODE_ENV === 'development') {
      logger.debug('Analytics', 'Analytics Event:', { eventName, properties });
      return;
    }
    logEvent(analyticsInstance, eventName, properties);
  } catch (error) {
    logger.error('Analytics', 'Failed to log analytics event:', { error, eventName });
  }
};

// Add performance tracking
export const trackQuizPerformance = (quizId: number) => {
  const startTime = performance.now();
  return {
    end: () => {
      const duration = performance.now() - startTime;
      logAnalyticsEvent(ANALYTICS_EVENTS.QUIZ_TIME_SPENT, {
        quiz_id: quizId,
        duration_ms: duration
      });
      return duration;
    }
  };
};

// Add error tracking
export const trackError = (error: Error, context: {
  component?: string;
  quizId?: number;
  questionId?: number;
}) => {
  logAnalyticsEvent(ANALYTICS_EVENTS.ERROR_OCCURRED, {
    error_type: error.name,
    error_message: error.message,
    stack_trace: error.stack,
    ...context
  });
};