import { QuizState, QuizAction } from '../../../models/QuizTypes';
import { isQuizComplete, processQuizAnswer, getQuizSummary } from '../../../utils/quiz/core';
import { AdaptiveState } from '../../../models/AdaptiveState';
import { logger } from '../../../utils/logger';
import { QuizProgress } from '../../../utils/quiz/types';
import { QuizType } from '../../../models/SharedTypes';
import { AdaptiveLearningEngine } from '../../../utils/adaptiveLearning';

export const initialState: QuizState = {
  progress: null,
  currentQuestion: null,
  showHint: false,
  showResult: false,
  selectedAnswer: null,
  currentExplanation: '',
  isLoading: false,
  needsSave: false,
  error: null,
  errorState: {
    error: null,
    retryCount: 0,
    lastAttempt: 0
  },
  adaptiveState: {
    engine: new AdaptiveLearningEngine(null),
    currentDifficulty: 5,
    categoryStrengths: {},
    consecutiveCorrect: 0,
    consecutiveIncorrect: 0,
    totalQuestionsAttempted: 0,
    recentlyAskedQuestions: [],
    insights: {
      weakCategories: [],
      recommendedDifficulty: 5,
      suggestedPracticeFrequency: 'daily',
      performanceTrend: 'steady',
      timeManagement: {
        averageQuestionTime: 0,
        recommendedTimePerQuestion: 90,
        timeManagementAdvice: 'Take your time to read each question carefully'
      }
    }
  },
  questionStartTime: Date.now()
};

export const quizReducer = (state: QuizState, action: QuizAction): QuizState => {
  logger.debug('QuizReducer', `Processing ${action.type}`, {
    actionType: action.type,
    hasProgress: !!state.progress,
    currentIndex: state.progress?.currentQuestionIndex,
    isCompleted: state.progress?.completed
  });

  // Prevent state changes after completion except for specific actions
  if (state.progress?.completed && 
      !['QUIZ_COMPLETE', 'SET_ERROR', 'SET_LOADING'].includes(action.type)) {
    logger.warn('QuizReducer', 'Attempted state change after completion', { 
      actionType: action.type 
    });
    return state;
  }

  switch (action.type) {
    case 'SET_PROGRESS': {
      // Don't override completion state if already completed
      if (state.progress?.completed) {
        logger.debug('QuizReducer', 'Preserving completed state in SET_PROGRESS');
        return state;
      }

      const totalQuestions = action.payload.questionIds?.length ?? 0;
      const currentIndex = action.payload.currentQuestionIndex ?? 0;
      const isComplete = currentIndex >= totalQuestions - 1;

      const newProgress = {
        ...action.payload,
        completed: isComplete,
        type: action.payload.type || QuizType.SAMPLE
      };

      logger.debug('QuizReducer', 'SET_PROGRESS update', {
        wasCompleted: state.progress?.completed,
        isCompleted: newProgress.completed,
        currentIndex,
        totalQuestions,
        loadedQuestions: action.payload.questions?.length
      });

      return {
        ...state,
        progress: newProgress,
        currentQuestion: action.payload.questions?.[currentIndex] ?? null,
        adaptiveState: state.adaptiveState
      };
    }

    case 'HANDLE_ANSWER': {
      logger.debug('QuizReducer', 'HANDLE_ANSWER called', {
        currentIndex: state.progress?.currentQuestionIndex,
        hasProgress: !!state.progress,
        hasCurrentQuestion: !!state.currentQuestion,
        payload: action.payload
      });

      if (!state.progress || !state.currentQuestion) return state;
      
      const { answer, timeSpent } = action.payload;
      
      const { updatedProgress, isCorrect } = processQuizAnswer(
        state.progress,
        state.progress.currentQuestionIndex ?? 0,
        answer
      );

      // Update adaptive state if engine exists
      const adaptiveState = {
        ...state.adaptiveState,
        currentDifficulty: state.adaptiveState.engine.getCurrentDifficulty(),
        categoryStrengths: state.adaptiveState.engine.getCategoryStrengths(),
        insights: state.adaptiveState.engine.generateInsights(),
      };

      return {
        ...state,
        progress: updatedProgress,
        adaptiveState,
        selectedAnswer: answer,
        showResult: true,
        needsSave: true,
        questionStartTime: 0
      };
    }

    case 'INITIALIZE_ADAPTIVE_ENGINE': {
      const engine = action.payload || new AdaptiveLearningEngine(null);
      return {
        ...state,
        adaptiveState: {
          ...state.adaptiveState,
          engine,
          currentDifficulty: engine.getCurrentDifficulty(),
          categoryStrengths: engine.getCategoryStrengths(),
          insights: engine.generateInsights(),
        }
      };
    }

    case 'UPDATE_ADAPTIVE_STATE': {
      const engine = action.payload.engine || new AdaptiveLearningEngine(null);
      return {
        ...state,
        adaptiveState: {
          ...action.payload,
          engine
        }
      };
    }
    case 'UPDATE_QUESTION_START_TIME': {
      return {
        ...state,
        questionStartTime: action.payload
      };
    }
    case 'NEXT_QUESTION': {
      if (!state.progress) return state;
      const nextIndex = (state.progress.currentQuestionIndex ?? 0) + 1;
      const totalQuestions = state.progress.questionIds?.length ?? 0;
      const loadedQuestions = state.progress.questions?.length ?? 0;
      
      logger.debug('QuizReducer', 'Processing NEXT_QUESTION', {
        nextIndex,
        totalQuestions,
        loadedQuestions,
        isLastQuestion: nextIndex >= totalQuestions - 1
      });
      
      // Check if we've reached the end of the quiz
      if (nextIndex >= totalQuestions) {
        const summary = getQuizSummary(state.progress);
        const adaptiveState = {
          ...state.adaptiveState,
          currentDifficulty: state.adaptiveState.engine.getCurrentDifficulty(),
          categoryStrengths: state.adaptiveState.engine.getCategoryStrengths(),
          insights: state.adaptiveState.engine.generateInsights(),
        };

        return {
          ...state,
          progress: {
            ...state.progress,
            completed: true,
            adaptiveState
          },
          showResult: true,
          isLoading: false,
          summary,
          needsSave: true
        };
      }

      // If we're at the end of our loaded questions but not the end of the quiz,
      // we should wait for more questions to be loaded
      if (nextIndex >= loadedQuestions) {
        logger.debug('QuizReducer', 'Waiting for more questions to be loaded', {
          nextIndex,
          loadedQuestions,
          totalQuestions
        });
        return {
          ...state,
          isLoading: true
        };
      }
      
      return {
        ...state,
        progress: {
          ...state.progress,
          currentQuestionIndex: nextIndex,
          lastQuestionStartTime: Date.now()
        },
        currentQuestion: state.progress.questions?.[nextIndex] ?? null,
        selectedAnswer: null,
        showResult: false,
        showHint: false,
        currentExplanation: '',
        isLoading: false
      };
    }

    case 'PREVIOUS_QUESTION': {
      if (!state.progress || (state.progress.currentQuestionIndex ?? 0) <= 0) return state;
      const prevIndex = (state.progress.currentQuestionIndex ?? 0) - 1;
      return {
        ...state,
        progress: {
          ...state.progress,
          currentQuestionIndex: prevIndex
        },
        currentQuestion: state.progress.questions?.[prevIndex] ?? null,
        selectedAnswer: state.progress.questions?.[prevIndex]?.selected_answer || null,
        showResult: !!state.progress.questions?.[prevIndex]?.selected_answer,
        showHint: false
      };
    }

    case 'TOGGLE_HINT': {
      return {
        ...state,
        showHint: !state.showHint
      };
    }

    case 'SET_ERROR': {
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    }

    case 'RETRY_SAVE': {
      if (state.errorState.retryCount >= 3) {
        return state;
      }
      return {
        ...state,
        errorState: {
          ...state.errorState,
          retryCount: state.errorState.retryCount + 1,
          lastAttempt: Date.now()
        }
      };
    }

    case 'INCREMENT_RETRY_COUNT': {
      return {
        ...state,
        errorState: {
          ...state.errorState,
          retryCount: state.errorState.retryCount + 1
        }
      };
    }

    case 'RESET_ERROR_STATE': {
      return {
        ...state,
        errorState: {
          error: null,
          retryCount: 0,
          lastAttempt: 0
        }
      };
    }

    case 'SET_SHOW_RESULT': {
      return {
        ...state,
        showResult: action.payload
      };
    }

    case 'SET_EXPLANATION': {
      return {
        ...state,
        currentExplanation: action.payload
      };
    }

    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: action.payload
      };
    }

    case 'SAVE_PROGRESS': {
      return {
        ...state,
        needsSave: false,
        isLoading: false
      };
    }

    case 'SAVE_AND_CONTINUE': {
      const { timestamp, nextQuestion, preservedProgress } = action.payload;
      
      // Don't proceed if quiz is completed or about to be completed
      if (state.progress?.completed || preservedProgress.completed) {
        logger.warn('QuizReducer', 'Preventing SAVE_AND_CONTINUE - quiz is completed or about to complete', {
          isCompleted: state.progress?.completed,
          willComplete: preservedProgress.completed
        });
        return state;
      }

      // Validate progress state before proceeding
      if (!preservedProgress || !nextQuestion) {
        logger.error('QuizReducer', 'Invalid progress state in SAVE_AND_CONTINUE', {
          hasPreservedProgress: !!preservedProgress,
          hasNextQuestion: !!nextQuestion
        });
        return {
          ...state,
          error: new Error('Invalid quiz state')
        };
      }

      // Process wrong answers for the current question before moving to next
      const currentQuestion = state.progress?.questions?.[state.progress.currentQuestionIndex ?? 0];
      let updatedWrongAnswers = [...(preservedProgress.wrongAnswers || [])];
      
      if (currentQuestion?.selected_answer && 
          currentQuestion.selected_answer !== currentQuestion.correct_answer) {
        logger.debug('QuizReducer', 'Adding wrong answer', {
          questionId: currentQuestion.id,
          selected: currentQuestion.selected_answer,
          correct: currentQuestion.correct_answer,
          currentWrongAnswersCount: updatedWrongAnswers.length
        });
        
        // Ensure wrong answer follows the WrongAnswer interface exactly
        const wrongAnswer = {
          question: {
            id: String(currentQuestion.id),
            category: String(currentQuestion.category || ''),
            question: String(currentQuestion.question || ''),
            correct_answer: String(currentQuestion.correct_answer || ''),
            selected_answer: String(currentQuestion.selected_answer)
          },
          explanation: String(currentQuestion.explanation || ''),
          date: new Date().toISOString(),
          isReviewed: false,
          attempts: 1
        };

        // Log the wrong answer structure for debugging
        logger.debug('QuizReducer', 'Structured wrong answer', {
          wrongAnswer,
          currentWrongAnswers: updatedWrongAnswers.length,
          preservedWrongAnswers: preservedProgress.wrongAnswers?.length || 0
        });
        
        updatedWrongAnswers.push(wrongAnswer);

        // Log after adding to verify the array was updated
        logger.debug('QuizReducer', 'Updated wrong answers array', {
          previousCount: preservedProgress.wrongAnswers?.length || 0,
          newCount: updatedWrongAnswers.length,
          wasAnswerAdded: updatedWrongAnswers.length > (preservedProgress.wrongAnswers?.length || 0)
        });
      }

      // Update preservedProgress with the new wrong answers
      const updatedProgress = {
        ...preservedProgress,
        wrongAnswers: updatedWrongAnswers,
        lastUpdated: new Date().toISOString()
      };

      // Log the final state for debugging
      logger.debug('QuizReducer', 'Final state update', {
        wrongAnswersCount: updatedWrongAnswers.length,
        hasCurrentQuestion: !!nextQuestion,
        currentIndex: updatedProgress.currentQuestionIndex,
        wrongAnswersInProgress: updatedProgress.wrongAnswers?.length || 0
      });

      // Always proceed to next question if not completed
      return {
        ...state,
        progress: updatedProgress,
        currentQuestion: nextQuestion,
        selectedAnswer: null,
        showResult: false,
        showHint: false,
        currentExplanation: '',
        questionStartTime: timestamp,
        isLoading: false,
        needsSave: true  // Set to true to ensure the state is saved
      };
    }

    case 'SAVE_ERROR': {
      return {
        ...state,
        error: action.payload,
        needsSave: true
      };
    }

    case 'SET_NEEDS_SAVE': {
      return {
        ...state,
        needsSave: action.payload
      };
    }

    case 'INITIALIZE_ADAPTIVE_ENGINE_WITH_STATE': {
      const { preservedProgress } = action.payload;
      
      // console.log('[Reducer] Initializing engine with preserved state', {
      //   preservedIndex: preservedProgress?.currentQuestionIndex,
      //   currentIndex: state.progress?.currentQuestionIndex
      // });

      return {
        ...state,
        adaptiveState: preservedProgress?.adaptiveState as AdaptiveState,
        progress: preservedProgress || state.progress
      };
    }

    case 'QUIZ_COMPLETE': {
      const { finalScore, timestamp, adaptiveState, summary } = action.payload;

      if (!state.progress) {
        logger.error('QuizReducer', 'Cannot complete quiz - missing progress');
        return state;
      }

      // Ensure we have all required fields for completion
      const finalProgress = {
        ...state.progress,
        completed: true,
        score: finalScore,
        lastQuestionStartTime: timestamp,
        lastUpdated: new Date().toISOString(),
        adaptiveState
      };

      logger.debug('QuizReducer', 'Quiz completed', {
        score: finalScore,
        totalQuestions: state.progress.questions?.length ?? 0,
        hasAdaptiveState: !!adaptiveState
      });

      return {
        ...state,
        progress: finalProgress,
        adaptiveState,
        showResult: true,
        isLoading: false,
        summary,
        needsSave: false // Reset since we've just saved
      };
    }

    case 'SET_ANSWER_WITH_TIME': {
      if (!state.progress || !state.currentQuestion) return state;
      
      const { answer, timeSpent, questionId } = action.payload;
      const { updatedProgress, isCorrect } = processQuizAnswer(
        state.progress,
        state.progress.currentQuestionIndex ?? 0,
        answer
      );

      // Update adaptive state
      const adaptiveState = state.adaptiveState?.engine 
        ? {
            ...state.adaptiveState,
            currentDifficulty: state.adaptiveState.engine.getCurrentDifficulty(),
            categoryStrengths: state.adaptiveState.engine.getCategoryStrengths(),
            insights: state.adaptiveState.engine.generateInsights(),
          }
        : state.adaptiveState ?? null;

      // Ensure we have all required fields for saving
      const progressWithUpdates: QuizProgress = {
        ...updatedProgress,
        lastUpdated: new Date().toISOString(),
        date: updatedProgress.date || new Date().toISOString(),
        adaptiveState: adaptiveState as AdaptiveState,
        questionTimes: {
          ...updatedProgress.questionTimes,
          [questionId]: timeSpent
        }
      };

      logger.debug('QuizReducer', 'Processing answer', {
        currentIndex: progressWithUpdates.currentQuestionIndex,
        totalQuestions: progressWithUpdates.questionIds?.length,
        loadedQuestions: progressWithUpdates.questions?.length,
        timeSpent,
        isCorrect
      });

      return {
        ...state,
        progress: progressWithUpdates,
        adaptiveState,
        selectedAnswer: answer,
        showResult: true,
        needsSave: true,
        questionStartTime: Date.now()
      };
    }

    default: {
      return state;
    }
  }
}; 