import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import { logger } from '../utils/logger';

/**
 * Gets a secure Stripe Customer Portal URL for managing subscriptions
 * @param customerId The Stripe customer ID
 * @returns A secure URL to the customer's subscription management portal
 */
export const getStripeCustomerPortal = async (customerId: string): Promise<string> => {
  try {
    const createPortalSession = httpsCallable(functions, 'createStripePortalSession');
    const result = await createPortalSession({ customerId });
    
    // The result.data should contain the secure URL from our Cloud Function
    const { url } = result.data as { url: string };
    
    if (!url) {
      throw new Error('No portal URL returned from Stripe');
    }

    return url;
  } catch (error) {
    logger.error('Stripe Service', 'Error creating portal session', { error });
    throw error;
  }
};

/**
 * Creates a Stripe checkout session for subscription purchase
 * @param priceId The Stripe price ID for the subscription
 * @returns An object containing the session ID and checkout URL
 */
export const createCheckoutSession = async (priceId: string): Promise<{ sessionId: string, url: string }> => {
  try {
    const createCheckout = httpsCallable(functions, 'createCheckoutSession');
    const result = await createCheckout({ priceId });
    
    const { sessionId, url } = result.data as { sessionId: string, url: string };
    
    if (!url || !sessionId) {
      throw new Error('Invalid checkout session data returned');
    }

    logger.info('Stripe Service', 'Created checkout session', { sessionId });
    return { sessionId, url };
  } catch (error) {
    logger.error('Stripe Service', 'Error creating checkout session', { error });
    throw error;
  }
}; 