import React, { useState, useEffect, useMemo } from 'react';
import { 
  Trophy, Clock, Target, Brain, 
  ArrowRight, AlertCircle, TrendingUp, 
  BarChart2, Loader2 
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { logger } from '../utils/logger';
import { saveTestResult } from '../utils/firebase/testResults';
import { useAuth } from '../contexts/AuthContext';
import { QuizProgress } from '../utils/quiz/types';
import { prepareTestResult } from '../utils/quiz/core';
import { toStorageState } from '../models/AdaptiveState';

interface QuizSummaryProps {
  quizProgress: QuizProgress;
  timeSpent: number;
  onRestartQuiz: () => void;
  showFeedbackButton?: boolean;
}

const StatCard: React.FC<{
  icon: React.ReactNode;
  title: string;
  value: string | number;
  subtitle?: string;
  trend?: 'up' | 'down' | 'neutral';
  trendValue?: string;
}> = ({ icon, title, value, subtitle, trend, trendValue }) => (
  <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm border border-gray-100 dark:border-gray-700">
    <div className="flex items-start justify-between">
      <div className="space-y-3">
        <div className="flex items-center space-x-2 text-gray-500 dark:text-gray-400">
          {icon}
          <span className="font-medium">{title}</span>
        </div>
        <div>
          <div className="text-3xl font-bold text-gray-900 dark:text-white">
            {value}
          </div>
          {subtitle && (
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
              {subtitle}
            </p>
          )}
        </div>
      </div>
      {trend && (
        <div className={`flex items-center space-x-1 text-sm font-medium ${
          trend === 'up' ? 'text-green-500' :
          trend === 'down' ? 'text-red-500' :
          'text-blue-500'
        }`}>
          {trend === 'up' && <TrendingUp className="w-4 h-4" />}
          {trend === 'down' && <TrendingUp className="w-4 h-4 transform rotate-180" />}
          {trend === 'neutral' && <TrendingUp className="w-4 h-4 transform rotate-90" />}
          <span>{trendValue}</span>
        </div>
      )}
    </div>
  </div>
);

const CategoryList: React.FC<{
  title: string;
  icon: React.ReactNode;
  categories: Array<{ category: string; successRate: number }>;
  type: 'success' | 'improvement';
}> = ({ title, icon, categories, type }) => (
  <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm border border-gray-100 dark:border-gray-700">
    <div className="flex items-center space-x-2 mb-4 text-gray-500 dark:text-gray-400">
      {icon}
      <h3 className="font-medium">{title}</h3>
    </div>
    <div className="space-y-3">
      {categories.map(({ category, successRate }, index) => (
        <div key={index} className="flex items-center justify-between">
          <span className="text-gray-700 dark:text-gray-300 truncate max-w-[70%]">
            {category}
          </span>
          <div className="flex items-center space-x-2">
            <div className="w-24 h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
              <div 
                className={`h-full transition-all duration-500 ${
                  type === 'success' 
                    ? 'bg-green-500 dark:bg-green-600' 
                    : 'bg-red-500 dark:bg-red-600'
                }`}
                style={{ width: `${Math.round(successRate * 100)}%` }}
              />
            </div>
            <span className={`text-sm font-medium ${
              type === 'success' 
                ? 'text-green-500 dark:text-green-400' 
                : 'text-red-500 dark:text-red-400'
            }`}>
              {Math.round(successRate * 100)}%
            </span>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const QuizSummary: React.FC<QuizSummaryProps> = ({
  quizProgress,
  timeSpent,
  onRestartQuiz,
  showFeedbackButton = true
}) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [saveComplete, setSaveComplete] = useState(false);

  const metrics = useMemo(() => {
    if (!quizProgress?.adaptiveState) {
      logger.error('QuizSummary', 'Missing adaptive state');
      return null;
    }

    logger.debug('QuizSummary', 'Raw quiz progress', {
      loadedQuestions: quizProgress.questions?.length ?? 0,
      totalQuestions: quizProgress.questionIds?.length ?? 0,
      wrongAnswers: quizProgress.wrongAnswers?.length ?? 0,
      adaptiveState: quizProgress.adaptiveState,
      currentIndex: quizProgress.currentQuestionIndex
    });

    const correctAnswers = quizProgress.questions?.filter(q => 
      q.selected_answer === q.correct_answer
    ).length ?? 0;

    const totalQuestions = quizProgress.questions?.length ?? 0;
    const overallScore = totalQuestions > 0 
      ? Math.round((correctAnswers / totalQuestions) * 100)
      : 0;

    const { averageQuestionTime, timeManagementAdvice } = 
      quizProgress.adaptiveState.insights?.timeManagement ?? { 
        averageQuestionTime: 0, 
        timeManagementAdvice: '' 
      };

    const isReallyComplete = quizProgress.completed && 
      (quizProgress.currentQuestionIndex ?? 0) >= (totalQuestions - 1);

    if (!isReallyComplete) {
      logger.warn('QuizSummary', 'Quiz showing as complete but may not be finished', {
        completed: quizProgress.completed,
        currentIndex: quizProgress.currentQuestionIndex,
        totalQuestions,
        loadedQuestions: quizProgress.questions?.length
      });
    }

    return {
      totalQuestions,
      correctAnswers,
      overallScore,
      averageTimePerQuestion: Math.round(averageQuestionTime),
      timeManagementAdvice,
      isReallyComplete
    };
  }, [quizProgress]);

  useEffect(() => {
    const saveResults = async () => {
      if (!currentUser || !quizProgress?.adaptiveState || !metrics) {
        logger.warn('QuizSummary', 'Missing required data for saving', {
          hasUser: !!currentUser,
          hasAdaptiveState: !!quizProgress?.adaptiveState,
          hasMetrics: !!metrics
        });
        return;
      }

      if (saveComplete) {
        logger.debug('QuizSummary', 'Results already saved');
        return;
      }

      setIsSaving(true);
      setError(null);

      try {
        logger.info('QuizSummary', 'Preparing to save quiz results', {
          userId: currentUser.uid,
          metrics
        });

        const testResult = prepareTestResult(quizProgress);
        const serializableState = toStorageState(quizProgress.adaptiveState);
        
        await saveTestResult(
          currentUser.uid,
          testResult,
          serializableState
        );

        setSaveComplete(true);
        logger.success('QuizSummary', 'Quiz results saved successfully');
      } catch (err) {
        const errorMessage = 'Failed to save quiz results';
        logger.error('QuizSummary', errorMessage, err);
        setError(errorMessage);
      } finally {
        setIsSaving(false);
      }
    };

    saveResults();
  }, [currentUser, quizProgress, metrics, saveComplete]);

  if (!metrics?.isReallyComplete) {
    logger.warn('QuizSummary', 'Preventing summary display - quiz not actually complete');
    return null;
  }

  if (!metrics) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="flex flex-col items-center space-y-4">
          <Loader2 className="w-12 h-12 animate-spin text-blue-500" />
          <p className="text-gray-600 dark:text-gray-300">Loading results...</p>
        </div>
      </div>
    );
  }

  const strongCategories = Object.entries(quizProgress.adaptiveState?.categoryStrengths ?? {})
    .filter(([_, stats]) => stats.totalCount > 0)
    .map(([category, stats]) => ({
      category,
      successRate: stats.correctCount / stats.totalCount
    }))
    .sort((a, b) => b.successRate - a.successRate)
    .slice(0, 3);

  const weakCategories = quizProgress.adaptiveState?.insights?.weakCategories
    ?.slice(0, 3)
    .map(cat => ({
      category: cat.category,
      successRate: cat.successRate
    })) ?? [];

  return (
    <div className="max-w-5xl mx-auto p-6 space-y-8">
      {error && (
        <div className="bg-red-50 dark:bg-red-900/30 border border-red-200 dark:border-red-800 rounded-lg p-4">
          <div className="flex items-start space-x-3">
            <AlertCircle className="w-5 h-5 text-red-500 flex-shrink-0" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        </div>
      )}

      <div className="text-center space-y-2">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
          Quiz Complete!
        </h1>
        <p className="text-gray-600 dark:text-gray-400">
          Great job! Here&apos;s how you performed.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <StatCard
          icon={<Trophy className="w-5 h-5" />}
          title="Overall Score"
          value={`${metrics.overallScore}%`}
          subtitle={`${metrics.correctAnswers} correct out of ${metrics.totalQuestions}`}
          trend={metrics.overallScore >= 70 ? 'up' : metrics.overallScore >= 50 ? 'neutral' : 'down'}
          trendValue={metrics.overallScore >= 70 ? 'Excellent' : metrics.overallScore >= 50 ? 'Good' : 'Needs Work'}
        />

        <StatCard
          icon={<Clock className="w-5 h-5" />}
          title="Time Analysis"
          value={`${timeSpent}s`}
          subtitle="Average time per question"
        />

        <StatCard
          icon={<BarChart2 className="w-5 h-5" />}
          title="Questions Completed"
          value={metrics.totalQuestions}
          subtitle="Total questions answered"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {strongCategories.length > 0 && (
          <CategoryList
            title="Strongest Categories"
            icon={<Brain className="w-5 h-5" />}
            categories={strongCategories}
            type="success"
          />
        )}

        {weakCategories.length > 0 && (
          <CategoryList
            title="Areas for Improvement"
            icon={<Target className="w-5 h-5" />}
            categories={weakCategories}
            type="improvement"
          />
        )}
      </div>

      <div className="flex flex-col sm:flex-row justify-center items-center gap-4 pt-6">
        <button
          onClick={onRestartQuiz}
          disabled={isSaving}
          className="inline-flex items-center px-6 py-3 bg-blue-500 hover:bg-blue-600 
                   text-white font-medium rounded-lg transition-colors duration-200 
                   disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isSaving ? (
            <>
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              Saving...
            </>
          ) : (
            <>
              <ArrowRight className="w-4 h-4 mr-2" />
              Start New Quiz
            </>
          )}
        </button>

        {showFeedbackButton && (
          <button 
            onClick={() => navigate('/feedback')}
            disabled={isSaving}
            className="inline-flex items-center px-6 py-3 bg-green-500 hover:bg-green-600 
                     text-white font-medium rounded-lg transition-colors duration-200
                     disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Give Feedback
          </button>
        )}
      </div>
    </div>
  );
};

export default QuizSummary; 