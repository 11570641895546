import { format, differenceInDays, isPast, isToday, isTomorrow, startOfDay } from 'date-fns';


export interface QuestionTiming {
    startTime: number;
    endTime?: number;
}

export const formatExamDate = (dateString: string): string => {
    try {
        const date = new Date(dateString);
        return format(date, 'MMMM d, yyyy');
    } catch (error) {
        console.error('Error formatting exam date:', error);
        return 'Invalid date';
    }
};

export interface ExamCountdownInfo {
    daysRemaining: number;
    isPast: boolean;
    isToday: boolean;
    status: 'upcoming' | 'today' | 'past';
    message: string;
}

export const calculateExamCountdown = (examDateString: string): ExamCountdownInfo => {
    try {
        const examDate = new Date(examDateString);
        const today = new Date();
        const daysRemaining = differenceInDays(examDate, today);
        const examIsPast = isPast(examDate);
        const examIsToday = isToday(examDate);
        const examIsTomorrow = isTomorrow(examDate);

        if (examIsToday) {
            return {
                daysRemaining: 0,
                isPast: false,
                isToday: true,
                status: 'today',
                message: 'Your exam is today! Good luck!'
            };
        }

        if (examIsTomorrow) {
            return {
                daysRemaining: 1,
                isPast: false,
                isToday: false,
                status: 'upcoming',
                message: 'One day to go! Get some rest!'
            };
        }

        if (examIsPast) {
            return {
                daysRemaining: Math.abs(daysRemaining),
                isPast: true,
                isToday: false,
                status: 'past',
                message: 'Exam date has passed'
            };
        }

        // Create encouraging messages based on time remaining
        let message = '';
        if (daysRemaining > 90) {
            message = 'Plenty of time to prepare thoroughly!';
        } else if (daysRemaining > 30) {
            message = 'Keep up the steady preparation!';
        } else if (daysRemaining > 14) {
            message = 'Getting closer! Stay focused!';
        } else if (daysRemaining > 7) {
            message = "Final weeks! You've got this!";
        } else if (daysRemaining > 1) {
            message = 'Almost there! Review your key topics!';
        } else {
            // This case should now only happen if there's an edge case we missed
            message = 'Exam coming up very soon!';
        }

        return {
            daysRemaining,
            isPast: false,
            isToday: false,
            status: 'upcoming',
            message
        };
    } catch (error) {
        console.error('Error calculating exam countdown:', error);
        return {
            daysRemaining: 0,
            isPast: false,
            isToday: false,
            status: 'upcoming',
            message: 'Error calculating countdown'
        };
    }
};

export const calculateAverageResponseTime = (
    timings?: Record<number, QuestionTiming>
  ): number => {
    if (!timings) return 0;
    
    const completedTimings = Object.values(timings).filter(t => t.endTime);
    if (!completedTimings.length) return 0;
    
    const totalTime = completedTimings.reduce((sum, timing) => 
      sum + ((timing.endTime ?? 0 - timing.startTime) / 1000), 0);
    
    return totalTime / completedTimings.length;
  };