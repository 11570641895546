import React, { useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import './styles/Home.css';
import { Helmet } from 'react-helmet';
import ErrorBoundary from './components/ErrorBoundary';
import LoadingSpinner from './components/LoadingSpinner';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from './utils/Analytics';
import { Shield, CheckCircle } from 'lucide-react';

const structuredData = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  "name": "Social Work Test Prep Academy",
  "url": "https://www.socialworktestprepacademy.com",
  "description": "Prepare for your ASWB Exams with our comprehensive practice tests and study materials.",
};

const Home = () => {
  const navigate = useNavigate();
  const emailSignupRef = useRef<HTMLDivElement>(null);
  const { currentUser, isLoading } = useAuth();

  React.useEffect(() => {
    logAnalyticsEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
      page_name: 'home',
      is_authenticated: !!currentUser,
      timestamp: new Date().toISOString()
    });

    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  const handleStartPreviewQuiz = () => {
    navigate('/preview-quiz');
  };

  function PricingHero() {
    return (
      <div className="w-full">
        <div className="max-w-7xl mx-auto px-4 py-12">
          <div className="text-center mb-8">
            <h2 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
              Choose Your Path to Success
            </h2>
            <p className="text-lg text-gray-600 dark:text-gray-300">
              Start with our free quiz or unlock full exam preparation
            </p>
          </div>
  
          <div className="grid md:grid-cols-2 gap-6 max-w-5xl mx-auto">
            {/* Free Tier */}
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow border border-gray-200 dark:border-gray-600">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-xl font-bold text-gray-900 dark:text-white">Free Practice Test</h3>
                <div className="text-blue-500 bg-blue-50 dark:bg-blue-900/20 p-2 rounded-full">
                  <Shield size={20} />
                </div>
              </div>
              
              <ul className="space-y-3 mb-6">
                <Feature text="Complete 30-question practice test" />
                <Feature text="Real exam-style format" />
                <Feature text="Performance tracking" />
                <Feature text="Detailed explanations" />
              </ul>
  
              <div className="mt-auto">
                <button 
                  onClick={handleStartPreviewQuiz}
                  className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition-colors"
                >
                  Start Free Test
                </button>
                <div className="mt-2 text-center">
                  <Link
                    to="/signin"
                    className="text-sm text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400 transition-colors"
                  >
                    Already have an account? Login to your dashboard
                  </Link>
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mt-2 text-center">
                  Start immediately - no signup required
                </p>
              </div>
            </div>
  
            {/* Premium Tier */}
            <div className="bg-blue-50 dark:bg-gray-700 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow relative border-2 border-blue-200 dark:border-blue-400">
              <div className="absolute -top-3 right-4 bg-blue-500 text-white px-3 py-1 rounded-full text-sm font-semibold">
                Recommended
              </div>
              
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-xl font-bold text-gray-900 dark:text-white">Full Exam Prep</h3>
                <div className="text-blue-600 dark:text-blue-300 bg-blue-100 dark:bg-blue-900/30 p-2 rounded-full">
                  <Shield size={20} />
                </div>
              </div>
  
              <ul className="space-y-3 mb-6">
                <Feature text="LCSW Full Practice Exam (170 questions)" />
                <Feature text="LMSW Full Practice Exam (170 questions)" />
                <Feature text="LBSW Practice Exam (Coming Soon)" />
                <Feature text="Adaptive learning technology" />
                <Feature text="Progress analytics dashboard" />
              </ul>
  
              <Link 
                to="/pricing" 
                className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md transition-colors inline-block text-center"
              >
                View Pricing
              </Link>
              <p className="text-sm text-gray-500 dark:text-gray-300 mt-2 text-center">
                7-day money-back guarantee
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  function Feature({ text }: { text: string }) {
    return (
      <li className="flex items-center space-x-3">
        <CheckCircle className="text-green-500 dark:text-green-400 flex-shrink-0" size={18} />
        <span className="text-gray-600 dark:text-gray-300 text-sm">{text}</span>
      </li>
    );
  }

  if (isLoading) {
    return (
      <div className="new-home-container">
        <div className="new-home-content">
          <div className="new-home-inner">
            <LoadingSpinner />
          </div>
        </div>
      </div>
    );
  }

  if (!currentUser) {
    return (
      <ErrorBoundary>
        <Helmet>
          <title>ASWB Exam Prep | Social Work Test Prep Academy</title>
          <meta name="description" content="Prepare for your ASWB Exams with our comprehensive practice tests and study materials. Start your journey to licensure today!" />
          <meta property="og:title" content="ASWB Exam Prep | Social Work Test Prep Academy" />
          <meta property="og:description" content="Master your ASWB Exams with our free expert-curated 30-question quiz and comprehensive full length practice exams." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <link rel="canonical" href={window.location.href} />
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>
        <div className="new-home-container">
          <div className="new-home-content">
            <div className="new-home-inner">
              <div className="md:flex">
                <div className="md:w-1/2 hidden md:block">
                  <div className="h-96 md:h-full w-full hero-image"></div>
                </div>
                <div className="w-full md:w-1/2 p-4 md:p-8">
                  <h1 className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-2">ASWB Exam Prep: Your Path to Licensure Made Easy</h1>
                  <p className="text-base text-gray-600 dark:text-gray-300 mb-4">Start your free 30-question ASWB practice test now!</p>
                  <div className="max-w-md mx-auto">
                    <button
                      onClick={handleStartPreviewQuiz}
                      className="w-full px-6 py-3 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
                    >
                      Start Free Test
                    </button>
                    <div className="mt-2 text-center">
                      <Link
                        to="/signin"
                        className="text-sm text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400 transition-colors"
                      >
                        Already have an account? Login to your dashboard
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-12">
                <h2 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">Everything you need to pass your ASWB Exams</h2>
                <p className="text-base text-gray-600 dark:text-gray-300 mb-4">
                  The Social Work Test Prep Academy offers comprehensive prep courses for ASWB social work licensing exams, including LCSW, LMSW, and LBSW practice exams. We designed this platform to help you learn and retain the information needed to pass your exam. Our exams are created by a subject matter expert with over 20 years of experience from a leading social work university. You will also have access to our dashboard, which tracks your progress and helps you stay on track with your study goals.
                </p>

                <p className="text-base text-gray-600 dark:text-gray-300 mb-8">
                  Our exams are curated by a LICSW, UofM graduate, and dedicated therapist who passed the exam in 2014 after 20 years of work in various clinical settings. The exam questions are based on clinical scenarios, requiring application of reasoning, diagnosis, and use of qualifiers. They align with the 2024 ASWB KSA content guidelines and the code of ethics.
                </p>

                <PricingHero />

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                  {[
                    { title: "Study anywhere, anytime", description: "Our courses are designed to give you the flexibility to study whenever you have time, while simulating the real exam experience.", image: "/images/studyanywhere.png" },
                    { title: "Learn from top instructors", description: "Our courses are curated by LICSW UofM graduates and dedicated therapists who have passed the exam and are experts in their field", image: "/images/learn.png" },
                    { title: "Stay on track with personalized progress tracking", description: "Our dashboard tracks your quiz results and helps you learn from your mistakes, while giving you the tools to stay on track with your study goals", image: "/images/stayontrack.png" },
                    { title: "Practice with real exam questions", description: "Our course includes full-length practice exams, so you can practice with real exam questions and get comfortable with the format", image: "/images/practice.png" },
                  ].map((feature, index) => (
                    <div key={index} className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden feature-card border border-gray-200 dark:border-gray-600">
                      <div className={`h-48 w-full ${
                        feature.title === "Study anywhere, anytime" ? "study-anywhere-image" :
                        feature.title === "Learn from top instructors" ? "learn-image" :
                        feature.title === "Stay on track with personalized progress tracking" ? "stay-on-track-image" :
                        "practice-image"
                      }`}></div>
                      <div className="p-6">
                        <h3 className="font-semibold text-lg mb-2 dark:text-white">{feature.title}</h3>
                        <p className="text-gray-600 dark:text-gray-300">{feature.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-16 bg-gradient-to-r from-blue-500 to-indigo-600 rounded-lg shadow-lg p-8 text-white">
                <h2 className="text-3xl md:text-4xl font-bold mb-4">Unlock the Full Potential of Your ASWB Exams Prep</h2>
                <p className="text-lg mb-6">
                  Take your study to the next level with our comprehensive 170-question exam. Perfect for simulating the real ASWB exam experience.
                </p>
                <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                  <p className="text-xl font-semibold">Subscribe now and supercharge your preparation!</p>
                  <Link
                    to="/pricing"
                    className="px-6 py-3 bg-white text-blue-600 font-bold rounded-md hover:bg-gray-100 transition duration-300 ease-in-out"
                  >
                    View Pricing
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }

  navigate('/dashboard');
  return null;
};

export default Home;
