import React, { useMemo } from 'react';
import { Question } from '../models/QuizTypes';

export interface AnswerOptionsProps {
  currentQuestion: Question;
  selectedAnswer: string | null;
  showResult: boolean;
  handleAnswer: (answer: string) => void;
  isCorrect: (option: string) => boolean;
}

const AnswerOptions: React.FC<AnswerOptionsProps> = ({
  currentQuestion,
  selectedAnswer,
  showResult,
  handleAnswer,
  isCorrect
}) => {
  const options = useMemo(() => {
    return ['A', 'B', 'C', 'D', 'E'].map((option) => {
      const optionKey = `option_${option.toLowerCase()}` as keyof Question;
      const optionText = currentQuestion[optionKey];

      if (!optionText || optionText === "") return null;

      return {
        letter: option,
        text: optionText,
        key: `${currentQuestion.id}-${option}`
      };
    }).filter(Boolean);
  }, [currentQuestion.id, currentQuestion]);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm mt-4">
      <div className="space-y-3">
        {options.map((option) => {
          if (!option) return null;
          
          return (
            <div key={option.key} className="flex items-center space-x-3">
              <span className="font-medium text-gray-700 dark:text-gray-300 w-6">{option.letter}.</span>
              <button
                className={`flex-1 text-left p-4 rounded-lg border ${
                  selectedAnswer === option.letter 
                    ? (isCorrect(option.letter) 
                        ? 'bg-green-50 dark:bg-green-900/30 border-green-500 text-green-700 dark:text-green-300' 
                        : 'bg-red-50 dark:bg-red-900/30 border-red-500 text-red-700 dark:text-red-300')
                    : 'bg-gray-50 dark:bg-gray-700/50 border-gray-200 dark:border-gray-600 text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700'
                } transition-all duration-200`}
                onClick={() => handleAnswer(option.letter)}
                disabled={showResult}
              >
                <span className="block">{option.text}</span>
                {showResult && selectedAnswer === option.letter && (
                  <span className="inline-block mt-2 text-sm">
                    {isCorrect(option.letter) ? '✓ Correct' : '✗ Incorrect'}
                  </span>
                )}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(AnswerOptions);