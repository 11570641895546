import React, { useEffect, useState, useRef } from 'react';
import { logger } from './utils/logger';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from './utils/Analytics';
import { createCheckoutSession } from './services/stripe';

interface FAQItemProps {
  question: string;
  answer: string;
}

export function BuyButtonComponent() {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<User | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState<string | null>(null);
  const pageLoadTime = useRef(Date.now());
  const navigate = useNavigate();

  // Get the price ID based on environment
  const getPriceId = () => {
    return process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_STRIPE_PRICE_ID!
      : process.env.REACT_APP_STRIPE_PRICE_ID_DEV!;
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        // Fetch the user's subscription status
        const userDoc = await getDoc(doc(getFirestore(), 'users', user.uid));
        const userData = userDoc.data();
        setSubscriptionStatus(userData?.subscriptionStatus || null);

        // Track subscription status check
        logAnalyticsEvent(ANALYTICS_EVENTS.SUBSCRIPTION_STEP, {
          step: 'status_check',
          status: userData?.subscriptionStatus || 'none',
          userId: user.uid,
          timestamp: Date.now()
        });
      }
    });
    return () => unsubscribe();
  }, []);

  const handleSubscribeClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    
    logAnalyticsEvent(ANALYTICS_EVENTS.SUBSCRIPTION_CLICKED, {
      subscriptionStatus,
      isLoggedIn: !!user,
      source: 'buy_button',
      timestamp: Date.now(),
      timeOnPage: Date.now() - pageLoadTime.current
    });
    
    if (!user) {
      setShowModal(true);
      
      logAnalyticsEvent(ANALYTICS_EVENTS.SUBSCRIPTION_STEP, {
        step: 'signup_prompt',
        source: 'buy_button',
        timestamp: Date.now()
      });
      return;
    }
    
    try {
      setIsLoading(true);
      const priceId = getPriceId();
      
      logAnalyticsEvent(ANALYTICS_EVENTS.SUBSCRIPTION_STEP, {
        step: 'creating_checkout',
        source: 'buy_button',
        userId: user.uid,
        timestamp: Date.now()
      });
      
      logger.info('Pricing', 'Creating checkout with price ID', { priceId });
      
      const { url } = await createCheckoutSession(priceId);
      
      logAnalyticsEvent(ANALYTICS_EVENTS.SUBSCRIPTION_STEP, {
        step: 'redirecting_to_checkout',
        userId: user.uid,
        timestamp: Date.now()
      });
      
      // Redirect to the checkout URL
      window.location.href = url;
    } catch (error) {
      console.error('Failed to create checkout session:', error);
      
      logAnalyticsEvent(ANALYTICS_EVENTS.ERROR_OCCURRED, {
        error_type: 'checkout_creation_error',
        error_message: error instanceof Error ? error.message : 'Unknown error',
        component: 'BuyButton',
        timestamp: Date.now()
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignIn = () => {
    logAnalyticsEvent(ANALYTICS_EVENTS.SUBSCRIPTION_STEP, {
      step: 'redirect_to_signin',
      source: 'pricing_modal',
      timestamp: Date.now(),
      timeOnPage: Date.now() - pageLoadTime.current
    });

    const redirectUrl = encodeURIComponent('/pricing');
    navigate(`/signin?from=${redirectUrl}`);
  };

  if (subscriptionStatus === 'active' || subscriptionStatus === 'trialing') {
    return (
      <div className="text-center">
        <p className="text-green-600 dark:text-green-400 font-bold mb-2">
          You have an active subscription!
        </p>
        <button
          onClick={() => navigate('/dashboard')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Go to Your Dashboard
        </button>
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={handleSubscribeClick}
        disabled={isLoading}
      >
        {isLoading ? 'Processing...' : 'Subscribe Now'}
      </button>
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4 text-gray-900 dark:text-white">Sign In Required</h2>
            <p className="mb-4 text-gray-700 dark:text-gray-300">Please sign in to subscribe to our service.</p>
            <div className="flex justify-end">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                onClick={handleSignIn}
              >
                Sign In
              </button>
              <button
                className="bg-gray-300 hover:bg-gray-400 dark:bg-gray-600 dark:hover:bg-gray-700 text-black dark:text-white font-bold py-2 px-4 rounded"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const AuthenticatedPricingTable: React.FC = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [user, setUser] = useState<User | undefined>();
  const scriptLoadStartTime = useRef(Date.now());
  const navigate = useNavigate();

  const getPricingTableId = () => {
    const devId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID_DEV;
    const prodId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
    return process.env.NODE_ENV === 'production' ? prodId : devId;
  };

  const getPublishableKey = () => {
    const devKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV;
    const prodKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    return process.env.NODE_ENV === 'production' ? prodKey : devKey;
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        const userDoc = await getDoc(doc(getFirestore(), 'users', user.uid));
        const userData = userDoc.data();
        
        logAnalyticsEvent(ANALYTICS_EVENTS.SUBSCRIPTION_STEP, {
          step: 'pricing_table_auth_check',
          isSubscribed: userData?.subscriptionStatus === 'active' || userData?.subscriptionStatus === 'trialing',
          userId: user.uid,
          timestamp: Date.now()
        });

        if (userData?.subscriptionStatus === 'active' || userData?.subscriptionStatus === 'trialing') {
          navigate('/dashboard');
          return;
        }

        if (!document.querySelector('script[src*="pricing-table.js"]')) {
          const script = document.createElement('script');
          script.src = "https://js.stripe.com/v3/pricing-table.js";
          script.async = true;
          script.onload = () => {
            logger.info('Pricing', 'Stripe script loaded successfully');
            setScriptLoaded(true);
            
            logAnalyticsEvent(ANALYTICS_EVENTS.SUBSCRIPTION_STEP, {
              step: 'stripe_script_loaded',
              loadTime: Date.now() - scriptLoadStartTime.current,
              timestamp: Date.now()
            });
          };
          script.onerror = (error) => {
            logger.error('Pricing', 'Stripe script failed to load:', error);
            
            logAnalyticsEvent(ANALYTICS_EVENTS.ERROR_OCCURRED, {
              error_type: 'stripe_script_load_error',
              error_message: 'Failed to load Stripe script',
              component: 'PricingTable',
              timestamp: Date.now()
            });
          };
          document.head.appendChild(script);
        }
      } else {
        setUser(undefined);
        setScriptLoaded(false);
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const handleSignIn = () => {
    logAnalyticsEvent(ANALYTICS_EVENTS.SUBSCRIPTION_CLICKED, {
      subscriptionStatus: null,
      isLoggedIn: false
    });
    navigate('/signin?from=/pricing');
  };

  // If user is not authenticated, show sign-in button
  if (!user) {
    return (
      <div className="text-center">
        <button
          onClick={handleSignIn}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Sign In to Subscribe
        </button>
      </div>
    );
  }

  // Show loading state while script is loading
  if (!scriptLoaded) {
    return (
      <div className="text-center p-4">
        Loading pricing options...
      </div>
    );
  }

  // Show pricing table for authenticated users
  return (
    <div className="pricing-table-container">
      <stripe-pricing-table
        pricing-table-id={getPricingTableId()}
        publishable-key={getPublishableKey()}
        client-reference-id={user.uid}
        customer-email={user.email || ''}
      />
      
      {process.env.NODE_ENV === 'development' && (
        <div className="mt-4 p-4 bg-gray-100 dark:bg-gray-800 rounded text-sm">
          <pre>
            {JSON.stringify({
              tableId: getPricingTableId(),
              publishableKey: getPublishableKey()?.slice(0, 10) + '...',
              scriptLoaded,
              userLoggedIn: !!user
            }, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};

const PricingPage: React.FC = () => {
  const pageLoadTime = useRef(Date.now());
  const [visibleFeatures, setVisibleFeatures] = useState<Set<string>>(new Set());

  useEffect(() => {
    // Track initial page view with detailed metrics
    logAnalyticsEvent(ANALYTICS_EVENTS.PRICING_PAGE_VIEWED, {
      timestamp: Date.now(),
      referrer: document.referrer,
      loadTime: Date.now() - pageLoadTime.current,
      viewport: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    });

    // Track scroll depth
    const handleScroll = () => {
      const scrollPercent = Math.round(
        (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100
      );
      
      if (scrollPercent % 25 === 0) { // Track at 25%, 50%, 75%, 100%
        logAnalyticsEvent(ANALYTICS_EVENTS.SCROLL_DEPTH, {
          depth: scrollPercent,
          timestamp: Date.now(),
          timeOnPage: Date.now() - pageLoadTime.current
        });
      }
    };

    // Track feature visibility
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const featureId = entry.target.getAttribute('data-feature-id');
            if (featureId && !visibleFeatures.has(featureId)) {
              setVisibleFeatures(prev => new Set(prev).add(featureId));
              
              logAnalyticsEvent(ANALYTICS_EVENTS.FEATURE_USAGE, {
                feature: featureId,
                action: 'viewed',
                timestamp: Date.now(),
                timeOnPage: Date.now() - pageLoadTime.current
              });
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    // Observe feature cards
    document.querySelectorAll('[data-feature-id]').forEach(
      el => observer.observe(el)
    );

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      observer.disconnect();
    };
  }, [visibleFeatures]);

  return (
    <div className="new-home-container bg-white dark:bg-gray-900 text-gray-900 dark:text-white min-h-screen">
      <div className="new-home-content max-w-4xl mx-auto px-4 py-8">
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50 dark:from-gray-900 dark:to-gray-800">
      {/* Hero Section */}
      <div className="relative overflow-hidden pt-16 pb-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl sm:text-5xl font-extrabold text-gray-900 dark:text-white mb-4">
              Level Up Your ASWB Exam Prep
            </h1>
            <p className="max-w-2xl mx-auto text-xl text-gray-600 dark:text-gray-300">
              Join social workers who have trusted our platform for their licensing journey
            </p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Value Proposition Cards */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {[
            { id: 'test-suite', icon: '📚', title: 'Complete Test Suite' },
            { id: 'ai-learning', icon: '🎯', title: 'Adaptive Learning' },
            { id: 'progress-tracking', icon: '📊', title: 'Progress Analytics' }
          ].map(feature => (
            <div
              key={feature.id}
              data-feature-id={feature.id}
              className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6"
            >
              <div className="text-blue-600 dark:text-blue-400 mb-4">
                <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">{feature.title}</h3>
              <p className="text-gray-600 dark:text-gray-300">LCSW & LMSW full practice exams with 170 questions each</p>
            </div>
          ))}
        </div>

        {/* Pricing Table Section */}
        <div className="relative bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 mb-16">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-blue-100 dark:from-blue-900/20 dark:to-blue-800/20 rounded-2xl" style={{ opacity: 0.4 }}></div>
          <div className="relative">
            <div className="text-center mb-8">
              <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                Choose Your Plan
              </h2>
              <p className="text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
                Get instant access to our comprehensive study materials and join our community of successful test-takers
              </p>
            </div>
            
            <div className="pricing-table-container">
              <AuthenticatedPricingTable />
            </div>

            <div className="mt-8 flex justify-center items-center space-x-2 text-sm">
              <span className="text-gray-600 dark:text-gray-400">✓ 7-day money-back guarantee</span>
              <span className="text-gray-300 dark:text-gray-600">•</span>
              <span className="text-gray-600 dark:text-gray-400">✓ Cancel anytime</span>
              <span className="text-gray-300 dark:text-gray-600">•</span>
              <span className="text-gray-600 dark:text-gray-400">✓ Instant access</span>
            </div>
          </div>
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mb-16">
          <FeatureCard
            icon="📚"
            title="Comprehensive Content"
            description="Access both LCSW and LMSW practice exams, each with 170 expertly-crafted questions"
          />
          <FeatureCard
            icon="🎯"
            title="Adaptive Learning"
            description="Our AI system adjusts to your performance, focusing on areas that need improvement"
          />
          <FeatureCard
            icon="📊"
            title="Progress Analytics"
            description="Track your improvement with detailed performance metrics and insights"
          />
          <FeatureCard
            icon="💡"
            title="Study Tools"
            description="Interactive flashcards and comprehensive study materials at your fingertips"
          />
          <FeatureCard
            icon="📱"
            title="Mobile Ready"
            description="Study anywhere with our responsive platform that works on all devices"
          />
          <FeatureCard
            icon="🔄"
            title="Regular Updates"
            description="Content regularly updated to align with the latest ASWB guidelines"
          />
        </div>

        {/* FAQ Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-center text-gray-900 dark:text-white mb-8">
            Frequently Asked Questions
          </h2>
          <div className="max-w-3xl mx-auto">
            <FAQ />
          </div>
        </div>

        {/* Testimonial/Trust Section */}
        <div className="text-center mb-16">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Your Success Is Our Priority
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-6">
              Join our community of aspiring social workers and take the next step in your professional journey
            </p>
            <MoneyBackGuarantee />
          </div>
        </div>

        {/* Disclaimer */}
        <div className="max-w-3xl mx-auto">
          <Disclaimer />
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

const FeatureCard: React.FC<{ icon: string; title: string; description: string }> = ({
  icon,
  title,
  description,
}) => (
  <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-md">
    <div className="text-2xl mb-3">{icon}</div>
    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">{title}</h3>
    <p className="text-gray-600 dark:text-gray-300 text-sm">{description}</p>
  </div>
);

const BenefitItem: React.FC<{ icon: string; text: string }> = ({ icon, text }) => (
  <div className="flex items-center space-x-2 text-gray-800 dark:text-gray-200 bg-gray-100 dark:bg-gray-800 p-3 rounded-lg">
    <span className="text-2xl">{icon}</span>
    <span>{text}</span>
  </div>
);

const HighlyRecommended: React.FC = () => (
  <div className="bg-gray-200 dark:bg-gray-700 p-6 rounded-lg mb-8 text-center">
    <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-3">
      Complete Social Work Licensing Journey
    </h3>
    <p className="text-gray-700 dark:text-gray-300 text-lg">
      Access both LCSW and LMSW practice exams to support your path from initial licensure to clinical social work
    </p>
    <div className="mt-4 flex justify-center gap-4">
      <span className="inline-flex items-center px-3 py-1 rounded-full bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200">
        LMSW Exam
      </span>
      <span className="inline-flex items-center px-3 py-1 rounded-full bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200">
        LCSW Exam
      </span>
    </div>
  </div>
);

const MoneyBackGuarantee: React.FC = () => (
  <div className="text-center mb-8">
    <p className="text-gray-900 dark:text-white font-bold">7-Day Money-Back Guarantee</p>
    <p className="text-gray-700 dark:text-gray-300">Not satisfied? Get a full refund, no questions asked.</p>
  </div>
);

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4">
      <button
        className="flex justify-between items-center w-full text-left font-semibold text-gray-900 dark:text-white bg-gray-200 dark:bg-gray-700 p-4 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{question}</span>
        <span className="text-xl">{isOpen ? '−' : '+'}</span>
      </button>
      {isOpen && (
        <div className="mt-2 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg text-gray-700 dark:text-gray-300">
          {answer}
        </div>
      )}
    </div>
  );
};

const Disclaimer: React.FC = () => (
  <div className="text-sm text-gray-600 mt-8 p-4 border border-gray-700 rounded-lg">
    <h4 className="font-semibold mb-2">Disclaimer:</h4>
    <p>
    At Social Work Test Prep Academy, we believe in fair pricing that promotes equity. Our goal is to make high-quality social work test prep accessible through fair and flexible pricing, ensuring that all aspiring social workers can achieve their goals without the burden of cost. Your success should be based on your dedication, not your wallet, and we are committed to supporting you on your journey.
    </p>
  </div>
);

const FAQ: React.FC = () => {
  const faqData = [
    {
      question: "What tests are included in the subscription?",
      answer: "Your subscription includes access to both the LCSW and LMSW full practice exams (170 questions each). Each test is carefully curated to match the format and difficulty of the actual ASWB exams. The LBSW test will be available soon at no additional cost."
    },
    {
      question: "How long do I have access to the tests after purchase?",
      answer: "You'll have unlimited access to both the LCSW and LMSW tests (170 questions each) for as long as your subscription is active."
    },
    {
      question: "Can I take the test more than once?",
      answer: "Absolutely! You can take the test as many times as you'd like as long as you're subscribed monthly. We encourage multiple attempts to help reinforce your learning and track your progress."
    },
    {
      question: "Is this test an exact replica of the actual ASWB LCSW exam?",
      answer: "While our test closely simulates the ASWB LCSW exam in terms of content areas and difficulty level, it's not an exact replica. Our questions are crafted by LCSW experts to prepare you thoroughly for the types of questions you'll encounter on the actual exam."
    },
    {
      question: "How does your test compare to other LCSW exam prep materials?",
      answer: "Our test stands out due to its accuracy, comprehensive explanations, and adaptive learning technology. We continuously update our question bank based on the latest ASWB guidelines and user feedback."
    },
    {
      question: "Will I get explanations for incorrect answers?",
      answer: "Yes! Each question comes with detailed explanations for both correct and incorrect answers. This helps you understand the reasoning behind each option and reinforces your learning."
    },
    {
      question: "Is there a mobile app available for studying on the go?",
      answer: "Currently, our platform is fully responsive and works great on mobile browsers. We're actively developing a dedicated mobile app to enhance your on-the-go study experience. Stay tuned for its release!"
    },
    {
      question: "What if I'm not satisfied with the test? Is there a refund policy?",
      answer: "We offer a 7-day money-back guarantee. If you're not completely satisfied with our test, contact our support team within 7 days of purchase for a full refund, no questions asked."
    },
    {
      question: "How often is the test content updated?",
      answer: "We review and update our test content quarterly to ensure it aligns with the latest ASWB exam guidelines. Any major changes to the LCSW exam are reflected in our test as soon as possible."
    },
    {
      question: "Can I pause the test and resume later?",
      answer: "Yes, you can pause the test at any time and resume where you left off. Your progress is automatically saved, allowing for flexible study sessions."
    },
    {
      question: "Do you offer any study materials besides the 170-question test?",
      answer: "While the 170-question test is our core offering, we're constantly expanding our study materials. Currently, we provide detailed explanations, a performance analytics dashboard, and targeted review suggestions based on your test results."
    },
    // {
    //   question: "How does your adaptive learning technology work?",
    //   answer: "Our adaptive learning algorithm analyzes your performance on each question and adjusts the difficulty of subsequent questions accordingly. This ensures you're always challenged at the right level and focuses your study on areas that need improvement."
    // },
    {
      question: "Is there a way to track my progress over time?",
      answer: "Absolutely! Our performance analytics dashboard allows you to track your scores, identify trends in your performance, and see which content areas need more focus. This feature helps you study more efficiently and effectively."
    },
    {
      question: "Can I use this test as my only study material for the LCSW exam?",
      answer: "While our test is comprehensive, we recommend using it in conjunction with other study materials for the best results. It's an excellent tool for practice and identifying knowledge gaps, but a well-rounded study approach typically yields the best outcomes."
    },
    // {
    //   question: "Do you offer any group discounts for study groups or institutions?",
    //   answer: "Yes, we offer discounted rates for groups of 5 or more. Please contact our sales team at sales@socialworktestprepacademy.com for more information on group pricing."
    // },
    {
      question: "How does your test prepare me for the time management aspect of the LCSW exam?",
      answer: "Our test closely mimics the time constraints of the actual LCSW exam. You'll have the same amount of time per question as you would in the real exam, helping you practice your pacing and time management skills."
    }
  ];

  return (
    <div className="text-white mb-8">
      <h3 className="font-bold text-2xl mb-6 text-center">Frequently Asked Questions</h3>
      {faqData.map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} />
      ))}
    </div>
  );
};

export default PricingPage;
