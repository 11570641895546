import { AdaptiveLearningEngine } from '../utils/adaptiveLearning';
import { logger } from '../utils/logger';

// Base performance interface
export interface CategoryPerformance {
  category: string;
  correctCount: number;
  totalCount: number;
  averageTime: number;
  lastAttempted: Date;
}

export interface CategoryStrength {
  correct: number;
  total: number;
  averageTime: number;
}

export interface QuestionAttempt {
    date: Date;
    isCorrect: boolean;
    timeSpent: number; // in seconds
    difficulty: number;
}

// Runtime state (with engine)
export interface AdaptiveState {
  engine: AdaptiveLearningEngine | null;
  currentDifficulty: number;
  categoryStrengths: Record<string, CategoryPerformance>;
  insights: AdaptiveInsights | null;
  recentlyAskedQuestions: number[];
  consecutiveCorrect: number;
  consecutiveIncorrect: number;
  totalQuestionsAttempted: number;
}

// Storage state (without engine)
export interface StorageAdaptiveState {
  currentDifficulty: number;
  categoryStrengths: Record<string, StorageCategoryPerformance>;
  insights: AdaptiveInsights;
  recentlyAskedQuestions: number[];
  consecutiveCorrect: number;
  consecutiveIncorrect: number;
}

// Storage version of performance data
export interface StorageCategoryPerformance extends Omit<CategoryPerformance, 'lastAttempted'> {
  lastAttempted: string;  // ISO date string
}

export interface AdaptiveInsights {
    weakCategories: WeakCategory[];
    recommendedDifficulty: number;
    suggestedPracticeFrequency: string;
    performanceTrend: ImprovementStatus;
    timeManagement: TimeManagement;
}

export interface TimeManagement {
    averageQuestionTime: number;
    recommendedTimePerQuestion: number;
    timeManagementAdvice: string;
}

export interface WeakCategory {
    category: string;
    successRate: number;
    recommendedFocus: string[];
}

// Firebase serialization interfaces
export interface SerializableAdaptiveState {
    insights: AdaptiveInsights;
    currentDifficulty: number;
    categoryStrengths: {
        [key: string]: Omit<CategoryPerformance, 'lastAttempted'> & {
            lastAttempted: string;
        };
    };
    recentlyAskedQuestions: number[];
    consecutiveCorrect: number;
    consecutiveIncorrect: number;
}

// Helper functions to compute derived values
export const getCategorySuccessRate = (performance: CategoryPerformance): number => {
    return performance.correctCount / (performance.totalCount || 1);
};

export const getCategoryTotalAttempts = (performance: CategoryPerformance): number => {
    return performance.totalCount;
};

// Helper to convert between Date and string for Firebase
export const serializeCategoryPerformance = (
    performance: CategoryPerformance
): SerializableAdaptiveState['categoryStrengths'][string] => {
    return {
        ...performance,
        lastAttempted: performance.lastAttempted.toISOString()
    };
};

export const deserializeCategoryPerformance = (
    serialized: SerializableAdaptiveState['categoryStrengths'][string]
): CategoryPerformance => {
    return {
        ...serialized,
        lastAttempted: new Date(serialized.lastAttempted)
    };
};

export interface FirebaseAdaptiveState extends Omit<SerializableAdaptiveState, 'categoryStrengths'> {
    categoryStrengths: {
        [key: string]: CategoryPerformance;
    };
}

// Conversion helpers
export const toStorageState = (state: AdaptiveState): StorageAdaptiveState => {
  logger.debug('AdaptiveState', 'Converting to storage state', {
    hasState: !!state,
    hasCategoryStrengths: !!state?.categoryStrengths,
    currentDifficulty: state?.currentDifficulty
  });

  if (!state) {
    logger.error('AdaptiveState', 'Missing state in toStorageState');
    throw new Error('Cannot convert undefined state to storage format');
  }

  try {
    const storageState: StorageAdaptiveState = {
      currentDifficulty: state.currentDifficulty ?? 5, // Default difficulty if undefined
      categoryStrengths: Object.fromEntries(
        Object.entries(state.categoryStrengths || {}).map(([key, value]) => {
          if (!value) {
            logger.warn('AdaptiveState', `Missing value for category ${key}`);
            return [key, {
              category: key,
              correctCount: 0,
              totalCount: 0,
              averageTime: 0,
              lastAttempted: new Date().toISOString()
            }];
          }
          
          return [
            key,
            {
              ...value,
              lastAttempted: value.lastAttempted?.toISOString() ?? new Date().toISOString()
            }
          ];
        })
      ),
      insights: state.insights ?? {
        weakCategories: [],
        recommendedDifficulty: state.currentDifficulty ?? 5,
        suggestedPracticeFrequency: 'daily',
        performanceTrend: 'steady',
        timeManagement: {
          averageQuestionTime: 0,
          recommendedTimePerQuestion: 60,
          timeManagementAdvice: 'Take your time to read each question carefully'
        }
      },
      recentlyAskedQuestions: state.recentlyAskedQuestions ?? [],
      consecutiveCorrect: state.consecutiveCorrect ?? 0,
      consecutiveIncorrect: state.consecutiveIncorrect ?? 0
    };

    logger.debug('AdaptiveState', 'Successfully converted to storage state', {
      categoryCount: Object.keys(storageState.categoryStrengths).length,
      hasInsights: !!storageState.insights
    });

    return storageState;
  } catch (error) {
    logger.error('AdaptiveState', 'Error converting to storage state', error);
    throw new Error('Failed to convert adaptive state to storage format');
  }
};

export const toRuntimeState = (
  storage: StorageAdaptiveState, 
  engine: AdaptiveLearningEngine | null
): AdaptiveState => {
  logger.debug('AdaptiveState', 'Converting to runtime state', {
    hasStorage: !!storage,
    hasEngine: !!engine,
    hasCategoryStrengths: !!storage?.categoryStrengths
  });

  if (!storage) {
    logger.error('AdaptiveState', 'Missing storage state in toRuntimeState');
    throw new Error('Cannot convert undefined storage state to runtime format');
  }

  try {
    const runtimeState: AdaptiveState = {
      engine,
      currentDifficulty: storage.currentDifficulty ?? 5,
      categoryStrengths: Object.fromEntries(
        Object.entries(storage.categoryStrengths || {}).map(([key, value]) => {
          if (!value) {
            logger.warn('AdaptiveState', `Missing value for category ${key}`);
            return [key, {
              category: key,
              correctCount: 0,
              totalCount: 0,
              averageTime: 0,
              lastAttempted: new Date()
            }];
          }

          return [
            key,
            {
              ...value,
              lastAttempted: new Date(value.lastAttempted)
            }
          ];
        })
      ),
      insights: storage.insights ?? {
        weakCategories: [],
        recommendedDifficulty: storage.currentDifficulty ?? 5,
        suggestedPracticeFrequency: 'daily',
        performanceTrend: 'steady',
        timeManagement: {
          averageQuestionTime: 0,
          recommendedTimePerQuestion: 60,
          timeManagementAdvice: 'Take your time to read each question carefully'
        }
      },
      recentlyAskedQuestions: storage.recentlyAskedQuestions ?? [],
      consecutiveCorrect: storage.consecutiveCorrect ?? 0,
      consecutiveIncorrect: storage.consecutiveIncorrect ?? 0,
      totalQuestionsAttempted: 0 // Assuming totalQuestionsAttempted is not provided in storage
    };

    logger.debug('AdaptiveState', 'Successfully converted to runtime state', {
      categoryCount: Object.keys(runtimeState.categoryStrengths).length,
      hasInsights: !!runtimeState.insights,
      hasEngine: !!runtimeState.engine
    });

    return runtimeState;
  } catch (error) {
    logger.error('AdaptiveState', 'Error converting to runtime state', error);
    throw new Error('Failed to convert storage state to runtime format');
  }
};

// Extended version for UI/Profile display
export interface EnhancedCategoryPerformance extends CategoryPerformance {
    improvement: ImprovementStatus;
    successRate: number;
    difficultyLevel: number;
}

// Simplified version for quick strength checks
export interface CategoryStrengthMetrics {
    successRate: number;
    totalAttempts: number;
}

export type ImprovementStatus = 'improving' | 'steady' | 'declining';

export interface StudyPlan {
    weeklyHours: number;
    recommendedPace: number;
    categoryPlans: CategoryPlan[];
    milestones: Milestone[];
}

export interface CategoryPlan {
    category: string;
    hoursPerWeek: number;
    focusAreas: string[];
    recommendedResources: string[];
}

export interface Milestone {
    category: string;
    date: string;
    targetScore: number;
    recommendedActions: string[];
    categoriesComplete: string[];
}

export interface CategoryMetrics {
  correct: number;
  total: number;
  averageTime: number;
  successRate: number;
}

export interface CategoryStrength {
  correctCount: number;
  totalCount: number;
  averageTime: number;
  lastAttempted?: string;
}

export interface RecentPerformance {
  date: string;
  score: number;
  category?: string;
  questionsAttempted: number;
  timeSpent?: number;
  difficulty?: number;
}

export interface DifficultyProgress {
  date: string;
  difficulty: number;
  successRate: number;
}

export interface Mastery {
  beginner: string[];      // Categories at beginner level
  intermediate: string[];  // Categories at intermediate level
  advanced: string[];      // Categories at advanced level
}

export interface PreferredTime {
  dayOfWeek: string;
  timeOfDay: string;
  averagePerformance: number;
}

export interface AdaptiveMetrics {
  startingDifficulty: number;
  endingDifficulty: number;
  averageResponseTime: number;
  categoryPerformance: Record<string, CategoryMetrics>;
  overallSuccessRate: number;
  totalQuestionsAttempted: number;
  currentStreak: number;
  longestStreak: number;
  lastSessionDate: string;
  weeklyActivityStreak: number;
  studyTimeThisWeek: number;
  estimatedExamReadiness: number;
  completedCategories: string[];
  mastery: Mastery;
  recentPerformance: RecentPerformance[];
  preferredTimes: PreferredTime[];
  difficultyProgression: DifficultyProgress[];
}

// Helper to create default metrics
export const createDefaultAdaptiveMetrics = (): AdaptiveMetrics => ({
  startingDifficulty: 5,
  endingDifficulty: 5,
  averageResponseTime: 0,
  categoryPerformance: {},
  overallSuccessRate: 0,
  totalQuestionsAttempted: 0,
  currentStreak: 0,
  longestStreak: 0,
  lastSessionDate: new Date().toISOString(),
  weeklyActivityStreak: 0,
  studyTimeThisWeek: 0,
  estimatedExamReadiness: 0,
  completedCategories: [],
  mastery: {
    beginner: [],
    intermediate: [],
    advanced: []
  },
  recentPerformance: [],
  preferredTimes: [],
  difficultyProgression: []
});