import { useState, useEffect, useMemo, useRef, useCallback, useLayoutEffect, useContext } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { QuizProgress, QuizType, TestType, QuizMode } from '../utils/quiz/types';
import { initializeNewQuiz } from '../utils/quiz/core';
import { fetchQuestions, getActiveQuiz, saveQuizProgress } from '../utils/firebase/quiz';
import { logAnalyticsEvent } from '../utils/Analytics';
import { AdaptiveLearningEngine } from '../utils/adaptiveLearning';
import { logger } from '../utils/logger';
import { useAdaptiveEngine } from '../contexts/AdaptiveEngineContext';

interface UseQuizReturn {
  quizProgress: QuizProgress | null;
  isLoading: boolean;
  error: string | null;
  adaptiveEngine: AdaptiveLearningEngine | null;
  initializeQuizWithParams: (params: QuizInitParams) => Promise<QuizProgress>;
  loadExistingQuiz: () => Promise<void>;
}

export interface QuizInitParams {
  testType: TestType;      // The specific test (LCSW, LMSW, etc.)
  mode: QuizMode;          // How the quiz is being taken (practice, full, category)
  category?: string;       // Optional category for category-focused quizzes
}

export const useQuiz = (quizId?: string): UseQuizReturn => {
  const { currentUser, userData, isLoading: authLoading } = useAuth();
  const { engine, engineId } = useAdaptiveEngine();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [quizProgress, setQuizProgress] = useState<QuizProgress | null>(null);
  const renderCount = useRef(0);
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      renderCount.current++;
      logger.debug('useQuiz', 'Initial render', { 
        count: renderCount.current,
        engineId,
        hasEngine: !!engine,
        authState: {
          hasUser: !!currentUser,
          hasUserData: !!userData,
          isAuthLoading: authLoading
        }
      });
      initialized.current = true;
    }
  }, [engineId, currentUser, userData, authLoading]);

  const initializeQuizWithParams = useCallback(async (params: QuizInitParams) => {
    logger.info('useQuiz', 'Initializing quiz with params', { params });
    setIsLoading(true);
    setError(null);
    
    if (!currentUser || !userData || !engine) {
      setIsLoading(false);
      logger.error('useQuiz', 'Missing required dependencies', {
        hasUser: !!currentUser,
        hasUserData: !!userData,
        hasEngine: !!engine
      });
      throw new Error('Authentication and engine required');
    }

    try {
      // 1. Fetch questions first
      const questions = await fetchQuestions({
        testType: params.testType,
        mode: params.mode,
        category: params.category,
        isSubscribed: userData.isSubscribed ?? false
      });

      logger.debug('useQuiz', 'Fetched questions', { 
        count: questions.length,
        testType: params.testType,
        mode: params.mode
      });

      // 2. Initialize quiz with engine
      const progress = initializeNewQuiz(
        questions, 
        params.testType,
        params.mode,
        params.category,
        engine
      );

      // 3. Create serializable version for Firebase
      const serializableProgress: QuizProgress = {
        ...progress,
        adaptiveState: {
          ...progress.adaptiveState,
          engine: progress.adaptiveState.engine,
          currentDifficulty: progress.adaptiveState.currentDifficulty || 5,
          categoryStrengths: progress.adaptiveState.categoryStrengths || {}
        },
        date: new Date().toISOString(),
        lastUpdated: new Date().toISOString()
      };

      logger.debug('useQuiz', 'Created serializable progress', {
        quizId: serializableProgress.quizId,
        hasQuestions: !!serializableProgress.questions,
        questionCount: serializableProgress.questions?.length,
        hasQuestionIds: !!serializableProgress.questionIds,
        questionIdsCount: serializableProgress.questionIds?.length,
        hasTestType: !!serializableProgress.testType,
        hasMode: !!serializableProgress.mode,
        hasAdaptiveState: !!serializableProgress.adaptiveState
      });

      // 4. Save to Firebase and wait for propagation
      logger.debug('useQuiz', 'Saving quiz progress to Firebase', {
        updateType: 'START',
        quizId: serializableProgress.quizId
      });

      await saveQuizProgress(serializableProgress, 'START');
      
      // Add delay to allow Firebase to propagate
      logger.debug('useQuiz', 'Waiting for Firebase propagation');
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      logger.debug('useQuiz', 'Quiz saved successfully', { 
        quizId: serializableProgress.quizId 
      });

      // 5. Set local state
      setQuizProgress(serializableProgress);
      setIsLoading(false);

      return serializableProgress;
    } catch (error) {
      setIsLoading(false);
      setError(error instanceof Error ? error : new Error('Failed to initialize quiz'));
      logger.error('useQuiz', 'Failed to initialize quiz', { 
        error,
        params 
      });
      throw error;
    }
  }, [currentUser, userData, engine]);

  const loadExistingQuiz = useCallback(async () => {
    if (!quizId) {
      setError(new Error('No quiz ID provided'));
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      logger.debug('useQuiz', 'Loading existing quiz', { quizId });
      
      // Add delay before fetching to allow for Firebase propagation
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const quiz = await getActiveQuiz(quizId);
      
      if (!quiz) {
        logger.error('useQuiz', 'Quiz not found', { quizId });
        setError(new Error('Quiz not found'));
        return;
      }

      setQuizProgress(quiz);
    } catch (error) {
      logger.error('useQuiz', 'Error loading quiz', { error, quizId });
      setError(error instanceof Error ? error : new Error('Failed to load quiz'));
    } finally {
      setIsLoading(false);
    }
  }, [quizId]);

  const handleAnswerSubmission = async (answer: string) => {
    if (!quizProgress) {
      logger.error('Quiz', 'No active quiz found');
      return;
    }

    const calculateNewScore = (answer: string): number => {
      const currentQuestion = quizProgress.questions?.[quizProgress.currentQuestionIndex ?? 0];
      return answer === currentQuestion?.correct_answer ? 
        (quizProgress.score ?? 0) + 1 : 
        (quizProgress.score ?? 0);
    };

    // Local state update
    const updatedProgress: QuizProgress = {
      ...quizProgress,
      currentQuestionIndex: (quizProgress.currentQuestionIndex ?? 0) + 1,
      score: calculateNewScore(answer),
      quizId: quizProgress.quizId, // Ensure required fields are present
      questions: quizProgress.questions,
      type: quizProgress.type
    };
    
    setQuizProgress(updatedProgress);

    // Save delta
    await saveQuizProgress(updatedProgress, 'ANSWER');
  };

  // Return loading state while auth is loading
  if (authLoading) {
    return {
      quizProgress: null,
      isLoading: true,
      error: null,
      adaptiveEngine: null,
      initializeQuizWithParams,
      loadExistingQuiz
    };
  }

  return {
    quizProgress,
    isLoading,
    error: error?.message ?? null,
    adaptiveEngine: engine,
    initializeQuizWithParams,
    loadExistingQuiz
  };
}; 