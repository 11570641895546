import React, { useEffect, useCallback, useState, useRef } from 'react';
import AnswerOptions from './AnswerOptions';
import { QuestionDisplay } from './quiz/QuestionDisplay';
import { ResultDisplay } from './quiz/ResultDisplay';
import { useQuizContext } from './quiz/state/QuizContext';
import { useAuth } from '../contexts/AuthContext';
import { TimeUtils } from '../utils/adaptiveLearning';
import { useQuizTimer } from '../hooks/useQuizTimer';
import QuizSummary  from './QuizSummary';
import { useNavigate } from 'react-router-dom';
import { logger } from '../utils/logger';
import { getCategoryBreakdown } from '../utils/quiz/core';

interface QuizComponentProps {
  onAnswerSelect: (answer: string, questionTime: number) => void;
  showHint: boolean;
  setShowHint: (show: boolean) => void;
  selectedAnswer: string | null;
  showResult: boolean;
  currentExplanation: string;
  handleExplanationChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSaveAndContinue: () => void;
  isSubscriber: boolean;
  showContinueButton: boolean;
  continueButtonText: string;
}

const QuizComponentBase = ({
  showHint,
  setShowHint,
  selectedAnswer,
  showResult,
  currentExplanation,
  handleExplanationChange,
  handleSaveAndContinue,
  isSubscriber,
  showContinueButton,
  continueButtonText
}: Omit<QuizComponentProps, 'quizProgress'>) => {
  const { userData } = useAuth();
  const { state, dispatch } = useQuizContext();
  const { timeSpent, startTimer, stopTimer, resetTimer } = useQuizTimer();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const completionCheckRef = useRef(false);
  
  // Get quiz progress from context
  const quizProgress = state.progress;
  if (!quizProgress) {
    logger.error('QuizComponent', 'Quiz progress not found in context', {
      state,
      hasProgress: !!state.progress
    });
    throw new Error('Quiz progress not found in context');
  }

  // Validate completion state on mount and updates
  useEffect(() => {
    if (!completionCheckRef.current && quizProgress) {
      const currentIndex = quizProgress.currentQuestionIndex ?? 0;
      const totalQuestions = quizProgress.questionIds?.length ?? 0;
      const shouldBeCompleted = currentIndex >= totalQuestions - 1 && showResult;
      
      if (shouldBeCompleted && !quizProgress.completed) {
        logger.warn('QuizComponent', 'Detected inconsistent completion state', {
          currentIndex,
          totalQuestions,
          showResult,
          isCompleted: quizProgress.completed
        });
        
        // Generate summary if not available
        const summary = state.summary ?? {
          totalQuestions,
          answeredQuestions: currentIndex + 1,
          correctAnswers: quizProgress.score ?? 0,
          wrongAnswers: quizProgress.wrongAnswers?.length ?? 0,
          score: quizProgress.score ?? 0,
          completionPercentage: ((quizProgress.score ?? 0) / totalQuestions) * 100,
          categoryBreakdown: getCategoryBreakdown(quizProgress) ?? {}
        };
        
        // Force completion state
        dispatch({
          type: 'QUIZ_COMPLETE',
          payload: {
            finalScore: (quizProgress.score ?? 0),
            timestamp: Date.now(),
            adaptiveState: quizProgress.adaptiveState,
            summary
          }
        });
      }
      completionCheckRef.current = true;
    }
  }, [quizProgress, showResult, dispatch, state.summary]);

  // Get adaptive state from quiz progress
  const adaptiveState = quizProgress.adaptiveState ?? {
    difficulty: 5,
    categoryStrengths: {},
    insights: {
      weakCategories: [],
      performanceTrend: 'steady',
      recommendedDifficulty: 5
    }
  };
  
  // Get current question with completion check
  const currentQuestion = React.useMemo(() => {
    if (!quizProgress) {
      logger.error('QuizComponent', 'No quiz progress available for current question check', {
        progress: null
      });
      return null;
    }
    
    // Add logging for completion check
    logger.debug('QuizComponent', 'Checking quiz state', {
      completed: quizProgress.completed,
      currentIndex: quizProgress.currentQuestionIndex,
      totalQuestions: quizProgress.questions?.length ?? 0,
      questionIds: quizProgress.questionIds?.length ?? 0,
      hasQuestions: !!quizProgress.questions,
      firstQuestionId: quizProgress.questions?.[0]?.id,
      adaptiveState: !!adaptiveState
    });
    
    if (quizProgress.completed || 
        (quizProgress.currentQuestionIndex ?? 0) >= (quizProgress.questions?.length ?? 0)) {
      logger.info('QuizComponent', 'Quiz is completed or at end', {
        completed: quizProgress.completed,
        currentIndex: quizProgress.currentQuestionIndex,
        totalQuestions: quizProgress.questions?.length
      });
      return null;
    }
    
    const question = quizProgress.questions?.[quizProgress.currentQuestionIndex ?? 0];
    if (!question) {
      logger.error('QuizComponent', 'Current question not found', {
        currentIndex: quizProgress.currentQuestionIndex,
        questions: quizProgress.questions?.map(q => q.id),
        totalQuestions: quizProgress.questions?.length
      });
      return null;
    }

    return {
      ...question,
      difficulty: adaptiveState.currentDifficulty,
      recommendedTime: TimeUtils.getExpectedTime(adaptiveState.currentDifficulty)
    };
  }, [quizProgress, adaptiveState]);
  // Add after currentQuestion memo
  useEffect(() => {
    if (currentQuestion && !selectedAnswer && !showResult) {
      resetTimer();
      startTimer();
      
      dispatch({
        type: 'UPDATE_QUESTION_START_TIME',
        payload: Date.now()
      });
    }
  }, [currentQuestion?.id, selectedAnswer, showResult, resetTimer, startTimer, dispatch]);

  // Replace the timestamp logging effect with this version
  useEffect(() => {
    // Only log on meaningful state changes
    if (!currentQuestion) return;

    const timestamp = new Date().toISOString();
    logger.debug('QuizComponent', 'Dependencies changed', {
      timestamp,
      currentQuestionId: currentQuestion.id,
      selectedAnswer,
      showResult,
      currentIndex: state.progress?.currentQuestionIndex
    });

    return () => {
      logger.debug('QuizComponent', 'Cleanup effect');
    };
  }, [currentQuestion?.id, selectedAnswer, showResult, state.progress?.currentQuestionIndex]);

  // Handle answer selection
  const handleAnswerClick = React.useCallback((answer: string) => {
    // if (isTransitioning) return;
    // setIsTransitioning(true);
    if (!showResult && !selectedAnswer && currentQuestion) {
      const questionTime = stopTimer();
      
      logger.debug('QuizComponent', 'Answer selected', {
        questionId: currentQuestion.id,
        timeSpent: questionTime,
        isLastQuestion: quizProgress?.currentQuestionIndex === (quizProgress?.questions?.length ?? 0) - 1
      });
      
      dispatch({ 
        type: 'SET_ANSWER_WITH_TIME', 
        payload: {
          answer,
          timeSpent: questionTime,
          questionId: currentQuestion.id
        }
      });
    }

    // setShowResult(true);
  }, [showResult, selectedAnswer, currentQuestion, stopTimer, dispatch]);
  
  const handleContinue = useCallback(async () => {
    try {
      setFadeOut(true);
      setIsLoading(true);
      
      const currentIndex = quizProgress.currentQuestionIndex ?? 0;
      const totalQuestions = quizProgress.questionIds?.length ?? 0;
      const isLastQuestion = currentIndex >= totalQuestions - 1;
      
      if (isLastQuestion && showResult) {
        const categoryBreakdown = getCategoryBreakdown(quizProgress);
        
        dispatch({ 
          type: 'QUIZ_COMPLETE', 
          payload: {
            timestamp: Date.now(),
            finalScore: quizProgress.score ?? 0,
            adaptiveState: quizProgress.adaptiveState,
            summary: {
              totalQuestions,
              answeredQuestions: currentIndex + 1,
              correctAnswers: quizProgress.score ?? 0,
              wrongAnswers: quizProgress.wrongAnswers?.length ?? 0,
              score: quizProgress.score ?? 0,
              completionPercentage: ((quizProgress.score ?? 0) / totalQuestions) * 100,
              categoryBreakdown: categoryBreakdown ?? {}
            }
          }
        });
        return;
      }

      await handleSaveAndContinue();
      setFadeOut(false);
    } catch (error) {
      logger.error('QuizComponent', 'Failed to handle continue', error);
    } finally {
      setIsLoading(false);
    }
  }, [quizProgress, showResult, dispatch, handleSaveAndContinue]);

  // Add status window handling
  const openStatusWindow = useCallback(() => {
    const quizId = quizProgress?.quizId;
    if (!quizId) return;

    const statusUrl = `${window.location.origin}/quiz/${quizId}?view=status`;
    window.open(statusUrl, `quiz_status_${quizId}`, 'width=600,height=800');
  }, [quizProgress?.quizId]);

  if (quizProgress?.completed) {
    logger.info('QuizComponent', 'Quiz is completed, showing summary', {
      completed: quizProgress.completed,
      currentIndex: quizProgress.currentQuestionIndex,
      totalQuestions: quizProgress.questions?.length ?? 0
    });
    
    return (
      <QuizSummary
        quizProgress={quizProgress}
        timeSpent={timeSpent}
        onRestartQuiz={() => navigate('/dashboard')}
        showFeedbackButton={true}
      />
    );
  }

  // Handle quiz completion or invalid state
  if (!currentQuestion) {
    // If we get here, there's an actual error
    logger.error('QuizComponent', 'Invalid quiz state', {
      currentIndex: quizProgress.currentQuestionIndex,
      hasQuestions: !!quizProgress.questions,
      questionCount: quizProgress.questions?.length,
      completed: quizProgress.completed,
      quizId: quizProgress.quizId
    });
    
    return (
      <div className="text-center py-8 space-y-4">
        <div className="text-red-500">
          Error loading quiz content. Please try again.
        </div>
        <div className="text-sm text-gray-500">
          {quizProgress.completed ? 
            "This quiz appears to be completed." : 
            "Unable to load the current question."}
        </div>
        <button
          onClick={() => navigate('/dashboard')}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          Return to Dashboard
        </button>
      </div>
    );
  }

  return (
    <div className={`${isSubscriber ? 'subscriber-quiz' : 'free-quiz'} space-y-6 relative`}>
      {/* Add status button */}
      <div className="absolute top-4 right-4">
        <button
          onClick={openStatusWindow}
          className="bg-gray-100 hover:bg-gray-200 text-gray-700 px-4 py-2 rounded-lg flex items-center space-x-2 transition-colors"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
            <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
          </svg>
          <span>View Status</span>
        </button>
      </div>

      {isLoading && (
        <div className="absolute inset-0 bg-white/50 dark:bg-gray-900/50 z-50 flex items-center justify-center">
          <div className="flex flex-col items-center space-y-4">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            <p className="text-gray-600 dark:text-gray-300">Loading next question...</p>
          </div>
        </div>
      )}
      
      <div className={`transition-opacity duration-300 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}>
        <QuestionDisplay
          question={currentQuestion}
          showHint={showHint}
          setShowHint={setShowHint}
          adaptiveState={adaptiveState}
          timeSpent={timeSpent}
        />
        
        <AnswerOptions
          currentQuestion={currentQuestion}
          selectedAnswer={selectedAnswer}
          showResult={showResult}
          handleAnswer={handleAnswerClick}
          isCorrect={(option) => option === currentQuestion.correct_answer}
        />

        {showResult && selectedAnswer && (
          <ResultDisplay
            question={currentQuestion}
            selectedAnswer={selectedAnswer}
            currentExplanation={currentExplanation}
            handleExplanationChange={handleExplanationChange}
            handleSaveAndContinue={handleContinue}
            showContinueButton={showContinueButton}
            continueButtonText={continueButtonText}
          />
        )}
      </div>
    </div>
  );
};

QuizComponentBase.displayName = 'QuizComponent';

// Add a render logging wrapper
const withRenderLogging = (WrappedComponent: React.ComponentType<any>, componentName: string) => {
  const WithLogging = React.memo((props: any) => {
    console.log(`[Render] ${componentName}`, {
      props: { ...props, children: undefined }, // Exclude children from logging
      timestamp: new Date().toISOString()
    });
    return <WrappedComponent {...props} />;
  });

  // Set display name for the wrapped component
  WithLogging.displayName = `WithLogging(${componentName})`;
  return WithLogging;
};

// Add display name to base component
QuizComponentBase.displayName = 'QuizComponent';

// Export with memo and logging
const MemoizedQuizComponent = React.memo(QuizComponentBase, (prevProps, nextProps) => {
  const areEqual = (
    prevProps.selectedAnswer === nextProps.selectedAnswer &&
    prevProps.showResult === nextProps.showResult &&
    prevProps.showHint === nextProps.showHint &&
    prevProps.currentExplanation === nextProps.currentExplanation
  );

  console.log('[Memo Comparison]', {
    areEqual,
    changedProps: Object.keys(prevProps).filter(key => 
      prevProps[key as keyof typeof prevProps] !== nextProps[key as keyof typeof nextProps]
    )
  });

  return areEqual;
});

MemoizedQuizComponent.displayName = 'MemoizedQuizComponent';

export default withRenderLogging(MemoizedQuizComponent, 'QuizComponent');

