import { db, auth } from '../../firebase';
import { 
  doc, 
  getDoc, 
  updateDoc, 
  collection, 
  query, 
  where, 
  getDocs, 
  deleteField, 
  limit as firestoreLimit,
  QueryConstraint,
  addDoc,
  runTransaction,
  FirestoreError,
  setDoc
} from 'firebase/firestore';
import { 
  Question,
  QuizProgress
} from '../quiz/types';
import { TestType, QuizMode, QuizType } from '../../models/SharedTypes';
import { TestType as TestTypeEnum, QuizMode as QuizModeEnum } from '../quiz/enums';
// import { sanitizeQuestion } from '../quiz/validation';
import { determineQuizType } from './migration';
// import typedQuestionsData from '../questions.json';
import { QuizError, QuizErrorCodes } from '../quiz/errors';
// import { quizCache, questionCache } from './cache';
// import { CACHE_CONFIG } from './cache';
import { logger } from '../logger';

interface CacheKeyGenerators {
  questions: (params: QuizFetchParams) => string;
  quiz: (userId: string, quizId: number) => string;
  activeQuizzes: (userId: string) => string;
}

const generateCacheKey: CacheKeyGenerators = {
  questions: (params: QuizFetchParams): string => 
    `questions:${params.testType}:${params.category ?? 'all'}:${params.questionIds?.join(',') ?? 'none'}`,
    
  quiz: (userId: string, quizId: number): string => 
    `quiz:${userId}:${quizId}`,
    
  activeQuizzes: (userId: string): string => 
    `activeQuizzes:${userId}`
};

interface QuizData {
  quizzes?: Record<string, QuizRef>;
  quizProgress?: LegacyQuizData; // Now properly typed
}

interface QuizRef {
  id: string;
  lastUpdated: string;
  ref: string;
  testType: TestType;     // The specific test type
  mode: QuizMode;         // How the quiz is being taken
  type?: QuizType; // Optional type field from the quiz
}

interface StoredQuizData {
  quizId?: string;
  currentQuestionIndex?: number;
  initialQuestionIndex?: number;
  score?: number;
  date?: string;
  lastUpdated?: string;
  wrongAnswers?: any[];
  questions?: Question[];
  questionIds?: string[];  // Explicitly typed as string[]
  testType: TestType;     // The specific test type
  mode: QuizMode;         // How the quiz is being taken
  type?: QuizType; // Optional type field from the quiz
  category?: string;
  adaptiveState?: any;
  completed?: boolean;
}

interface LegacyQuizData {
  quizId: string | number;
  currentQuestionIndex?: number;
  score?: number;
  questions?: Array<Partial<Question> & { id: string | number }>;
  wrongAnswers?: string[];
  date?: string;
  category?: string;
  type?: QuizType;
}

interface QuizDelta {
  type: 'ANSWER' | 'START' | 'COMPLETE' | 'METADATA';
  timestamp: number;
  quizId: string;
  userId: string;
  changes: {
    path: string;
    value: any;
  }[];
  processed: boolean;
  quizRef?: string;
}

// Constants for Firestore limits
const FIRESTORE_LIMITS = {
  MAX_STRING_LENGTH: 1048487, // Firestore's max string length
  MAX_ARRAY_LENGTH: 20000,    // Firestore's max array elements
  MAX_DOC_SIZE: 1048576       // 1MB in bytes
};

// Add before validateQuizId
const estimateDocumentSize = (obj: any): number => {
  const stringify = (val: any): string => {
    if (val === null || val === undefined) return '';
    if (typeof val === 'string') return val;
    return JSON.stringify(val);
  };

  let size = 0;
  const processObject = (obj: any) => {
    for (const key in obj) {
      // Add key size
      size += key.length;
      
      const value = obj[key];
      if (Array.isArray(value)) {
        value.forEach(item => {
          if (typeof item === 'object' && item !== null) {
            processObject(item);
          } else {
            size += stringify(item).length;
          }
        });
      } else if (typeof value === 'object' && value !== null) {
        processObject(value);
      } else {
        size += stringify(value).length;
      }
    }
  };

  processObject(obj);
  return size;
};

// Add before validateQuizId
const validateQuizId = (id: any): boolean => {
  // Add detailed logging
  logger.debug('Quiz', 'Validating quiz ID', {
    id,
    type: typeof id,
    stringified: String(id)
  });

  // Handle string IDs (common in Firestore)
  if (typeof id === 'string') {
    // Allow non-empty string IDs
    return id.length > 0;
  }

  // Handle numeric IDs
  const numId = Number(id);
  const isValid = (
    !isNaN(numId) && 
    Number.isFinite(numId) && 
    Number.isInteger(numId) && 
    numId > 0 && 
    numId <= Number.MAX_SAFE_INTEGER
  );

  if (!isValid) {
    logger.debug('Quiz', 'Quiz ID validation failed', {
      id,
      numericId: numId,
      checks: {
        isNaN: !isNaN(numId),
        isFinite: Number.isFinite(numId),
        isInteger: Number.isInteger(numId),
        isPositive: numId > 0,
        isSafe: numId <= Number.MAX_SAFE_INTEGER
      }
    });
  }

  return isValid;
};

const sanitizeQuizId = (id: string | number): string => {
  return String(id);
};

// Add after FIRESTORE_LIMITS
const detectCircularReferences = (obj: any, seen = new WeakSet()): string[] => {
  const paths: string[] = [];
  
  const detect = (obj: any, path: string[] = []) => {
    if (obj && typeof obj === 'object') {
      if (seen.has(obj)) {
        paths.push(path.join('.'));
        return;
      }
      seen.add(obj);
      
      for (const [key, value] of Object.entries(obj)) {
        detect(value, [...path, key]);
      }
    }
  };
  
  detect(obj);
  return paths;
};

const validateQuizType = (type: any): type is QuizType => {
  return Object.values(QuizType).includes(type);
};

const validateAdaptiveState = (state: any): boolean => {
  if (!state) return true; // null is valid
  
  if (typeof state !== 'object') {
    logger.error('Quiz', 'Invalid adaptive state type', { type: typeof state });
    return false;
  }

  // Validate categoryStrengths
  if (state.categoryStrengths && typeof state.categoryStrengths === 'object') {
    const strengths = state.categoryStrengths as Record<string, {
      correctCount?: number | string;
      totalCount?: number | string;
      averageTime?: number | string;
    }>;

    for (const [category, strength] of Object.entries(strengths)) {
      if (!strength || typeof strength !== 'object') {
        logger.error('Quiz', 'Invalid category strength', { category, strength });
        return false;
      }

      const requiredFields = ['correctCount', 'totalCount', 'averageTime'] as const;
      for (const field of requiredFields) {
        const value = Number(strength[field]);
        if (isNaN(value) || !isFinite(value)) {
          logger.error('Quiz', `Invalid ${field} in category strength`, { 
            category, 
            field, 
            value: strength[field] 
          });
          return false;
        }
      }
    }
  }

  // Validate insights
  if (state.insights) {
    const { recommendedDifficulty } = state.insights;
    if (typeof recommendedDifficulty !== 'undefined') {
      const difficulty = Number(recommendedDifficulty);
      if (isNaN(difficulty) || difficulty < 1 || difficulty > 10) {
        logger.error('Quiz', 'Invalid recommended difficulty', { difficulty });
        return false;
      }
    }
  }

  return true;
};

export type ValidationResult = {
  isValid: boolean;
  error?: string;
  details?: any;
};

const validateQuestion = (q: Partial<Question>): ValidationResult => {
  if (!q.id) return { isValid: false, error: 'Question missing ID' };
  if (!q.category) return { isValid: false, error: 'Question missing category' };
  if (!q.question) return { isValid: false, error: 'Question missing content' };
  if (!q.option_a || !q.option_b || !q.option_c || !q.option_d) {
    return { 
      isValid: false, 
      error: 'Question missing options',
      details: {
        hasOptionA: !!q.option_a,
        hasOptionB: !!q.option_b,
        hasOptionC: !!q.option_c,
        hasOptionD: !!q.option_d
      }
    };
  }
  if (!q.correct_answer) return { isValid: false, error: 'Question missing correct answer' };
  if (!q.explanation) return { isValid: false, error: 'Question missing explanation' };

  return { isValid: true };
};

const validateQuizData = (data: Partial<QuizProgress>): ValidationResult => {
  try {
    // Log what we're validating
    logger.debug('Quiz', 'Starting quiz validation', {
      quizId: data.quizId,
      questionCount: data.questions?.length
    });

    // Essential structure check
    if (!data.quizId) {
      return { 
        isValid: false, 
        error: 'Missing quiz ID',
        details: { quizId: data.quizId }
      };
    }

    if (!data.questions?.length) {
      return { 
        isValid: false, 
        error: 'No questions found',
        details: { 
          hasQuestions: !!data.questions,
          length: data.questions?.length 
        }
      };
    }

    // Size limit check (Firestore requirement)
    try {
      // Create a safe copy without the engine for size estimation
      const sizingCopy = {
        ...data,
        adaptiveState: data.adaptiveState ? {
          ...data.adaptiveState,
          engine: null // Remove engine instance
        } : null
      };
      
      const estimatedSize = JSON.stringify(sizingCopy).length;
      logger.debug('Quiz', 'Estimated quiz size', { 
        size: estimatedSize,
        limit: FIRESTORE_LIMITS.MAX_DOC_SIZE 
      });

      if (estimatedSize > FIRESTORE_LIMITS.MAX_DOC_SIZE) {
        return {
          isValid: false,
          error: 'Quiz data exceeds Firestore size limit',
          details: {
            size: estimatedSize,
            limit: FIRESTORE_LIMITS.MAX_DOC_SIZE,
            questionCount: data.questions.length
          }
        };
      }
    } catch (serializationError) {
      logger.error('Quiz', 'Error estimating quiz size', { 
        error: serializationError,
        hasEngine: !!data.adaptiveState?.engine
      });
      return {
        isValid: false,
        error: 'Unable to estimate quiz size - serialization error',
        details: { error: serializationError }
      };
    }

    if (data.questions.length > FIRESTORE_LIMITS.MAX_ARRAY_LENGTH) {
      return {
        isValid: false,
        error: 'Too many questions for Firestore array limit',
        details: {
          length: data.questions.length,
          max: FIRESTORE_LIMITS.MAX_ARRAY_LENGTH
        }
      };
    }

    // Question content validation
    // for (let i = 0; i < data.questions.length; i++) {
    //   const questionValidation = validateQuestion(data.questions[i]);
    //   if (!questionValidation.isValid) {
    //     return {
    //       isValid: false,
    //       error: `Invalid question at index ${i}: ${questionValidation.error}`,
    //       details: {
    //         index: i,
    //         questionId: data.questions[i].id,
    //         ...questionValidation.details
    //       }
    //     };
    //   }
    // }

    // Question IDs validation
    if (data.questionIds) {
      const questionIdSet = new Set(data.questions.map(q => q.id));
      const providedIdSet = new Set(data.questionIds);
      
      const missingIds = Array.from(questionIdSet).filter(id => !providedIdSet.has(id));
      if (missingIds.length > 0) {
        return {
          isValid: false,
          error: 'Question ID mismatch between questions and questionIds',
          details: {
            missingIds: missingIds.slice(0, 3),
            questionCount: data.questions.length,
            idCount: data.questionIds.length
          }
        };
      }
    }

    return { isValid: true };
  } catch (error) {
    // This catch block should now only trigger for truly unexpected errors
    logger.error('Quiz', 'Unexpected error during quiz validation', { 
      error,
      quizId: data.quizId,
      stage: 'validation'
    });
    return {
      isValid: false,
      error: 'Unexpected validation error',
      details: { error }
    };
  }
};

const MAX_RETRY_ATTEMPTS = 3;
const RETRY_DELAY_MS = 1000;

interface TransactionMetrics {
  stageTiming: Record<string, number>;
  lastStageTime: number;
  validationTime?: number;
  serializationTime?: number;
  documentSize: number;
  memoryUsage: {
    heapUsed: number;
    heapTotal: number;
  };
}

interface TransactionContext {
  attempt: number;
  startTime: number;
  stage: 'init' | 'validation' | 'serialization' | 'user_check' | 'quiz_save' | 'user_update' | 'complete';
  metrics: TransactionMetrics;
}

const measurePerformance = () => {
  // Use performance.memory in Chrome/Edge, fallback to estimates in other browsers
  const memory = (window.performance as any).memory || {
    usedJSHeapSize: 0,
    totalJSHeapSize: 0
  };

  return {
    heapUsed: Math.round(memory.usedJSHeapSize / 1024 / 1024), // MB
    heapTotal: Math.round(memory.totalJSHeapSize / 1024 / 1024) // MB
  };
};

// Add a type guard for memory metrics
const hasMemoryMetrics = (metrics: any): metrics is { heapUsed: number; heapTotal: number } => {
  return metrics && typeof metrics.heapUsed === 'number' && typeof metrics.heapTotal === 'number';
};

const updateMetrics = (context: TransactionContext, newStage: TransactionContext['stage']) => {
  const now = Date.now();
  if (context.metrics.lastStageTime) {
    context.metrics.stageTiming[context.stage] = now - context.metrics.lastStageTime;
  }
  context.stage = newStage;
  context.metrics.lastStageTime = now;
};

// Add after TransactionContext interface
interface ErrorClassification {
  type: 'FIRESTORE' | 'VALIDATION' | 'UNKNOWN';
  code?: string;
  retryable: boolean;
}

const classifyError = (error: unknown): ErrorClassification => {
  if (error instanceof FirestoreError) {
    return {
      type: 'FIRESTORE',
      code: error.code,
      retryable: ['deadline-exceeded', 'unavailable', 'resource-exhausted'].includes(error.code)
    };
  }
  if (error instanceof QuizError) {
    return {
      type: 'VALIDATION',
      code: error.code,
      retryable: error.recoverable
    };
  }
  return {
    type: 'UNKNOWN',
    retryable: false
  };
};

const saveFullQuizState = async (progress: QuizProgress): Promise<void> => {
  const user = auth.currentUser;
  if (!user) throw new QuizError('No authenticated user', QuizErrorCodes.SAVE_FAILED, false);

  // Validate before attempting save
  const validation = validateQuizData(progress);
  if (!validation.isValid) {
    logger.error('Quiz', 'Quiz validation failed', {
      error: validation.error,
      details: validation.details
    });
    throw new QuizError(
      `Invalid quiz data: ${validation.error}`, 
      QuizErrorCodes.INVALID_DATA, 
      false
    );
  }

  let context: TransactionContext = {
    attempt: 0,
    startTime: Date.now(),
    stage: 'init',
    metrics: {
      stageTiming: {},
      lastStageTime: Date.now(),
      documentSize: 0,
      memoryUsage: measurePerformance()
    }
  };

  let lastError: Error | null = null;

  try {
    // Validation phase
    updateMetrics(context, 'validation');
    const sanitizedProgress = convertToQuizProgress(progress);
    const quizId = sanitizeQuizId(sanitizedProgress.quizId);

    if (!validateQuizData(sanitizedProgress)) {
      const validationError = new QuizError('Invalid quiz data structure', QuizErrorCodes.INVALID_DATA, false);
      const errorClass = classifyError(validationError);
      logger.error('Quiz', 'Validation failed', {
        error: validationError,
        errorType: errorClass.type,
        duration: Date.now() - context.metrics.lastStageTime,
        memoryUsage: measurePerformance()
      });
      throw validationError;
    }
    context.metrics.validationTime = Date.now() - context.metrics.lastStageTime;

    // Serialization phase
    updateMetrics(context, 'serialization');
    const { docData, adaptiveState } = await (async () => {
      // Remove engine instance and convert dates to ISO strings
      const adaptiveState = sanitizedProgress.adaptiveState ? {
        ...sanitizedProgress.adaptiveState,
        engine: null,
        categoryStrengths: Object.fromEntries(
          Object.entries(sanitizedProgress.adaptiveState.categoryStrengths || {}).map(([key, value]) => [
            key,
            {
              ...value,
              lastAttempted: value.lastAttempted instanceof Date 
                ? value.lastAttempted.toISOString() 
                : value.lastAttempted,
              correctCount: Number(value.correctCount) || 0,
              totalCount: Number(value.totalCount) || 0,
              averageTime: Number(value.averageTime) || 0
            }
          ])
        ),
        insights: sanitizedProgress.adaptiveState.insights ? {
          ...sanitizedProgress.adaptiveState.insights,
          recommendedDifficulty: Number(sanitizedProgress.adaptiveState.insights.recommendedDifficulty) || 5
        } : null
      } : null;

      // Format wrong answers properly
      const wrongAnswers = Array.isArray(sanitizedProgress.wrongAnswers) 
        ? sanitizedProgress.wrongAnswers
            .filter((wa: any) => wa && typeof wa === 'object')
            .map((wa: any) => ({
              question: {
                id: String(wa.question?.id || ''),
                category: String(wa.question?.category || ''),
                question: String(wa.question?.question || ''),
                correct_answer: String(wa.question?.correct_answer || ''),
                selected_answer: String(wa.question?.selected_answer || '')
              },
              explanation: String(wa.explanation || ''),
              date: wa.date ? String(wa.date) : new Date().toISOString(),
              isReviewed: Boolean(wa.isReviewed),
              attempts: Number(wa.attempts || 1)
            }))
        : [];

      logger.debug('Quiz', 'Processing wrong answers for save', {
        originalCount: sanitizedProgress.wrongAnswers?.length || 0,
        formattedCount: wrongAnswers.length,
        sampleWrongAnswer: wrongAnswers[0] ? JSON.stringify(wrongAnswers[0]) : undefined,
        wrongAnswerStructure: wrongAnswers[0] ? Object.keys(wrongAnswers[0]) : [],
        rawWrongAnswers: sanitizedProgress.wrongAnswers?.slice(0, 2).map(wa => JSON.stringify({
          question: wa?.question ? {
            id: wa.question.id,
            category: wa.question.category,
            selected_answer: wa.question.selected_answer,
            correct_answer: wa.question.correct_answer
          } : null,
          explanation: wa?.explanation,
          date: wa?.date,
          isReviewed: wa?.isReviewed,
          attempts: wa?.attempts
        }))
      });

      const docData = {
        quizId: String(quizId),
        currentQuestionIndex: Number(sanitizedProgress.currentQuestionIndex),
        score: Number(sanitizedProgress.score),
        testType: sanitizedProgress.testType || TestTypeEnum.LCSW_FREE,
        mode: sanitizedProgress.mode || QuizModeEnum.PRACTICE,
        type: sanitizedProgress.type || QuizType.SAMPLE,
        category: sanitizedProgress.category || 'unknown',
        questionIds: sanitizedProgress.questionIds || 
          (sanitizedProgress.questions?.map(q => String(q.id)) || []),
        wrongAnswers, // Use the formatted wrong answers
        adaptiveState,
        userId: user.uid,
        lastUpdated: new Date().toISOString(),
        date: sanitizedProgress.date || new Date().toISOString(),
        completed: sanitizedProgress.completed || false
      };

      // Log the document structure before saving
      logger.debug('Quiz', 'Document structure before save', {
        quizId,
        wrongAnswersCount: wrongAnswers.length,
        docSize: estimateDocumentSize(docData),
        docStructure: Object.keys(docData),
        wrongAnswersPresent: wrongAnswers.length > 0,
        wrongAnswersSample: wrongAnswers.slice(0, 1)
      });

      return { docData, adaptiveState };
    })();
    context.metrics.serializationTime = Date.now() - context.metrics.lastStageTime;

    // Size check and warnings
    const estimatedSize = estimateDocumentSize(docData);
    context.metrics.documentSize = estimatedSize;
    
    if (estimatedSize > 512 * 1024) { // 512KB warning threshold
      logger.warn('Quiz', 'Large document warning', {
        size: estimatedSize,
        threshold: 512 * 1024,
        suggestion: 'Consider implementing delta compression for questions array',
        metrics: {
          questionCount: docData.questionIds?.length || 0,
          averageQuestionSize: Math.round(estimatedSize / (docData.questionIds?.length || 1))
        }
      });
    }
    
    if (estimatedSize > FIRESTORE_LIMITS.MAX_DOC_SIZE) {
      const sizeError = new QuizError('Document size too large', QuizErrorCodes.DOC_SIZE_EXCEEDED, false);
      const errorClass = classifyError(sizeError);
      logger.error('Quiz', 'Document size exceeds Firestore limit', {
        size: estimatedSize,
        limit: FIRESTORE_LIMITS.MAX_DOC_SIZE,
        errorType: errorClass.type,
        metrics: context.metrics
      });
      throw sizeError;
    }

    while (context.attempt < MAX_RETRY_ATTEMPTS) {
      try {
        await runTransaction(db, async (transaction) => {
          // User check phase
          updateMetrics(context, 'user_check');
          const userRef = doc(db, 'users', user.uid);
          const userDoc = await transaction.get(userRef);
          
          if (!userDoc.exists()) {
            throw new QuizError('User document not found', QuizErrorCodes.USER_NOT_FOUND, false);
          }

          // Quiz save phase
          updateMetrics(context, 'quiz_save');
          const quizRef = doc(db, 'quizzes', `${user.uid}_${quizId}`);
          transaction.set(quizRef, docData);

          // Update user's quizzes reference
          const userData = userDoc.data();
          const updatedQuizzes = {
            ...(userData?.quizzes || {}),
            [quizId]: {
              id: quizId,
              lastUpdated: docData.lastUpdated,
              ref: quizRef.path,
              testType: docData.testType,
              mode: docData.mode,
              type: docData.type,
              completed: docData.completed,
              wrongAnswers: docData.wrongAnswers
            }
          };

          transaction.update(userRef, {
            quizzes: updatedQuizzes
          });

          // Remove redundant user update
          updateMetrics(context, 'complete');
        });

        // Success logging with size metrics
        logger.info('Quiz', 'Saved quiz state successfully', {
          quizId,
          userId: user.uid,
          metrics: {
            ...context.metrics,
            totalDuration: Date.now() - context.startTime,
            attempts: context.attempt + 1,
            finalMemoryUsage: measurePerformance(),
            stageTiming: context.metrics.stageTiming,
            documentSizeRatio: Math.round((estimatedSize / FIRESTORE_LIMITS.MAX_DOC_SIZE) * 100) + '%'
          }
        });
        return;

      } catch (error) {
        lastError = error instanceof Error ? error : new Error('Unknown error occurred');
        context.attempt++;
        const currentMemory = measurePerformance();
        const errorClass = classifyError(error);

        logger.warn('Quiz', 'Transaction attempt failed', {
          attempt: context.attempt,
          stage: context.stage,
          error: error instanceof Error ? error.message : 'Unknown error',
          errorClassification: errorClass,
          metrics: {
            stageTiming: context.metrics.stageTiming,
            currentDuration: Date.now() - context.startTime,
            memoryDelta: {
              heapUsed: currentMemory.heapUsed - context.metrics.memoryUsage.heapUsed,
              heapTotal: currentMemory.heapTotal - context.metrics.memoryUsage.heapTotal
            }
          }
        });

        if (!errorClass.retryable) break;

        if (context.attempt < MAX_RETRY_ATTEMPTS) {
          await new Promise(resolve => setTimeout(resolve, RETRY_DELAY_MS * context.attempt));
        }
      }
    }

    // All attempts failed - log comprehensive metrics with error classification
    const finalMemory = measurePerformance();
    const finalErrorClass = classifyError(lastError);
    
    logger.error('Quiz', 'Failed to save quiz state after all attempts', {
      error: lastError,
      errorClassification: finalErrorClass,
      metrics: {
        attempts: context.attempt,
        lastStage: context.stage,
        documentSize: context.metrics.documentSize,
        documentSizeRatio: Math.round((estimatedSize / FIRESTORE_LIMITS.MAX_DOC_SIZE) * 100) + '%',
        totalDuration: Date.now() - context.startTime,
        stageTiming: context.metrics.stageTiming,
        memoryProfile: {
          initial: context.metrics.memoryUsage,
          final: finalMemory,
          delta: {
            heapUsed: finalMemory.heapUsed - context.metrics.memoryUsage.heapUsed,
            heapTotal: finalMemory.heapTotal - context.metrics.memoryUsage.heapTotal
          }
        }
      }
    });

    throw lastError || new QuizError('Failed to save quiz state', QuizErrorCodes.SAVE_FAILED, false);
  } catch (error) {
    // Catch any errors in the validation/serialization phase
    const errorClass = classifyError(error);
    logger.error('Quiz', 'Critical error in quiz save process', {
      error,
      errorClassification: errorClass,
      stage: context.stage,
      metrics: {
        duration: Date.now() - context.startTime,
        memoryUsage: measurePerformance()
      }
    });
    throw error;
  }
};

// Update the interface first
interface QuizFetchParams {
  testType: TestType;           // The specific test type (LCSW, LMSW, etc.)
  mode?: QuizMode;              // How the quiz is being taken
  questionIds?: string[];       // Specific questions to fetch (as strings)
  category?: string;            // Category filter
  limit?: number;               // How many questions to fetch
  lastQuestionId?: string;      // For pagination (as string)
  isSubscribed?: boolean;       // User's subscription status
}

/**
 * Fetches questions from a specific test collection with pagination and filtering
 * @param params - Parameters for fetching questions
 * @returns Promise<Question[]>
 */
export const fetchQuestions = async (params: QuizFetchParams): Promise<Question[]> => {
  const { 
    testType, 
    mode = QuizMode.PRACTICE,
    questionIds,
    category,
    limit = 30,
    lastQuestionId,
    isSubscribed = false 
  } = params;

  try {
    // Get the questions collection for this test type
    const questionsRef = collection(db, 'tests', testType, 'questions');
    
    // If we have specific questionIds, use chunked fetching
    if (questionIds?.length) {
      return fetchQuizQuestions(testType, questionIds);
    }

    // For category or mode-based fetching, first get the IDs
    const constraints: QueryConstraint[] = [];

    // Apply filters
    if (category) {
      constraints.push(where('category', '==', category));
    }

    // Apply appropriate limits based on mode
    let targetLimit = limit;
    // switch (mode) {
    //   case QuizMode.FULL:
    //     if (!isSubscribed) {
    //       throw new Error('Full quiz requires subscription');
    //     }
    //     targetLimit = 170; // Full exam length
    //     break;
    //   case QuizMode.CATEGORY:
    //     targetLimit = 50;  // Category practice length
    //     break;
    //   case QuizMode.PRACTICE:
    //   default:
    //     targetLimit = Math.min(limit, 30); // Practice length
    // }
    
    // constraints.push(firestoreLimit(targetLimit));

    // Add pagination if lastQuestionId is provided
    if (lastQuestionId) {
      constraints.push(where('id', '>', lastQuestionId));
    }

    // First fetch just the IDs
    const querySnapshot = await getDocs(query(questionsRef, ...constraints));
    
    if (querySnapshot.empty) {
      logger.warn('Quiz', 'No questions found', { testType, mode, category });
      return [];
    }

    // Extract IDs and fetch full questions in chunks
    const ids = querySnapshot.docs.map(doc => doc.id);  // Keep as strings
    return fetchQuizQuestions(testType, ids);

  } catch (error) {
    logger.error('Quiz', 'Error fetching questions', { 
      error, 
      testType, 
      mode,
      category 
    });
    throw error;
  }
};

// Quiz progress management
export const saveQuizProgress = async (
  progress: QuizProgress, 
  updateType: 'ANSWER' | 'START' | 'COMPLETE' | 'METADATA'
): Promise<void> => {
  const user = auth.currentUser;
  if (!user) throw new QuizError('No authenticated user', QuizErrorCodes.SAVE_FAILED, false);

  // Add more detailed logging for batch transitions
  const currentBatchIndex = Math.floor((progress.currentQuestionIndex ?? 0) / 10);
  const totalQuestions = progress.questionIds?.length ?? 0;
  const loadedQuestions = progress.questions?.length ?? 0;
  const isTransitioningBatch = progress.currentQuestionIndex !== undefined && 
    progress.currentQuestionIndex % 10 === 0 && 
    progress.currentQuestionIndex > 0;

  // Validate state consistency
  if (loadedQuestions === 0 && totalQuestions > 0) {
    logger.error('Quiz', 'Invalid quiz state - no loaded questions', {
      currentIndex: progress.currentQuestionIndex,
      totalQuestions,
      updateType
    });
    throw new QuizError('Invalid quiz state - no loaded questions', QuizErrorCodes.INVALID_DATA, true);
  }

  // Validate batch consistency
  if (isTransitioningBatch && loadedQuestions < Math.min(10, totalQuestions - (progress.currentQuestionIndex ?? 0))) {
    logger.error('Quiz', 'Invalid batch state - insufficient questions loaded', {
      currentIndex: progress.currentQuestionIndex,
      loadedQuestions,
      totalQuestions,
      batchIndex: currentBatchIndex
    });
    throw new QuizError('Invalid batch state', QuizErrorCodes.INVALID_DATA, true);
  }

  logger.debug('Quiz', 'Saving quiz progress with batch info', {
    updateType,
    currentIndex: progress.currentQuestionIndex,
    totalQuestions,
    loadedQuestions,
    currentBatchIndex,
    isTransitioningBatch,
    score: progress.score,
    hasWrongAnswers: (progress.wrongAnswers?.length ?? 0) > 0
  });

  // For complete quiz saves (START or COMPLETE) or after answering questions
  if (updateType === 'START' || updateType === 'COMPLETE' || updateType === 'ANSWER') {
    // Add pre-save validation for batch transitions
    if (isTransitioningBatch) {
      logger.info('Quiz', 'Batch transition detected during save', {
        currentIndex: progress.currentQuestionIndex,
        batchIndex: currentBatchIndex,
        questionCount: loadedQuestions,
        hasAllQuestions: loadedQuestions === totalQuestions,
        score: progress.score,
        wrongAnswersCount: progress.wrongAnswers?.length
      });

      // Ensure we have the next batch loaded
      const nextBatchStart = (currentBatchIndex + 1) * 10;
      const expectedNextBatchSize = Math.min(10, totalQuestions - nextBatchStart);
      const hasNextBatch = progress.questions?.slice(nextBatchStart, nextBatchStart + 10).length === expectedNextBatchSize;

      if (!hasNextBatch && nextBatchStart < totalQuestions) {
        // Instead of throwing an error, log a warning and continue
        logger.warn('Quiz', 'Next batch not fully loaded during transition', {
          currentIndex: progress.currentQuestionIndex ?? 0,
          nextBatchStart,
          expectedSize: expectedNextBatchSize,
          actualSize: progress.questions?.slice(nextBatchStart, nextBatchStart + 10).length
        });
        
        // Only throw if we don't have the immediate next question
        const nextQuestionIndex = (progress.currentQuestionIndex ?? 0) + 1;
        if (!progress.questions?.[nextQuestionIndex]) {
          throw new QuizError('Missing next question', QuizErrorCodes.INVALID_DATA, true);
        }
      }
    }
    return saveFullQuizState(progress);
  }

  // For other metadata updates
  return saveQuizDelta(progress, updateType);
};

const saveQuizDelta = async (
  progress: QuizProgress, 
  updateType: 'ANSWER' | 'METADATA'
): Promise<void> => {
  const user = auth.currentUser;
  if (!user) throw new QuizError('No authenticated user', QuizErrorCodes.SAVE_FAILED, false);

  // Log unexpected updateType but continue with default behavior
  if (!['ANSWER', 'METADATA'].includes(updateType)) {
    logger.warn('Quiz', 'Unexpected update type for quiz delta', { 
      updateType,
      fallback: 'METADATA'  // Default to metadata updates as they're less critical
    });
  }

  // Extract and validate changes
  const changes = extractRelevantChanges(progress, updateType);
  if (!changes.length) {
    logger.debug('Quiz', 'No changes detected for quiz delta', { 
      updateType,
      quizId: progress.quizId 
    });
    return; // Exit early if no changes to save
  }

  const delta: QuizDelta = {
    type: updateType, // This is now guaranteed to be either 'ANSWER' or 'METADATA'
    timestamp: Date.now(),
    quizId: String(progress.quizId) || '',
    userId: user.uid,
    changes,
    processed: false,
    quizRef: `quizzes/${user.uid}_${progress.quizId}`
  };

  // Log the delta for debugging
  logger.debug('Quiz', 'Saving quiz delta', {
    delta: {
      ...delta,
      changes: changes.length // Just log the length for brevity
    }
  });

  try {
    const deltaRef = collection(db, 'quizDeltas');
    await addDoc(deltaRef, delta);
    
    logger.info('Quiz', 'Successfully saved quiz delta', {
      updateType,
      quizId: progress.quizId,
      changeCount: changes.length
    });
  } catch (error) {
    logger.error('Quiz', 'Failed to save quiz delta', {
      error,
      delta: {
        ...delta,
        changes: changes.length
      }
    });
    throw error;
  }
};

const extractRelevantChanges = (
  progress: QuizProgress, 
  updateType: 'ANSWER' | 'METADATA'
): Array<{path: string, value: any}> => {
  const changes: Array<{path: string, value: any}> = [];

  if (updateType === 'ANSWER') {
    // Always include these fields for answer updates
    changes.push(
      {
        path: 'currentQuestionIndex',
        value: progress.currentQuestionIndex ?? 0
      },
      {
        path: 'score',
        value: progress.score ?? 0
      },
      {
        path: 'lastUpdated',
        value: new Date().toISOString()
      }
    );

    // Add selected answer if available
    const currentQuestion = progress.questions?.[progress.currentQuestionIndex ?? 0];
    if (currentQuestion?.selected_answer) {
      changes.push({
        path: `questions.${progress.currentQuestionIndex}.selected_answer`,
        value: currentQuestion.selected_answer
      });
    }
  } else if (updateType === 'METADATA') {
    // Handle metadata updates
    if (progress.category) {
      changes.push({
        path: 'category',
        value: progress.category
      });
    }
    if (progress.testType) {
      changes.push({
        path: 'testType',
        value: progress.testType
      });
    }
    // Add other metadata fields as needed
  }

  return changes;
};

// Helper functions
const buildQueryConstraints = ({
  testType,
  mode = QuizMode.PRACTICE,
  category,
  questionIds,
  isSubscribed,
  limit: requestedLimit
}: QuizFetchParams): QueryConstraint[] => {
  const constraints: QueryConstraint[] = [];

  if (questionIds?.length) {
    constraints.push(where('id', 'in', questionIds));
    return constraints;
  }

  if (category) {
    constraints.push(where('category', '==', category));
  }

  // Apply appropriate limits based on mode
  switch (mode) {
    case QuizMode.FULL:
      if (!isSubscribed) throw new Error('Full quiz requires subscription');
      constraints.push(firestoreLimit(170));
      break;
    case QuizMode.CATEGORY:
      constraints.push(firestoreLimit(50));
      break;
    default:
      constraints.push(firestoreLimit(Math.min(requestedLimit ?? 30, 30)));
  }
  
  return constraints;
};

/**
 * Fetches a specific quiz's questions from the appropriate test collection
 * @param testType - The type of test (e.g., 'lcsw', 'lmsw')
 * @param questionIds - Array of question IDs to fetch
 * @returns Array of Questions
 */
async function fetchQuizQuestions(testType: TestType, questionIds: string[]): Promise<Question[]> {
  try {
    if (!testType) {
      logger.error('Quiz', 'Test type is undefined', {
        testType,
        questionIds: questionIds.slice(0, 3)
      });
      throw new Error('Test type is required for fetching questions');
    }

    // Deduplicate question IDs
    const uniqueIds = Array.from(new Set(questionIds));
    if (uniqueIds.length !== questionIds.length) {
      logger.warn('Quiz', 'Removed duplicate question IDs', {
        originalCount: questionIds.length,
        uniqueCount: uniqueIds.length,
        duplicatesRemoved: questionIds.length - uniqueIds.length
      });
    }

    logger.debug('Quiz', 'Starting question fetch with test type', {
      testType,
      testTypeType: typeof testType,
      questionCount: uniqueIds.length,
      sampleIds: uniqueIds.slice(0, 3)
    });

    const questionsRef = collection(db, 'tests', testType, 'questions');
    const questions: Question[] = [];
    const chunkSize = 10;
    
    logger.debug('Quiz', 'Starting chunked question fetch', {
      testType,
      totalQuestions: uniqueIds.length,
      chunkSize,
      sampleIds: uniqueIds.slice(0, 3)
    });
    
    for (let i = 0; i < uniqueIds.length; i += chunkSize) {
      const chunk = uniqueIds.slice(i, i + chunkSize);
      logger.debug('Quiz', 'Fetching question chunk', {
        chunkIndex: i / chunkSize,
        chunkSize: chunk.length,
        questionIds: chunk
      });

      // Create an array of document references for this chunk
      const docRefs = chunk.map(id => doc(questionsRef, id));
      
      // Fetch all documents in parallel
      const docs = await Promise.all(docRefs.map(ref => getDoc(ref)));
      
      logger.debug('Quiz', 'Raw Firestore response', {
        docsFound: docs.filter(doc => doc.exists()).length,
        docIds: docs.map(doc => doc.id),
        docData: docs.map(doc => ({
          id: doc.id,
          exists: doc.exists(),
          dataId: doc.exists() ? doc.data()?.id : null
        }))
      });

      // Convert documents to questions
      const chunkQuestions = docs
        .filter(doc => doc.exists())
        .map(doc => ({
          ...doc.data() as Omit<Question, 'id'>,
          id: doc.id
        }));

      questions.push(...chunkQuestions);
      
      logger.debug('Quiz', 'Chunk fetch complete', {
        fetchedCount: chunkQuestions.length,
        totalFetched: questions.length,
        progress: `${questions.length}/${uniqueIds.length}`,
        sampleQuestions: chunkQuestions.slice(0, 2).map(q => ({
          id: q.id,
          category: q.category
        }))
      });
    }
    
    logger.info('Quiz', 'Question fetch complete', {
      testType,
      requestedCount: questionIds.length,
      uniqueCount: uniqueIds.length,
      fetchedCount: questions.length,
      matchedIds: questions.map(q => q.id).filter(id => uniqueIds.includes(id)).length
    });
    
    return questions;
  } catch (error) {
    logger.error('Quiz', 'Error fetching quiz questions', { 
      error, 
      testType, 
      questionIds
    });
    throw error;
  }
}

/**
 * Gets an active quiz by ID, optimizing question fetching
 * @param quizId - The ID of the quiz to fetch
 * @returns QuizProgress or null if not found
 */
export const getActiveQuiz = async (
  quizId: string
): Promise<QuizProgress | null> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  try {
    logger.debug('Quiz', 'Starting to fetch active quiz', {
      quizId,
      userId: user.uid
    });

    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    const data = userDoc.data() as QuizData | undefined;

    let quiz: QuizProgress | null = null;

    if (data?.quizzes?.[quizId]) {
      const fullQuizRef = doc(db, 'quizzes', `${user.uid}_${quizId}`);
      const fullQuizDoc = await getDoc(fullQuizRef);
      
      if (fullQuizDoc.exists()) {
        const quizData = fullQuizDoc.data() as StoredQuizData;
        const quizRef = data.quizzes[quizId];

        logger.debug('Quiz', 'Found quiz data', {
          quizId,
          currentIndex: quizData.currentQuestionIndex,
          questionCount: quizData.questionIds?.length,
          completed: quizData.completed
        });

        // Calculate which chunk of questions we need based on currentQuestionIndex
        const chunkSize = 10;
        const currentChunkIndex = Math.floor((quizData.currentQuestionIndex || 0) / chunkSize);
        const startIndex = currentChunkIndex * chunkSize;
        const questionIds = quizData.questionIds || [];

        // Only fetch the current chunk of questions
        let questions: Question[] = [];
        if (!quizData.completed && questionIds.length > 0) {
          const chunkIds = questionIds.slice(startIndex, startIndex + chunkSize);
          
          logger.debug('Quiz', 'Fetching current chunk of questions', {
            quizId,
            currentIndex: quizData.currentQuestionIndex,
            chunkIndex: currentChunkIndex,
            chunkSize,
            chunkIds,
            testType: quizData.testType || TestTypeEnum.LCSW_FREE
          });

          questions = await fetchQuizQuestions(
            quizData.testType || TestTypeEnum.LCSW_FREE,
            chunkIds
          );

          logger.debug('Quiz', 'Current chunk fetched', {
            fetchedCount: questions.length,
            expectedCount: chunkIds.length,
            testType: quizData.testType || TestTypeEnum.LCSW_FREE,
            firstQuestionId: questions[0]?.id
          });

          // Validate that we got the questions we need
          if (questions.length === 0 && chunkIds.length > 0) {
            logger.error('Quiz', 'Failed to fetch questions for active quiz', {
              quizId,
              chunkIds,
              testType: quizData.testType
            });
            return null;
          }
        }

        // Ensure the current question index is valid
        const validIndex = Math.min(
          quizData.currentQuestionIndex || 0,
          questions.length - 1
        );

        quiz = {
          quizId: String(quizId),
          currentQuestionIndex: validIndex,
          score: quizData.score || 0,
          questions,  // Only contains current chunk
          questionIds, // Contains all IDs
          wrongAnswers: quizData.wrongAnswers || [],
          date: quizData.date || new Date().toISOString(),
          category: quizData.category || 'unknown',
          type: quizRef.type,
          testType: quizData.testType || TestTypeEnum.LCSW_FREE,
          mode: quizData.mode || QuizModeEnum.PRACTICE,
          adaptiveState: quizData.adaptiveState || null,
          lastUpdated: new Date().toISOString(),
          completed: quizData.completed || false
        };

        logger.debug('Quiz', 'Successfully prepared quiz progress', {
          quizId,
          hasQuestions: questions.length > 0,
          questionCount: questions.length,
          currentIndex: validIndex,
          totalQuestions: questionIds.length
        });
      } else {
        logger.warn('Quiz', 'Quiz document not found', {
          quizId,
          ref: fullQuizRef.path
        });
      }
    } else {
      logger.warn('Quiz', 'Quiz not found in user data', {
        quizId,
        userId: user.uid
      });
    }

    return quiz;
  } catch (error) {
    logger.error('Quiz', 'Error getting active quiz', {
      error,
      quizId,
      userId: user.uid
    });
    throw error;
  }
};

const convertLegacyQuestion = (q: Partial<Question> & { id: string | number }): Question => ({
  id: String(q.id || '0'),
  category: q.category || 'unknown',
  question: q.question || '',
  option_a: q.option_a || '',
  option_b: q.option_b || '',
  option_c: q.option_c || '',
  option_d: q.option_d || '',
  option_e: q.option_e || null,
  correct_answer: q.correct_answer || '',
  explanation: q.explanation || '',
  hint: q.hint || null,
  selected_answer: q.selected_answer || null,
  difficulty_level: q.difficulty_level || null
});

const convertToQuizProgress = (data: any): QuizProgress => {
  const questions = Array.isArray(data.questions) 
    ? data.questions.map((q: any) => ({
        id: String(q.id || '0'),
        category: String(q.category || 'unknown'),
        question: String(q.question || ''),
        option_a: String(q.option_a || ''),
        option_b: String(q.option_b || ''),
        option_c: String(q.option_c || ''),
        option_d: String(q.option_d || ''),
        option_e: q.option_e ? String(q.option_e) : null,
        correct_answer: String(q.correct_answer || ''),
        explanation: String(q.explanation || ''),
        hint: q.hint ? String(q.hint) : null,
        selected_answer: q.selected_answer ? String(q.selected_answer) : null,
        difficulty_level: q.difficulty_level ? String(q.difficulty_level) : null
      }))
    : [];

  // Properly format wrong answers during conversion
  const wrongAnswers = Array.isArray(data.wrongAnswers) 
    ? data.wrongAnswers
        .filter((wa: any) => wa && typeof wa === 'object')
        .map((wa: any) => ({
          question: {
            id: String(wa.question?.id || ''),
            category: String(wa.question?.category || ''),
            question: String(wa.question?.question || ''),
            correct_answer: String(wa.question?.correct_answer || ''),
            selected_answer: String(wa.question?.selected_answer || '')
          },
          explanation: String(wa.explanation || ''),
          date: wa.date ? String(wa.date) : new Date().toISOString(),
          isReviewed: Boolean(wa.isReviewed),
          attempts: Number(wa.attempts || 1)
        }))
    : [];

  logger.debug('Quiz', 'Converting wrong answers', {
    originalCount: Array.isArray(data.wrongAnswers) ? data.wrongAnswers.length : 0,
    convertedCount: wrongAnswers.length,
    sample: wrongAnswers[0] ? JSON.stringify(wrongAnswers[0]) : undefined
  });

  return {
    quizId: String(data.quizId || '0'),
    currentQuestionIndex: Number(data.currentQuestionIndex || 0),
    score: Number(data.score || 0),
    questions,
    questionIds: data.questionIds || questions.map((q: Question) => q.id),
    wrongAnswers,
    date: typeof data.date === 'string' ? data.date : new Date().toISOString(),
    category: typeof data.category === 'string' ? data.category : 'unknown',
    type: data.type || QuizType.SAMPLE,
    testType: data.testType || TestTypeEnum.LCSW_FREE,
    mode: data.mode || QuizModeEnum.PRACTICE,
    adaptiveState: data.adaptiveState || null,
    lastUpdated: new Date().toISOString()
  };
};

const convertLegacyQuizToStored = (legacyQuiz: LegacyQuizData): StoredQuizData => {
  const questions = legacyQuiz.questions?.map(convertLegacyQuestion) || [];
  const questionIds = questions.map(q => q.id);

  return {
    quizId: String(legacyQuiz.quizId || '0'),
    type: determineQuizType(legacyQuiz as Partial<QuizProgress>),
    questions,
    currentQuestionIndex: legacyQuiz.currentQuestionIndex || 0,
    score: legacyQuiz.score || 0,
    date: legacyQuiz.date || new Date().toISOString(),
    wrongAnswers: legacyQuiz.wrongAnswers || [],
    questionIds: questionIds.map(String),
    category: legacyQuiz.category || 'unknown',
    lastUpdated: new Date().toISOString(),
    testType: TestTypeEnum.LCSW_FREE,
    mode: QuizModeEnum.PRACTICE,
    completed: false
  };
};

/**
 * Gets all active quizzes for a user, with optimized question fetching
 * @param userId - The user's ID
 * @returns Array of QuizProgress objects
 */
export const getAllActiveQuizzes = async (
  userId: string
): Promise<QuizProgress[]> => {
  if (!userId) throw new Error('No user ID provided');

  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    const data = userDoc.data() as QuizData | undefined;

    logger.debug('Quiz', 'Fetching active quizzes - Initial user data', {
      userId,
      hasData: !!data,
      hasQuizzes: !!data?.quizzes,
      quizCount: data?.quizzes ? Object.keys(data.quizzes).length : 0
    });

    let quizzes = data?.quizzes;
    if (!quizzes) {
      logger.info('Quiz', 'No active quizzes found');
      return [];
    }

    const quizPromises = Object.entries(quizzes).map(async ([quizId, quizRef]) => {
      try {
        const fullQuizRef = doc(db, 'quizzes', `${userId}_${quizId}`);
        const fullQuizDoc = await getDoc(fullQuizRef);
        
        if (!fullQuizDoc.exists()) {
          logger.warn('Quiz', 'Quiz document not found', {
            quizId,
            ref: fullQuizRef.path
          });
          return null;
        }

        const quizData = fullQuizDoc.data() as StoredQuizData;
        
        // Calculate current chunk for this quiz
        const chunkSize = 10;
        const currentChunkIndex = Math.floor((quizData.currentQuestionIndex || 0) / chunkSize);
        const startIndex = currentChunkIndex * chunkSize;
        const questionIds = quizData.questionIds || [];

        // Only fetch questions for incomplete quizzes and only the current chunk
        let questions: Question[] = [];
        if (!quizData.completed && questionIds.length > 0) {
          const chunkIds = questionIds.slice(startIndex, startIndex + chunkSize);
          
          logger.debug('Quiz', 'Fetching chunk for quiz', {
            quizId,
            currentIndex: quizData.currentQuestionIndex,
            chunkIndex: currentChunkIndex,
            chunkSize,
            chunkIds
          });

          questions = await fetchQuizQuestions(
            quizData.testType || TestTypeEnum.LCSW_FREE,
            chunkIds
          );
        }

        return {
          quizId: String(quizId),
          currentQuestionIndex: quizData.currentQuestionIndex || 0,
          initialQuestionIndex: quizData.initialQuestionIndex || 0,
          score: quizData.score || 0,
          date: quizData.date || new Date().toISOString(),
          questions, // Only contains current chunk
          questionIds, // Contains all IDs
          type: quizRef.type,
          category: quizData.category || '',
          lastUpdated: quizRef.lastUpdated,
          completed: quizData.completed || false,
          adaptiveState: quizData.adaptiveState || null,
          wrongAnswers: quizData.wrongAnswers || [],
          testType: quizData.testType || TestTypeEnum.LCSW_FREE,
          mode: quizData.mode || QuizModeEnum.PRACTICE
        } as QuizProgress;
      } catch (error) {
        logger.error('Quiz', 'Error fetching quiz data', {
          quizId,
          error
        });
        return null;
      }
    });

    const fetchedQuizzes = (await Promise.all(quizPromises))
      .filter((quiz): quiz is QuizProgress => quiz !== null);

    logger.debug('Quiz', 'Fetched all quiz data', {
      totalFetched: fetchedQuizzes.length,
      quizIds: fetchedQuizzes.map(q => q.quizId)
    });

    // 6. Filter and return only active (non-completed) quizzes
    const activeQuizzes = fetchedQuizzes.filter(quiz => {
      // A quiz is considered completed if:
      // 1. It's explicitly marked as completed
      // 2. All questions have been answered
      // 3. The current index is at or beyond the total questions
      const isCompleted = quiz.completed || 
        (quiz.currentQuestionIndex !== undefined && 
         quiz.questionIds !== undefined &&
         quiz.currentQuestionIndex >= quiz.questionIds.length);

      logger.debug('Quiz', 'Checking quiz completion status', {
        quizId: quiz.quizId,
        completed: quiz.completed,
        currentIndex: quiz.currentQuestionIndex,
        totalQuestions: quiz.questionIds?.length,
        isCompleted
      });

      return !isCompleted;
    });

    logger.info('Quiz', 'Processed active quizzes', {
      totalQuizzes: fetchedQuizzes.length,
      activeQuizzes: activeQuizzes.length,
      activeQuizIds: activeQuizzes.map(q => q.quizId)
    });

    return activeQuizzes;
  } catch (error) {
    logger.error('Quiz', 'Error getting active quizzes', {
      error,
      userId
    });
    throw error;
  }
};

/**
 * Deletes a quiz and its associated data
 * @param quizId - The ID of the quiz to delete
 * @returns Promise<void>
 */
export const deleteQuiz = async (quizId: string): Promise<void> => {
  const user = auth.currentUser;
  if (!user) throw new QuizError('No authenticated user', QuizErrorCodes.DELETE_FAILED, false);

  try {
    logger.debug('Quiz', 'Starting quiz deletion', {
      quizId,
      userId: user.uid
    });

    await runTransaction(db, async (transaction) => {
      // Get user document reference
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await transaction.get(userDocRef);
      
      if (!userDoc.exists()) {
        throw new QuizError('User document not found', QuizErrorCodes.USER_NOT_FOUND, false);
      }

      const data = userDoc.data() as QuizData;
      const quizzes = data.quizzes || {};

      // Check if quiz exists
      if (!quizzes[quizId]) {
        throw new QuizError('Quiz not found', QuizErrorCodes.QUIZ_NOT_FOUND, false);
      }

      // Delete quiz document
      const quizRef = doc(db, 'quizzes', `${user.uid}_${quizId}`);
      transaction.delete(quizRef);

      // Remove quiz reference from user document
      const updatedQuizzes = { ...quizzes };
      delete updatedQuizzes[quizId];
      
      transaction.update(userDocRef, {
        quizzes: updatedQuizzes
      });

      // Delete any pending deltas for this quiz
      const deltasRef = collection(db, 'quizDeltas');
      const deltaQuery = query(
        deltasRef,
        where('userId', '==', user.uid),
        where('quizId', '==', quizId)
      );
      const deltaSnapshot = await getDocs(deltaQuery);
      
      deltaSnapshot.forEach(deltaDoc => {
        transaction.delete(deltaDoc.ref);
      });
    });

    logger.info('Quiz', 'Successfully deleted quiz', {
      quizId,
      userId: user.uid
    });
  } catch (error) {
    logger.error('Quiz', 'Failed to delete quiz', {
      error,
      quizId,
      userId: user.uid
    });
    throw error;
  }
};

/**
 * Fetches a question by its ID from the test-specific questions collection
 * @param testType - The type of test (e.g., 'lcsw', 'lmsw')
 * @param questionId - The ID of the question to fetch
 */
export const getQuestionById = async (
  testType: TestType,
  questionId: string
): Promise<Question | null> => {
  try {
    logger.info('Quiz', `Fetching question ${questionId} from ${testType}`);
    
    // Get the questions collection for this test type
    const questionsRef = collection(db, 'tests', testType, 'questions');
    
    // Get the specific question document
    const questionDoc = await getDoc(doc(questionsRef, questionId));

    if (!questionDoc.exists()) {
      logger.warn('Quiz', `Question ${questionId} not found in ${testType}`);
      return null;
    }

    const questionData = questionDoc.data() as Question;
    return {
      ...questionData,
      id: questionId // Ensure ID is set correctly
    };
  } catch (error) {
    logger.error('Quiz', `Error fetching question ${questionId} from ${testType}`, error);
    throw error;
  }
};