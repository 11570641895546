export enum QuizType {
    SAMPLE = 'sample',       // @deprecated use QuizMode.PRACTICE
    FULL = 'full',          // @deprecated use QuizMode.FULL
    CATEGORY = 'category'    // @deprecated use QuizMode.CATEGORY
}

export enum TestType {
    LCSW = 'lcsw',
    LCSW_FREE = 'lcsw_free',
    LBSW = 'lbsw',
    LMSW = 'lmsw'
}

export enum QuizMode {
    PRACTICE = 'practice',    // Short practice sessions
    FULL = 'full',           // Full-length simulated exam
    CATEGORY = 'category'    // Category-focused practice
} 