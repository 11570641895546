import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Shield, CheckCircle, XCircle } from 'lucide-react';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from '../utils/Analytics';
import { logger } from '../utils/logger';

// Sample questions from our quiz data
const PREVIEW_QUESTIONS = [
  {
    question: "A social worker is working with a client who has been struggling with alcohol use for several years. The client acknowledges the impact of their drinking on their life but expresses a desire to reduce consumption rather than stop entirely. The social worker believes that abstinence might be the safest option for the client. How should the social worker proceed while respecting the client's right to self-determination?",
    options: [
      "Continue to encourage the client toward abstinence, emphasizing the risks of reducing rather than quitting.",
      "Develop a harm reduction plan with the client to support their goal of reducing alcohol consumption.",
      "Refer the client to a treatment program that requires complete abstinence.",
      "Refuse to work with the client unless they agree to an abstinence-based treatment plan."
    ],
    correctAnswer: 1,
    explanation: "The social worker should respect the client's autonomy and right to self-determination by working with their stated goal of reduction rather than imposing abstinence. A harm reduction approach allows for collaboration while still addressing the risks."
  },
  {
    question: "In the initial phase of building a therapeutic relationship with a client, which of the following is the MOST important factor in fostering trust and rapport?",
    options: [
      "The social worker's ability to provide solutions to the client's problems quickly.",
      "The social worker's use of clinical techniques to analyze the client's behavior.",
      "The social worker's genuine empathy, active listening, and unconditional positive regard for the client.",
      "The social worker's adherence to strict professional boundaries, minimizing personal connection."
    ],
    correctAnswer: 2,
    explanation: "Genuine empathy, active listening, and unconditional positive regard are fundamental to building trust and rapport in the therapeutic relationship. These qualities create a safe and supportive environment for the client."
  },
  {
    question: "A social worker is conducting an initial assessment with a client who has been referred for issues related to substance abuse. The client appears anxious and hesitant to discuss their substance use. Which approach should the social worker use to effectively obtain sensitive information while ensuring the client's comfort and trust?",
    options: [
      "Directly ask the client about the specifics of their substance use during the first few minutes of the session to get the necessary information quickly.",
      "Use confrontational questioning to challenge the client's reluctance and encourage immediate disclosure.",
      "Create a safe and non-judgmental environment by expressing empathy, normalizing the client's experiences, and gradually introducing questions about substance use.",
      "Avoid discussing substance use during the initial session to prevent discomfort and revisit the topic in future meetings."
    ],
    correctAnswer: 2,
    explanation: "Creating a safe and non-judgmental environment while gradually introducing sensitive topics helps build trust and makes clients more comfortable sharing difficult information. This approach balances the need for assessment with the client's emotional comfort."
  }
];

const PreviewQuiz: React.FC = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<number[]>([]);
  const [showSignupPrompt, setShowSignupPrompt] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const questionContainerRef = useRef<HTMLDivElement>(null);
  const startTime = useRef(Date.now());
  const questionStartTime = useRef(Date.now());

  useEffect(() => {
    // Track preview quiz start
    logAnalyticsEvent(ANALYTICS_EVENTS.QUIZ_STARTED, {
      quiz_id: 0, // Preview quiz ID
      quiz_type: 'preview',
      category: 'preview'
    });

    return () => {
      // Track quiz exit if not completed
      if (!showSignupPrompt) {
        logAnalyticsEvent(ANALYTICS_EVENTS.QUIZ_STATE_CHANGED, {
          state: 'abandoned',
          questionsAttempted: selectedAnswers.filter(a => a !== undefined).length,
          timeSpent: Date.now() - startTime.current
        });
      }
    };
  }, [showSignupPrompt]);

  const scrollToTop = () => {
    if (questionContainerRef.current) {
      questionContainerRef.current.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleAnswerSelect = (answerIndex: number) => {
    const timeSpent = Date.now() - questionStartTime.current;
    const newAnswers = [...selectedAnswers];
    newAnswers[currentQuestionIndex] = answerIndex;
    setSelectedAnswers(newAnswers);
    
    const isAnswerCorrect = answerIndex === PREVIEW_QUESTIONS[currentQuestionIndex].correctAnswer;
    setIsCorrect(isAnswerCorrect);
    setShowFeedback(true);

    // Track answer submission
    logAnalyticsEvent(ANALYTICS_EVENTS.QUESTION_ANSWERED, {
      questionId: currentQuestionIndex,
      isCorrect: isAnswerCorrect,
      timeSpent,
      attemptCount: 1,
      hintUsed: false
    });

    setTimeout(() => {
      setShowFeedback(false);
      if (currentQuestionIndex < PREVIEW_QUESTIONS.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        questionStartTime.current = Date.now();
        scrollToTop();
      } else {
        setShowSignupPrompt(true);
        // Track preview quiz completion
        logAnalyticsEvent(ANALYTICS_EVENTS.QUIZ_COMPLETED, {
          quiz_id: 0,
          score: selectedAnswers.filter((answer, idx) => 
            answer === PREVIEW_QUESTIONS[idx].correctAnswer
          ).length,
          totalQuestions: PREVIEW_QUESTIONS.length,
          percentage: (selectedAnswers.filter((answer, idx) => 
            answer === PREVIEW_QUESTIONS[idx].correctAnswer
          ).length / PREVIEW_QUESTIONS.length) * 100,
          timeSpent: Date.now() - startTime.current,
          questionIds: PREVIEW_QUESTIONS.map((_, idx) => idx)
        });
      }
    }, 2000);
  };

  const handleSignup = (e: React.FormEvent) => {
    e.preventDefault();
    logger.info('PreviewQuiz', 'Starting signup process', { email });
    
    logAnalyticsEvent(ANALYTICS_EVENTS.AUTH_STARTED, {
      method: 'email',
      source: 'preview_quiz',
      email_provided: !!email
    });

    navigate('/signin', {
      state: {
        email,
        mode: 'signup'
      }
    });
  };

  const currentQuestion = PREVIEW_QUESTIONS[currentQuestionIndex];

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto relative">
        <div 
          ref={questionContainerRef}
          className={`bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 ${showSignupPrompt ? 'blur-sm' : ''}`}
        >
          <div className="mb-8">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                ASWB Practice Question
              </h2>
            </div>
          </div>

          <div className="space-y-6">
            <p className="text-lg text-gray-900 dark:text-white">
              {currentQuestion.question}
            </p>

            <div className="space-y-3">
              {currentQuestion.options.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleAnswerSelect(index)}
                  disabled={showFeedback}
                  className={`w-full text-left p-4 rounded-lg border ${
                    selectedAnswers[currentQuestionIndex] === index
                      ? isCorrect
                        ? 'border-green-500 bg-green-50 dark:bg-green-900/20'
                        : 'border-red-500 bg-red-50 dark:bg-red-900/20'
                      : 'border-gray-200 dark:border-gray-600 hover:border-blue-300 dark:hover:border-blue-400'
                  } transition-colors`}
                >
                  <span className="text-gray-900 dark:text-white">{option}</span>
                </button>
              ))}
            </div>

            {showFeedback && (
              <div className={`p-4 rounded-lg ${
                isCorrect 
                  ? 'bg-green-50 dark:bg-green-900/20 border border-green-200' 
                  : 'bg-red-50 dark:bg-red-900/20 border border-red-200'
              }`}>
                <div className="flex items-start">
                  {isCorrect ? (
                    <CheckCircle className="w-6 h-6 text-green-500 mr-2 flex-shrink-0 mt-1" />
                  ) : (
                    <XCircle className="w-6 h-6 text-red-500 mr-2 flex-shrink-0 mt-1" />
                  )}
                  <div>
                    <p className={`font-semibold ${
                      isCorrect ? 'text-green-800 dark:text-green-200' : 'text-red-800 dark:text-red-200'
                    }`}>
                      {isCorrect ? 'Correct!' : 'Incorrect'}
                    </p>
                    <p className="text-gray-600 dark:text-gray-300 mt-1">
                      {currentQuestion.explanation}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {showSignupPrompt && (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl p-8 max-w-2xl w-full mx-4">
              <div className="text-center mb-8">
                <Shield className="mx-auto h-12 w-12 text-blue-500" />
                <h2 className="mt-6 text-3xl font-bold text-gray-900 dark:text-white">
                  Ready to continue your prep?
                </h2>
                <p className="mt-2 text-lg text-gray-600 dark:text-gray-300">
                  Sign up now to access the full practice test and see your results
                </p>
              </div>

              <div className="space-y-4 mb-8">
                <div className="flex items-center">
                  <Shield className="h-5 w-5 text-green-500 mr-3" />
                  <span className="text-gray-700 dark:text-gray-300">Access to 30 expert-curated questions</span>
                </div>
                <div className="flex items-center">
                  <Shield className="h-5 w-5 text-green-500 mr-3" />
                  <span className="text-gray-700 dark:text-gray-300">Detailed explanations for each answer</span>
                </div>
                <div className="flex items-center">
                  <Shield className="h-5 w-5 text-green-500 mr-3" />
                  <span className="text-gray-700 dark:text-gray-300">Track your progress and identify areas for improvement</span>
                </div>
              </div>

              <form onSubmit={handleSignup} className="space-y-4">
                <div>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                    className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full bg-blue-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Continue Free Quiz
                </button>
              </form>

              <p className="mt-4 text-sm text-gray-500 dark:text-gray-400 text-center">
                No credit card required
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewQuiz; 