import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { QuizContainer } from './components/QuizContainer';
import { useAuth } from './contexts/AuthContext';
import { QuizProvider } from './components/quiz/state/QuizContext';
import { useQuiz } from './hooks/useQuiz';
import QuizSummary from './components/QuizSummary';
import { logger } from './utils/logger';
import { QuizProgress } from './utils/quiz/types';

const LoadingSpinner = () => (
  <div className="flex flex-col items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900 mb-4"></div>
    <p className="text-lg text-gray-600">Loading your test...</p>
  </div>
);

interface QuizStatusViewProps {
  quizProgress: QuizProgress | null;
  isLoading: boolean;
  error: Error | string | null;
}

const getPublishableKey = () => {
  return process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV;
};

const QuizStatusView: React.FC<QuizStatusViewProps> = ({ quizProgress, isLoading, error }) => {
  const navigate = useNavigate();
  
  if (isLoading) return <LoadingSpinner />;
  
  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen p-4">
        <div className="text-center">
          <h2 className="text-xl text-red-600 mb-4">
            {error instanceof Error ? error.message : error}
          </h2>
          <p className="text-gray-600 mb-4">Unable to load quiz status.</p>
          <button 
            onClick={() => window.close()}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Close Window
          </button>
        </div>
      </div>
    );
  }

  if (!quizProgress) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen p-4">
        <div className="text-center">
          <h2 className="text-xl text-gray-800 mb-4">Quiz Not Found</h2>
          <p className="text-gray-600 mb-4">This quiz may have been completed or removed.</p>
          <button 
            onClick={() => window.close()}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Close Window
          </button>
        </div>
      </div>
    );
  }

  const totalQuestions = quizProgress.questionIds?.length || 0;
  const currentQuestion = (quizProgress.currentQuestionIndex ?? 0) + 1;
  const progressPercent = (currentQuestion / totalQuestions) * 100;

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <div className="max-w-2xl w-full bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold mb-4">Quiz Progress</h2>
        
        <div className="mb-6">
          <div className="flex justify-between text-sm text-gray-600 mb-1">
            <span>Progress</span>
            <span>{Math.round(progressPercent)}%</span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div 
              className="bg-blue-600 h-2.5 rounded-full transition-all duration-300" 
              style={{ width: `${progressPercent}%` }}
            ></div>
          </div>
        </div>

        <div className="space-y-4">
          <div className="flex justify-between py-2 border-b">
            <span className="text-gray-600">Questions Completed</span>
            <span className="font-semibold">{currentQuestion} of {totalQuestions}</span>
          </div>
          <div className="flex justify-between py-2 border-b">
            <span className="text-gray-600">Current Score</span>
            <span className="font-semibold">{quizProgress.score || 0}%</span>
          </div>
          <div className="flex justify-between py-2 border-b">
            <span className="text-gray-600">Wrong Answers</span>
            <span className="font-semibold">{quizProgress.wrongAnswers?.length || 0}</span>
          </div>
          <div className="flex justify-between py-2">
            <span className="text-gray-600">Last Updated</span>
            <span className="font-semibold">
              {new Date(quizProgress.lastUpdated).toLocaleString()}
            </span>
          </div>
        </div>

        <div className="mt-8 flex justify-center space-x-4">
          <button 
            onClick={() => window.close()}
            className="bg-gray-500 text-white px-6 py-2 rounded hover:bg-gray-600"
          >
            Close
          </button>
          <button 
            onClick={() => window.location.reload()}
            className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
          >
            Refresh
          </button>
        </div>
      </div>
    </div>
  );
};

const QuizApp: React.FC = () => {
  const { quizId: paramQuizId } = useParams();
  const [searchParams] = useSearchParams();
  const isStatusView = searchParams.get('view') === 'status';
  const { userData } = useAuth();
  const { 
    quizProgress, 
    adaptiveEngine, 
    isLoading, 
    error,
    loadExistingQuiz 
  } = useQuiz(paramQuizId);
  const loadedRef = useRef(false);
  const navigate = useNavigate();

  // Only load quiz once
  useEffect(() => {
    if (paramQuizId && !loadedRef.current) {
      loadedRef.current = true;
      loadExistingQuiz();
    }
  }, [paramQuizId, loadExistingQuiz]);

  // Handle status view
  if (isStatusView) {
    return (
      <QuizStatusView 
        quizProgress={quizProgress}
        isLoading={isLoading}
        error={error}
      />
    );
  }

  // Show loading state
  if (isLoading) {
    return <LoadingSpinner />;
  }

  // Show error state
  if (error || !quizProgress) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="text-center">
          <h2 className="text-xl text-red-600 mb-4">
            {error || 'Unable to load quiz'}
          </h2>
          <p className="text-gray-600 mb-4">
            This might take a moment. Please try again.
          </p>
          <button 
            onClick={() => window.location.reload()}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  // Only render provider when we have everything ready
  return (
    <QuizProvider>
      <div className="new-home-container">
        <QuizContainer 
          quizId={paramQuizId ?? ''}
          initialQuizProgress={quizProgress}
        />
      </div>
    </QuizProvider>
  );
};

export default QuizApp;