import { 
  AdaptiveState, 
  AdaptiveInsights, 
  CategoryPerformance,
  QuestionAttempt,
  CategoryMetrics,
  AdaptiveMetrics,
  EnhancedCategoryPerformance,
  ImprovementStatus,
  StudyPlan,
  CategoryPlan,
  Mastery,
  RecentPerformance,
  DifficultyProgress,
  // PreferredTime,
  Milestone,
  WeakCategory,
  PreferredTime
} from '../models/AdaptiveState';

import { Question } from '../utils/quiz/types';
import { LearningStyle, QuizProgress, TestResult, UserProfile, WrongAnswer } from '../models/UserTypes';
import { logger } from './logger';
import { getQuestionById } from './firebase/quiz';
import { TestType } from './quiz/enums';


/**
 * Scoring weights for question priority calculation
 * These can be tuned based on empirical performance data
 */
const PRIORITY_WEIGHTS = {
  RECENCY: {
    LAST_24H: 0.2,    // Reduce priority if seen very recently
    LAST_WEEK: 0.6,   // Optimal review time for most questions
    LAST_MONTH: 1.0,  // High priority for spaced repetition
    OLDER: 0.8        // Slightly lower priority for very old questions
  },
  PERFORMANCE: {
    WRONG_MULTIPLE_TIMES: 1.0,   // Highest priority for repeatedly missed
    WRONG_ONCE: 0.8,            // High priority for questions missed once
    CORRECT_WITH_DIFFICULTY: 0.6,// Medium priority for struggled questions
    CORRECT_QUICKLY: 0.3        // Lower priority for well-understood questions
  }
};

/**
 * Helper class for managing time-based calculations and intervals
 */
export class TimeUtils {
  static getDaysBetween(date1: Date, date2: Date): number {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    return Math.round(Math.abs((date1.getTime() - date2.getTime()) / oneDay));
  }

  static getExpectedTime(difficulty: number): number {
    // Base time in seconds, adjusted by difficulty
    const baseTime = 60; // 1 minute base time
    return baseTime * (1 + (difficulty - 1) * 0.2); // 20% increase per difficulty level
  }

  // Add new method for calculating average response time
  static calculateAverageResponseTime(quizProgress: QuizProgress): number {
    if (!quizProgress.questionTimes || Object.keys(quizProgress.questionTimes).length === 0) {
      return 0;
    }

    const times = Object.values(quizProgress.questionTimes);
    return times.reduce((sum, time) => sum + time, 0) / times.length;
  }
}

/**
 * Helper class for analyzing question attempts and patterns
 */
class AttemptAnalyzer {
  static getConsecutiveCorrect(attempts: QuestionAttempt[]): number {
    let count = 0;
    for (let i = attempts.length - 1; i >= 0; i--) {
      if (attempts[i].isCorrect) count++;
      else break;
    }
    return count;
  }

  static getSuccessRate(attempts: QuestionAttempt[]): number {
    if (attempts.length === 0) return 0;
    const correct = attempts.filter(a => a.isCorrect).length;
    return correct / attempts.length;
  }

  static getAverageTime(attempts: QuestionAttempt[]): number {
    if (attempts.length === 0) return 0;
    const totalTime = attempts.reduce((sum, a) => sum + a.timeSpent, 0);
    return totalTime / attempts.length;
  }
}

// Add new logging utility
const LOG_LEVELS = {
  INFO: '📘',
  WARNING: '⚠️',
  ERROR: '🚨',
  SUCCESS: '✅',
  DEBUG: '🔍',
  METRIC: '📊'
} as const;

class AdaptiveLogger {
  static log(level: keyof typeof LOG_LEVELS, message: string, data?: any) {
    const timestamp = new Date().toISOString();
    const emoji = LOG_LEVELS[level];
    
    console.log(`${emoji} [${timestamp}] ${message}`);
    if (data) {
      console.log('  └─ ', data);
    }
  }
}

export class AdaptiveLearningEngine {
  private id: string;
  private userProfile: UserProfile | null;
  private testResults: Record<string, TestResult>;
  private wrongAnswers: WrongAnswer[];
  private adaptiveState: AdaptiveState;
  private questionCache: Map<string, Question>;
  private testType: TestType;
  private questionLoadPromise: Promise<void> | null;
  static TimeUtils: any;

  constructor(
    userProfile: UserProfile | null,
    testResults: Record<string, TestResult> = {},
    wrongAnswers: WrongAnswer[] = [],
    testType: TestType = TestType.LCSW_FREE
  ) {
    this.id = `engine_${Date.now()}`; // Add unique ID for tracking
    try {
      AdaptiveLogger.log('INFO', 'Initializing Adaptive Learning Engine');
      
      if (typeof testResults !== 'object') {
        throw new Error('Invalid input: testResults must be an object');
      }

      this.userProfile = userProfile;
      this.testResults = testResults;
      this.wrongAnswers = wrongAnswers;
      this.adaptiveState = this.initializeAdaptiveState();
      this.questionCache = new Map();
      this.testType = testType;
      this.questionLoadPromise = null;
      
      // Start preloading questions
      this.preloadQuestions();

      AdaptiveLogger.log('SUCCESS', 'Engine initialized successfully', {
        profileExists: !!userProfile,
        testCount: Object.keys(testResults).length,
        wrongAnswersCount: wrongAnswers.length,
        testType
      });
    } catch (error) {
      AdaptiveLogger.log('ERROR', 'Failed to initialize engine', error);
      throw error;
    }
  }

  // Add getter for ID
  get ID(): string {
    return this.id;
  }

  /**
   * Initializes or updates the adaptive state while preserving existing values
   */
  private initializeAdaptiveState(): AdaptiveState {
    AdaptiveLogger.log('DEBUG', 'Initializing/updating adaptive state');
    
    // If we have an existing state, use it as the base
    if (this.adaptiveState) {
        AdaptiveLogger.log('INFO', 'Using existing adaptive state as base');
        return this.adaptiveState;
    }

    // Only create new state if none exists
    AdaptiveLogger.log('INFO', 'Creating new adaptive state with defaults');
    const newState: AdaptiveState = {
        engine: this,
        currentDifficulty: 5,
        categoryStrengths: {},
        insights: {
            weakCategories: [],
            recommendedDifficulty: 5,
            suggestedPracticeFrequency: 'daily',
            performanceTrend: 'steady',
            timeManagement: {
                averageQuestionTime: 0,
                recommendedTimePerQuestion: 90,
                timeManagementAdvice: 'Take your time to read each question carefully'
            }
        },
        recentlyAskedQuestions: [],
        consecutiveCorrect: 0,
        consecutiveIncorrect: 0,
        totalQuestionsAttempted: 0
    };

    AdaptiveLogger.log('SUCCESS', 'New adaptive state created');
    return newState;
  }

  // Add preloading functionality
  private async preloadQuestions(): Promise<void> {
    try {
      // Get all unique question IDs from test results and wrong answers
      const questionIds = new Set<string>();
      
      // Add IDs from test results
      Object.values(this.testResults).forEach(test => {
        test.questionIds?.forEach(id => questionIds.add(id));
      });

      // Add IDs from wrong answers
      this.wrongAnswers.forEach(wa => {
        questionIds.add(wa.question.id);
      });

      if (questionIds.size === 0) {
        logger.debug('AdaptiveLearning', 'No questions to preload');
        return;
      }

      logger.info('AdaptiveLearning', 'Starting question preload', {
        questionCount: questionIds.size
      });

      // Load questions in batches
      const batchSize = 10;
      const batches = Array.from(questionIds).reduce((acc, id, i) => {
        const batchIndex = Math.floor(i / batchSize);
        if (!acc[batchIndex]) acc[batchIndex] = [];
        acc[batchIndex].push(id);
        return acc;
      }, [] as string[][]);

      this.questionLoadPromise = (async () => {
        for (let i = 0; i < batches.length; i++) {
          const batch = batches[i];
          try {
            const questions = await Promise.all(
              batch.map(id => getQuestionById(this.testType, id))
            );

            // Add successful fetches to cache
            questions.forEach(q => {
              if (q) this.questionCache.set(q.id, q);
            });

            logger.debug('AdaptiveLearning', 'Loaded question batch', {
              batchNumber: i + 1,
              totalBatches: batches.length,
              successCount: questions.filter(Boolean).length
            });
          } catch (error) {
            logger.error('AdaptiveLearning', `Error loading question batch ${i + 1}`, error);
          }
        }

        logger.info('AdaptiveLearning', 'Question preload complete', {
          cachedQuestions: this.questionCache.size
        });
      })();
    } catch (error) {
      logger.error('AdaptiveLearning', 'Failed to preload questions', error);
      this.questionLoadPromise = null;
    }
  }

  // Update getQuestionData to work with preloading
  private async getQuestionData(questionId: string): Promise<Question> {
    // Wait for any ongoing preload to complete
    if (this.questionLoadPromise) {
      await this.questionLoadPromise;
    }

    // Check cache first
    if (this.questionCache.has(questionId)) {
      return this.questionCache.get(questionId)!;
    }

    try {
      const question = await getQuestionById(this.testType, questionId);
      if (question) {
        this.questionCache.set(questionId, question);
        return question;
      }
      throw new Error(`Question with ID ${questionId} not found in ${this.testType}`);
    } catch (error) {
      logger.error('AdaptiveLearning', `Failed to fetch question ${questionId} from ${this.testType}`, error);
      throw error;
    }
  }

  // Update calculateQuestionPriority to work with IDs
  public async calculateQuestionPriority(questionId: string): Promise<number> {
    try {
      const question = await this.getQuestionData(questionId);
      
      if (!question || !question.id || !question.category) {
        throw new Error('Invalid question structure');
      }

      // Default weights for different factors
      const weights = {
        difficulty: 0.4,
        categoryStrength: 0.3,
        timeDecay: 0.3
      };

      // Get difficulty score (1-10)
      const difficultyScore = this.calculateDifficultyMatch(this.estimateQuestionDifficulty(questionId) || 5);

      // Get category strength (0-1)
      const categoryStrength = this.calculateCategoryWeight(question.category) || 0.5;

      // Calculate time decay factor (0-1)
      const timeDecay = this.calculateTimeDecayWeight(questionId) || 1;

      const priority = (
        (difficultyScore / 10) * weights.difficulty +
        (1 - categoryStrength) * weights.categoryStrength +
        timeDecay * weights.timeDecay
      );

      logger.info('AdaptiveLearning', 'Priority calculated', {
        questionId,
        difficulty: difficultyScore,
        categoryStrength,
        timeDecay,
        finalPriority: priority
      });

      return priority;
    } catch (error) {
      logger.error('AdaptiveLearning', `Failed to calculate priority for question ${questionId}`, error);
      return 0;
    }
  }

  private estimateQuestionDifficulty(questionId: string): number {
    try {
      AdaptiveLogger.log('DEBUG', 'Estimating question difficulty', { questionId });

      const testEntries = Object.values(this.testResults);
      if (testEntries.length === 0) {
        // Adjust based on consecutive correct/incorrect answers
        const streakModifier = Math.min(
          Math.max(this.adaptiveState.consecutiveCorrect - this.adaptiveState.consecutiveIncorrect, -2),
          2
        ) * 0.5;

        const adaptiveDifficulty = Math.min(
          Math.max(
            this.adaptiveState.currentDifficulty + streakModifier,
            1
          ),
          10
        );

        AdaptiveLogger.log('WARNING', `No test results available (Question ID: ${questionId})`, {
          timestamp: new Date().toISOString(),
          questionId,
          currentDifficulty: this.adaptiveState.currentDifficulty,
          streakModifier,
          adaptiveDifficulty,
          consecutiveCorrect: this.adaptiveState.consecutiveCorrect,
          consecutiveIncorrect: this.adaptiveState.consecutiveIncorrect
        });

        return adaptiveDifficulty;
      }

      const allAttempts = testEntries.flatMap(test => {
        if (!test?.questions) {
          AdaptiveLogger.log('WARNING', 'Invalid test structure', { test });
          return [];
        }

        const question = test.questions.find(q => q?.id === String(questionId));
        if (!question) return [];
        
        const isWrong = (test.wrongAnswers || [])
          .some(wa => wa?.question?.id === String(questionId));

        return [{
          isCorrect: !isWrong,
          timeSpent: test.timeTaken || 0
        }];
      });

      if (allAttempts.length === 0) {
        AdaptiveLogger.log('INFO', 'No attempts found, using default difficulty', { questionId });
        return 5; // Default medium difficulty
      }

      // Calculate difficulty based on:
      // 1. Success rate (70% weight)
      // 2. Average completion time (30% weight)
      const successRate = allAttempts.filter(a => a.isCorrect).length / allAttempts.length;
      const avgTime = allAttempts.reduce((sum, a) => sum + a.timeSpent, 0) / allAttempts.length;
      const expectedTime = TimeUtils.getExpectedTime(5); // Using baseline medium difficulty
      const timeRatio = Math.min(avgTime / expectedTime, 2); // Cap at 2x expected time

      // Convert to 1-10 scale
      const difficultyScore = (
        (1 - successRate) * 0.7 + // Higher failure rate = higher difficulty
        (timeRatio - 1) * 0.3     // Longer time = higher difficulty
      ) * 10;

      const finalDifficulty = Math.max(1, Math.min(10, Math.round(difficultyScore)));

      AdaptiveLogger.log('METRIC', 'Difficulty calculated', {
        questionId,
        successRate,
        avgTime,
        timeRatio,
        difficultyScore,
        finalDifficulty
      });

      return finalDifficulty;

    } catch (error) {
      AdaptiveLogger.log('ERROR', 'Failed to estimate question difficulty', {
        questionId,
        error: error instanceof Error ? error.message : 'Unknown error'
      });
      return 5; // Return default difficulty on error
    }
  }

  /**
   * Calculates weight based on user's performance in the question's category
   * Returns higher values for categories where user struggles
   */
  private calculateCategoryWeight(category: string): number {
    const performance = this.adaptiveState.categoryStrengths[category];
    
    // For new users, use random initial weights to create variety
    if (!performance) {
        // Generate a pseudo-random number between 0.7 and 1.0
        // using the category string as seed
        const hash = category.split('').reduce((acc, char) => {
            return char.charCodeAt(0) + ((acc << 5) - acc);
        }, 0);
        const randomWeight = 0.7 + (Math.abs(hash) % 300) / 1000; // 0.7 to 1.0 range
        
        AdaptiveLogger.log('DEBUG', 'Using seeded weight for new category', {
            category,
            randomWeight
        });
        
        return randomWeight;
    }

    const successRate = performance.correctCount / performance.totalCount;
    // Inverse relationship: lower success rate = higher priority
    return 1 - (successRate * 0.8); // Keep minimum priority at 0.2
  }

  /**
   * Calculates the time decay weight for spaced repetition
   */
  private calculateTimeDecayWeight(questionId: string): number {
    const lastAttempt = this.getLastAttemptDate(questionId);
    if (!lastAttempt) return 1.0; // Never attempted = high priority

    const daysSinceAttempt = TimeUtils.getDaysBetween(lastAttempt, new Date());
    
    // Modified SuperMemo-2 interval calculation
    const interval = this.getOptimalInterval(questionId);
    const normalizedDays = daysSinceAttempt / interval;

    // Bell curve distribution centered around optimal interval
    return Math.exp(-Math.pow(normalizedDays - 1, 2));
  }

  /**
   * Gets the date of the last attempt for a specific question
   */
  private getLastAttemptDate(questionId: string): Date | null {
    const attempts = this.getQuestionAttempts(questionId);
    if (attempts.length === 0) return null;
    return attempts[attempts.length - 1].date;
  }

  /**
   * Calculates optimal review interval based on previous performance
   * Uses exponential backoff for correctly answered questions
   */
  private getOptimalInterval(questionId: string): number {
    const attempts = this.getQuestionAttempts(questionId);
    const baseInterval = 2; // Base interval in days
    
    if (attempts.length === 0) return baseInterval;

    const consecutiveCorrect = AttemptAnalyzer.getConsecutiveCorrect(attempts);
    // Exponential increase in interval for each correct answer
    return baseInterval * Math.pow(2, consecutiveCorrect);
  }

  /**
   * Matches question difficulty to user's current level
   * Prefers questions slightly above current difficulty
   */
  private calculateDifficultyMatch(questionDifficulty: number): number {
    const diffDelta = questionDifficulty - this.adaptiveState.currentDifficulty;
    
    // Prefer questions 1-2 levels above current difficulty
    if (diffDelta > 0 && diffDelta <= 2) return 1.0;
    // Gradually reduce priority for questions too easy or too hard
    return Math.exp(-Math.abs(diffDelta - 1) / 2);
  }

  /**
   * Updates difficulty based on user performance
   */
  private updateDifficulty(isCorrect: boolean, timeSpent: number): void {
    try {
      const previousDifficulty = this.adaptiveState?.currentDifficulty || 5;
      
      // Safety check for NaN
      if (isNaN(previousDifficulty)) {
        AdaptiveLogger.log('WARNING', 'Current difficulty is NaN, resetting to default');
        this.adaptiveState.currentDifficulty = 5;
        return;
      }

      AdaptiveLogger.log('DEBUG', 'Updating difficulty', {
        previousDifficulty,
        isCorrect,
        timeSpent,
        expectedTime: TimeUtils.getExpectedTime(previousDifficulty)
      });

      if (isCorrect) {
        this.adaptiveState.consecutiveCorrect++;
        this.adaptiveState.consecutiveIncorrect = 0;

        if (timeSpent < TimeUtils.getExpectedTime(previousDifficulty) && this.adaptiveState.consecutiveCorrect >= 3) {
          this.adaptiveState.currentDifficulty = Math.min(10, previousDifficulty + 1);
          this.adaptiveState.consecutiveCorrect = 0;
          
          AdaptiveLogger.log('INFO', 'Difficulty increased', {
            newDifficulty: this.adaptiveState.currentDifficulty,
            reason: 'Consecutive correct answers'
          });
        }
      } else {
        this.adaptiveState.consecutiveIncorrect++;
        this.adaptiveState.consecutiveCorrect = 0;

        if (this.adaptiveState.consecutiveIncorrect >= 2) {
          this.adaptiveState.currentDifficulty = Math.max(1, previousDifficulty - 1);
          this.adaptiveState.consecutiveIncorrect = 0;
          
          AdaptiveLogger.log('INFO', 'Difficulty decreased', {
            newDifficulty: this.adaptiveState.currentDifficulty,
            reason: 'Consecutive incorrect answers'
          });
        }
      }
    } catch (error) {
      AdaptiveLogger.log('ERROR', 'Failed to update difficulty', error);
      this.adaptiveState.currentDifficulty = 5; // Reset to safe default
    }
  }

  /**
   * Gets the current study plan based on user profile and performance
   * @returns StudyPlan object with recommended study schedule and milestones
   */
  public getStudyPlan(): StudyPlan {
    const examDate = this.userProfile?.examDate ? new Date(this.userProfile.examDate) : null;
    const daysUntilExam = examDate ? TimeUtils.getDaysBetween(new Date(), examDate) : 30;
    
    return {
      weeklyHours: this.calculateWeeklyStudyHours(daysUntilExam),
      recommendedPace: this.calculateRecommendedPace(daysUntilExam),
      categoryPlans: this.generateCategoryPlans(),
      milestones: this.generateMilestones(daysUntilExam)
    };
  }

  private generateCategoryPlans(): CategoryPlan[] {
    try {
        return Object.entries(this.adaptiveState.categoryStrengths)
            .filter(([category]) => category && typeof category === 'string')
            .map(([category, performance]) => {
                const successRate = performance.correctCount / Math.max(1, performance.totalCount);
                const focusAreas = this.identifyFocusAreas(category);
                
                // Only include valid focus areas
                return {
                    category,
                    hoursPerWeek: successRate < 0.7 ? 4 : 2,
                    focusAreas: focusAreas.filter(area => area && typeof area === 'string'),
                    recommendedResources: this.getRecommendedResources(category)
                };
            })
            .filter(plan => plan.category && plan.focusAreas.length > 0); // Only return plans with valid categories and focus areas
    } catch (error) {
        AdaptiveLogger.log('ERROR', 'Failed to generate category plans', error);
        return [];
    }
  }

  /**
   * Analyzes user performance trends and provides recommendations for:
   * - Areas needing more focus
   * - Suggested practice frequency
   * - Question difficulty adjustments
   */
  public generateInsights(): AdaptiveInsights {
    try {
      AdaptiveLogger.log('INFO', 'Generating insights');

      const recommendedDifficulty = this.getRecommendedDifficulty();
      
      // Safety check for NaN in insights
      if (isNaN(recommendedDifficulty)) {
        AdaptiveLogger.log('WARNING', 'NaN detected in recommendedDifficulty during insights generation');
      }

      const insights = {
        weakCategories: this.getWeakCategories(),
        recommendedDifficulty: isNaN(recommendedDifficulty) ? 5 : recommendedDifficulty,
        suggestedPracticeFrequency: this.getSuggestedPracticeFrequency(),
        performanceTrend: this.getPerformanceTrend(),
        timeManagement: {
          averageQuestionTime: this.calculateAverageQuestionTime(),
          recommendedTimePerQuestion: 90,
          timeManagementAdvice: this.generateTimeManagementAdvice()
        }
      };

      AdaptiveLogger.log('SUCCESS', 'Insights generated', {
        recommendedDifficulty: insights.recommendedDifficulty,
        weakCategoryCount: insights.weakCategories.length
      });

      return insights;
    } catch (error) {
      AdaptiveLogger.log('ERROR', 'Failed to generate insights', error);
      return {
        weakCategories: [],
        recommendedDifficulty: 5, // Safe default
        suggestedPracticeFrequency: 'daily',
        performanceTrend: 'steady',
        timeManagement: {
          averageQuestionTime: 0,
          recommendedTimePerQuestion: 90,
          timeManagementAdvice: 'Take your time to read each question carefully'
        }
      };
    }
  }

  /**
   * Retrieves and analyzes previous attempts for a specific question
   * Currently tracks correctness and time spent
   * TODO: Add difficulty tracking when question difficulty is implemented
   */
  private getQuestionAttempts(questionId: string): QuestionAttempt[] {
    const attempts: QuestionAttempt[] = [];
    
    // Ensure testResults exists and is an array
    if (Object.keys(this.testResults).length === 0) {
      return attempts; // Return empty array if no test history
    }

    // Check test history
    Object.values(this.testResults).forEach(test => {
      // Ensure test and its properties exist
      if (!test?.questions) return;

      const question = test.questions.find(q => q?.id === String(questionId));
      if (question) {
        const isCorrect = !(test.wrongAnswers || []).some(wa => wa?.question?.id === String(questionId));
        attempts.push({
          date: new Date(test.date || Date.now()),
          isCorrect,
          timeSpent: test.timeTaken || 0,
          difficulty: this.estimateQuestionDifficulty(String(questionId))
        });
      }
    });

    return attempts;
  }

  /**
   * Calculates learning style match score
   * Currently simplified for text-only questions
   * TODO: Add question parameter back when additional question formats are added
   */
  private calculateLearningStyleMatch(): number {
    const userStyle = this.userProfile?.learningStyle;
    
    if (!userStyle) {
      return 0.85; // Default score when no learning style is set
    }

    switch (userStyle) {
      case LearningStyle.READING_WRITING:
        return 1.0;
      case LearningStyle.VISUAL:
        return 0.9;
      case LearningStyle.AUDITORY:
      case LearningStyle.KINESTHETIC:
        return 0.8;
      default:
        return 0.85;
    }
  }

  /**
   * Calculates weight based on wrong answer history
   */
  private calculateWrongAnswerWeight(questionId: string): number {
    const wrongAnswerCount = this.wrongAnswers.filter(
      wa => wa.question.id === String(questionId)
    ).length;

    if (wrongAnswerCount > 1) return PRIORITY_WEIGHTS.PERFORMANCE.WRONG_MULTIPLE_TIMES;
    if (wrongAnswerCount === 1) return PRIORITY_WEIGHTS.PERFORMANCE.WRONG_ONCE;
    
    const attempts = this.getQuestionAttempts(questionId);
    const avgTime = AttemptAnalyzer.getAverageTime(attempts);
    const expectedTime = TimeUtils.getExpectedTime(this.adaptiveState.currentDifficulty);
    
    return avgTime > expectedTime * 1.5 
      ? PRIORITY_WEIGHTS.PERFORMANCE.CORRECT_WITH_DIFFICULTY
      : PRIORITY_WEIGHTS.PERFORMANCE.CORRECT_QUICKLY;
  }

  focusAreas(): string[] {
    const wrongAnswers = Object.values(this.testResults).flatMap(test => test?.wrongAnswers || []);
    const categories = Array.from(new Set(wrongAnswers.map(wa => wa.question.category)));
    return categories.flatMap(category => this.identifyFocusAreas(category));
  }

  /**
   * Identifies specific topics within a category that need focus
   */
  private identifyFocusAreas(category: string): string[] {
    try {
        if (!category || typeof category !== 'string') {
            AdaptiveLogger.log('WARNING', 'Invalid category for focus areas', { category });
            return [];
        }

        const categoryQuestions = Object.values(this.testResults)
            .flatMap(test => test?.questions || [])
            .filter(q => {
                // Skip invalid questions or those without categories
                if (!q || !q.category || typeof q.category !== 'string') {
                    return false;
                }
                return q.category === category;
            });

        if (categoryQuestions.length === 0) {
            return [];
        }

        const topicPerformance = new Map<string, { correct: number, total: number }>();
        
        categoryQuestions.forEach(question => {
            if (!question.category) return; // Skip if no category
            
            const isWrong = this.wrongAnswers.some(wa => wa.question.id === question.id);
            const topic = question.category;
            
            const current = topicPerformance.get(topic) || { correct: 0, total: 0 };
            topicPerformance.set(topic, {
                correct: current.correct + (isWrong ? 0 : 1),
                total: current.total + 1
            });
        });

        // Return only valid topics with less than 70% success rate
        return Array.from(topicPerformance.entries())
            .filter(([topic, stats]) => {
                if (!topic || typeof topic !== 'string') return false;
                return (stats.correct / stats.total) < 0.7;
            })
            .map(([topic]) => topic);

    } catch (error) {
        AdaptiveLogger.log('ERROR', 'Failed to identify focus areas', {
            category,
            error: error instanceof Error ? error.message : 'Unknown error'
        });
        return [];
    }
  }

  /**
   * Calculates recommended weekly study hours based on exam proximity
   */
  private calculateWeeklyStudyHours(daysUntilExam: number): number {
    if (!this.userProfile?.examDate) {
      return 15; // Default to base hours if no exam date
    }
    const baseHours = 15;
    const urgencyMultiplier = Math.max(1, 30 / daysUntilExam);
    return Math.min(40, Math.ceil(baseHours * urgencyMultiplier));
  }

  /**
   * Generates milestone targets based on current performance and exam date
   */
  private generateMilestones(daysUntilExam: number): Milestone[] {
    const milestones: Milestone[] = [];
    const weeksUntilExam = Math.ceil(daysUntilExam / 7);
    const currentAverage = this.calculateCurrentAverage();
    const targetScore = 0.90; // 90% target score
    
    const weeklyImprovement = (targetScore - currentAverage) / weeksUntilExam;
    
    for (let week = 1; week <= weeksUntilExam; week++) {
      const milestoneDate = new Date();
      milestoneDate.setDate(milestoneDate.getDate() + (week * 7));
      
      milestones.push({
        category: '',
        date: milestoneDate.toISOString(),
        targetScore: currentAverage + (weeklyImprovement * week),
        categoriesComplete: this.getCompletedCategories(),
        recommendedActions: this.generateRecommendedActions(week, weeksUntilExam)
      });
    }

    return milestones;
  }

  /**
   * Calculates current average score across recent tests
   */
  public calculateCurrentAverage(): number {
    try {
        const recentTests = Object.values(this.testResults)
            .slice(-5) // Consider last 5 tests
            .filter(test => test?.date && typeof test.percentage === 'number');
            
        if (recentTests.length === 0) {
            return 0.7; // Default to 70% if no recent tests
        }
        
        const average = recentTests.reduce((sum, test) => sum + test.percentage, 0) / recentTests.length;
        
        // Ensure we never return NaN
        if (isNaN(average)) {
            AdaptiveLogger.log('WARNING', 'Calculated average is NaN', {
                recentTests: recentTests.map(t => t.percentage)
            });
            return 0.7; // Default to 70%
        }

        return Math.min(100, Math.max(0, average * 100));
    } catch (error) {
        AdaptiveLogger.log('ERROR', 'Failed to calculate current average', error);
        return 0.7; // Default on error
    }
  }

  /**
   * Predicts which categories should be completed by a given week
   */
  private predictCompletedCategories(weekNumber: number): string[] {
    // Sort categories by current strength
    const sortedCategories = Object.entries(this.adaptiveState.categoryStrengths)
      .sort(([, a], [, b]) => 
        (b.correctCount / b.totalCount) - (a.correctCount / a.totalCount)
      );

    // Predict completion based on current performance and week number
    const categoriesPerWeek = Math.ceil(sortedCategories.length / 8); // Complete all categories in 8 weeks
    return sortedCategories
      .slice(0, categoriesPerWeek * weekNumber)
      .map(([category]) => category);
  }

  /**
   * Generates recommended actions based on study progress
   */
  public generateRecommendedActions(currentWeek: number, totalWeeks: number): string[] {
    const actions: string[] = [];
    const progress = currentWeek / totalWeeks;

    if (progress < 0.3) {
      actions.push("Focus on understanding core concepts");
      actions.push("Complete content review for main categories");
    } else if (progress < 0.6) {
      actions.push("Increase practice test frequency");
      actions.push("Review wrong answers in detail");
    } else if (progress < 0.9) {
      actions.push("Focus on weak areas identified by practice tests");
      actions.push("Take full-length practice exams");
    } else {
      actions.push("Final review of commonly missed concepts");
      actions.push("Light review and rest before exam");
    }

    return actions;
  }

  // Update selectNextQuestion to handle batched loading
  public async selectNextQuestion(availableQuestionIds: string[]): Promise<string> {
    try {
      // Wait for preload to complete before calculating priorities
      if (this.questionLoadPromise) {
        await this.questionLoadPromise;
      }

      const priorities = await Promise.all(
        availableQuestionIds.map(async qId => ({
          id: qId,
          priority: await this.calculateQuestionPriority(qId)
        }))
      );

      // Sort by priority and select the highest
      const sorted = priorities.sort((a, b) => b.priority - a.priority);
      return sorted[0]?.id;
    } catch (error) {
      logger.error('AdaptiveLearning', 'Failed to select next question', error);
      // Return first available question as fallback
      return availableQuestionIds[0];
    }
  }

  // Update updateWithResult to work with IDs
  public async updateWithResult(questionId: string | Question, isCorrect: boolean, timeSpent: number): Promise<void> {
    try {
      const question = typeof questionId === 'string' 
        ? await this.getQuestionData(questionId)
        : questionId;
      
      // Update difficulty based on performance
      this.updateDifficulty(isCorrect, timeSpent);

      // Update consecutive tracking
      if (isCorrect) {
        this.adaptiveState.consecutiveCorrect++;
        this.adaptiveState.consecutiveIncorrect = 0;
      } else {
        this.adaptiveState.consecutiveIncorrect++;
        this.adaptiveState.consecutiveCorrect = 0;
      }

      // Update total questions attempted
      this.adaptiveState.totalQuestionsAttempted++;

      // Log the update
      logger.info('AdaptiveLearning', 'Updated with result', {
        questionId: typeof questionId === 'string' ? questionId : questionId.id,
        isCorrect,
        timeSpent,
        newDifficulty: this.adaptiveState.currentDifficulty
      });
    } catch (error) {
      logger.error('AdaptiveLearning', `Failed to update result for question ${typeof questionId === 'string' ? questionId : questionId.id}`, error);
      throw error;
    }
  }

  /**
   * Calculate recommended pace based on remaining time
   */
  private calculateRecommendedPace(daysUntilExam: number): number {
    const totalQuestions = 170; // ASWB exam length
    const recommendedPracticeQuestions = Math.ceil((totalQuestions * 3) / daysUntilExam);
    return Math.min(50, Math.max(10, recommendedPracticeQuestions)); // Between 10-50 questions per day
  }

  /**
   * Get recommended resources for a category
   */
  private getRecommendedResources(category: string): string[] {
    // This could be expanded with actual resource links/references
    const resources = [
      "ASWB Study Guide Chapter",
      "Practice Questions Set",
      "Video Lectures",
      "Flashcard Deck"
    ];
    
    return resources.map(resource => `${resource} for ${category}`);
  }

  /**
   * Calculate average question time across recent tests
   */
  private calculateAverageQuestionTime(): number {
    const recentTests = Object.values(this.testResults).slice(-3);
    const totalTime = recentTests.reduce((sum, test) => sum + (test.timeTaken ?? 0), 0);
    return totalTime / recentTests.length;
  }

  /**
   * Generate time management advice based on user performance
   */
  private generateTimeManagementAdvice(): string {
    // This could be expanded with actual time management advice
    return "Try to answer questions within the recommended time per question.";
  }

  /**
   * Calculate performance trend based on recent tests
   */
  private calculatePerformanceTrend(recentTests: TestResult[]): ImprovementStatus {
    const recentSuccessRate = recentTests.reduce((sum, test) => sum + test.percentage, 0) / recentTests.length;
    const previousSuccessRate = Object.values(this.testResults).slice(-6).reduce((sum, test) => sum + test.percentage, 0) / 6;

    if (recentSuccessRate > previousSuccessRate) return 'improving';
    if (recentSuccessRate < previousSuccessRate) return 'declining';
    return 'steady';
  }

  /**
   * Calculate practice frequency based on user performance
   */
  private calculatePracticeFrequency(): string {
    // This could be expanded with actual practice frequency advice
    return "Practice at least 30 minutes per day.";
  }

  /**
   * Get current difficulty level (1-10)
   */
  public getCurrentDifficulty(): number {
    return this.adaptiveState.currentDifficulty;
  }

  /**
   * Get number of consecutive correct answers
   */
  public getConsecutiveCorrect(): number {
    return this.adaptiveState.consecutiveCorrect;
  }

  /**
   * Get consecutive incorrect answers
   */
  public getConsecutiveIncorrect(): number {
    return this.adaptiveState.consecutiveIncorrect;
  }

  /**
   * Get category strengths with formatted data for display
   */
  public getCategoryStrengths(): Record<string, CategoryPerformance> {
    const strengths: Record<string, CategoryPerformance> = {};
    
    Object.entries(this.adaptiveState.categoryStrengths).forEach(([category, performance]) => {
      strengths[category] = {
        category,
        correctCount: performance.correctCount || 0,
        totalCount: performance.totalCount || 0,
        averageTime: performance.averageTime || 0,
        lastAttempted: performance.lastAttempted || new Date()
      };
    });

    return strengths;
  }

  /**
   * Get weak categories with formatted data for display
   */
  public getWeakCategories(): WeakCategory[] {
    return Object.entries(this.adaptiveState.categoryStrengths)
      .filter(([_, performance]) => {
        const successRate = performance.correctCount / Math.max(1, performance.totalCount);
        return successRate < 0.7;
      })
      .map(([category, performance]) => ({
        category,
        successRate: performance.correctCount / Math.max(1, performance.totalCount),
        recommendedFocus: this.getRecommendedResources(category)
      }));
  }

  /**
   * Get recommended difficulty level (1-10)
   */
  public getRecommendedDifficulty(): number {
    try {
        const recentPerformance = this.calculateCurrentAverage();
        const currentDifficulty = this.adaptiveState?.currentDifficulty || 5;
        
        // Safety check for input values
        if (isNaN(recentPerformance) || isNaN(currentDifficulty)) {
            AdaptiveLogger.log('WARNING', 'Invalid inputs for difficulty calculation', {
                recentPerformance,
                currentDifficulty
            });
            return 5;
        }

        const calculatedDifficulty = Math.min(10, Math.max(1, 
            Math.round(currentDifficulty * (1 + (recentPerformance - 0.7)))
        ));

        // Additional NaN check after calculation
        if (isNaN(calculatedDifficulty)) {
            AdaptiveLogger.log('WARNING', 'Calculated difficulty is NaN', {
                recentPerformance,
                currentDifficulty,
                calculation: `${currentDifficulty} * (1 + (${recentPerformance} - 0.7))`
            });
            return 5;
        }

        AdaptiveLogger.log('METRIC', 'Recommended difficulty calculated', {
            recentPerformance,
            currentDifficulty,
            calculatedDifficulty
        });

        return calculatedDifficulty;

    } catch (error) {
        AdaptiveLogger.log('ERROR', 'Failed to get recommended difficulty', {
            error: error instanceof Error ? error.message : 'Unknown error',
            state: {
                currentDifficulty: this.adaptiveState?.currentDifficulty,
                hasTestResults: Boolean(this.testResults?.length)
            }
        });
        return 5;
    }
  }

  /**
   * Get performance trend based on recent tests
   */
  public getPerformanceTrend(): 'improving' | 'steady' | 'declining' {
    const recentTests = Object.values(this.testResults)
      .slice(-3)
      .filter(test => test.date)
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    if (recentTests.length < 2) return 'steady';

    const scores = recentTests.map(test => test.percentage);
    const trend = scores[scores.length - 1] - scores[0];

    if (trend > 5) return 'improving';
    if (trend < -5) return 'declining';
    return 'steady';
  }

  public generateTimeManagementInsights(testResult: TestResult): {
    averageQuestionTime: number;
    recommendedTimePerQuestion: number;
    timeManagementAdvice: string;
  } {
    const avgTime = testResult.timeTaken && testResult.questions?.length 
      ? testResult.timeTaken / testResult.questions.length 
      : 0;
    const recommendedTime = TimeUtils.getExpectedTime(this.adaptiveState.currentDifficulty);
    
    let advice = 'Your pacing is good - maintain this rhythm';
    if (avgTime > recommendedTime * 1.3) {
      advice = 'Try to increase your pace - aim for 90 seconds per question';
    } else if (avgTime < recommendedTime * 0.5) {
      advice = 'Take more time to carefully consider each option';
    }

    return {
      averageQuestionTime: avgTime,
      recommendedTimePerQuestion: recommendedTime,
      timeManagementAdvice: advice
    };
  }

  public calculateAverageResponseTime(testResult: TestResult): number {
    if (!testResult.timeTaken || !testResult.questions?.length) return 0;
    return testResult.timeTaken / testResult.questions.length;
  }

  private generateCategoryMetrics(): Record<string, CategoryMetrics> {
    try {
      const metrics: Record<string, CategoryMetrics> = {};
      
      if (!this.adaptiveState?.categoryStrengths) {
        logger.warn('AdaptiveLearning', 'No category strengths available');
        return metrics;
      }

      Object.entries(this.adaptiveState.categoryStrengths).forEach(([category, performance]) => {
        if (!performance) {
          logger.warn('AdaptiveLearning', `Missing performance data for category: ${category}`);
          return;
        }

        metrics[category] = {
          correct: performance.correctCount || 0,
          total: performance.totalCount || 0,
          averageTime: performance.averageTime || 0,
          successRate: performance.totalCount > 0 
            ? performance.correctCount / performance.totalCount 
            : 0
        };
      });

      return metrics;
    } catch (error) {
      logger.error('AdaptiveLearning', 'Error generating category metrics', error);
      return {};
    }
  }

  /**
   * Calculates dashboard statistics from adaptive metrics and test results
   */
  public calculateDashboardStats(): {
    totalQuestionsAttempted: number;
    studyStreak: number;
    masteryLevel: number;
    averageScore: number;
    scoreTrend: {
      direction: 'up' | 'down';
      percentage: number;
    };
    completedTests: number;
    totalStudyHours: number;
    studyHoursTrend: {
      direction: 'up' | 'down';
      percentage: number;
    };
  } {
    try {
      // Get recent tests for trend analysis
      const recentTests = Object.values(this.testResults || {})
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        .slice(0, 10);

      // Calculate score trend
      const scoreTrend = this.calculateScoreTrend(recentTests);

      // Calculate study hours trend
      const studyHoursTrend = this.calculateStudyHoursTrend(recentTests);

      // Calculate average score
      const averageScore = recentTests.length > 0
        ? recentTests.reduce((sum, test) => sum + test.score, 0) / recentTests.length
        : 0;

      return {
        totalQuestionsAttempted: this.adaptiveState.totalQuestionsAttempted || 0,
        studyStreak: this.calculateWeeklyStreak(),
        masteryLevel: this.calculateExamReadiness(),
        averageScore,
        scoreTrend,
        completedTests: Object.keys(this.testResults || {}).length,
        totalStudyHours: this.calculateTotalStudyHours(),
        studyHoursTrend
      };
    } catch (error) {
      logger.error('AdaptiveLearning', 'Error calculating dashboard stats', error);
      return {
        totalQuestionsAttempted: 0,
        studyStreak: 0,
        masteryLevel: 0,
        averageScore: 0,
        scoreTrend: { direction: 'up', percentage: 0 },
        completedTests: 0,
        totalStudyHours: 0,
        studyHoursTrend: { direction: 'up', percentage: 0 }
      };
    }
  }

  private calculateScoreTrend(recentTests: TestResult[]): { direction: 'up' | 'down'; percentage: number } {
    if (recentTests.length < 2) {
      return { direction: 'up', percentage: 0 };
    }

    const recentAvg = recentTests.slice(0, 3).reduce((sum, test) => sum + test.score, 0) / 3;
    const olderAvg = recentTests.slice(-3).reduce((sum, test) => sum + test.score, 0) / 3;
    
    const direction = recentAvg >= olderAvg ? 'up' : 'down';
    const percentage = Math.abs((recentAvg - olderAvg) / olderAvg * 100);

    return { direction, percentage };
  }

  private calculateStudyHoursTrend(recentTests: TestResult[]): { direction: 'up' | 'down'; percentage: number } {
    if (recentTests.length < 2) {
      return { direction: 'up', percentage: 0 };
    }

    const recentHours = recentTests.slice(0, 3)
      .reduce((sum, test) => sum + (test.timeTaken || 0) / 3600, 0);
    const olderHours = recentTests.slice(-3)
      .reduce((sum, test) => sum + (test.timeTaken || 0) / 3600, 0);
    
    const direction = recentHours >= olderHours ? 'up' : 'down';
    const percentage = Math.abs((recentHours - olderHours) / olderHours * 100);

    return { direction, percentage };
  }

  private calculateTotalStudyHours(): number {
    return Object.values(this.testResults || {})
      .reduce((total, test) => total + (test.timeTaken || 0) / 3600, 0);
  }

  public generateAdaptiveMetrics(): AdaptiveMetrics {
    logger.debug('AdaptiveLearning', 'Starting generateAdaptiveMetrics', {
      hasTestResults: !!this.testResults,
      testResultsCount: Object.keys(this.testResults || {}).length
    });

    try {
      // Get recent tests with safety checks
      const recentTests = this.testResults 
        ? Object.values(this.testResults)
            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
            .slice(0, 10)
        : [];

      logger.debug('AdaptiveLearning', 'Recent tests data', {
        recentTestsCount: recentTests.length,
        recentTests: recentTests.map(test => ({
          id: test.id,
          hasQuestions: !!test.questions,
          questionsLength: test.questions?.length,
          hasScore: test.score !== undefined
        }))
      });

      // Safety check for empty tests
      if (!recentTests.length) {
        logger.warn('AdaptiveLearning', 'No recent tests available');
        return {
          startingDifficulty: this.adaptiveState.currentDifficulty,
          endingDifficulty: this.adaptiveState.currentDifficulty,
          averageResponseTime: 0,
          categoryPerformance: {},
          overallSuccessRate: 0,
          totalQuestionsAttempted: 0,
          currentStreak: 0,
          longestStreak: 0,
          lastSessionDate: new Date().toISOString(),
          weeklyActivityStreak: 0,
          studyTimeThisWeek: 0,
          estimatedExamReadiness: 0,
          completedCategories: [],
          mastery: {
            beginner: [],
            intermediate: [],
            advanced: []
          },
          recentPerformance: [],
          preferredTimes: [],
          difficultyProgression: []
        };
      }

      // Calculate total questions with safety checks
      const totalQuestions = recentTests.reduce((sum, test) => {
        if (!test?.questions?.length) {
          logger.warn('AdaptiveLearning', 'Test missing questions', { testId: test?.id });
          return sum;
        }
        return sum + test.questions.length;
      }, 0);

      logger.debug('AdaptiveLearning', 'Calculated total questions', { totalQuestions });

      // Calculate correct answers with safety checks
      const correctAnswers = recentTests.reduce((sum, test) => {
        if (test.score === undefined) {
          logger.warn('AdaptiveLearning', 'Test missing score', { testId: test?.id });
          return sum;
        }
        return sum + test.score;
      }, 0);

      logger.debug('AdaptiveLearning', 'Calculated correct answers', { correctAnswers });

      // Calculate success rate
      const overallSuccessRate = totalQuestions > 0 
        ? correctAnswers / totalQuestions 
        : 0;

      // Generate category metrics with safety checks
      const categoryPerformance = this.generateCategoryMetrics();

      const metrics: AdaptiveMetrics = {
        startingDifficulty: this.adaptiveState.currentDifficulty,
        endingDifficulty: this.getCurrentDifficulty(),
        averageResponseTime: this.calculateAverageQuestionTime(),
        categoryPerformance,
        overallSuccessRate,
        totalQuestionsAttempted: totalQuestions,
        currentStreak: this.adaptiveState.consecutiveCorrect || 0,
        longestStreak: this.calculateLongestStreak(),
        lastSessionDate: this.getLastSessionDate(),
        weeklyActivityStreak: this.calculateWeeklyStreak(),
        studyTimeThisWeek: this.calculateStudyTimeThisWeek(),
        estimatedExamReadiness: this.calculateExamReadiness(),
        completedCategories: this.getCompletedCategories(),
        mastery: this.calculateMasteryLevels(),
        recentPerformance: this.getRecentPerformance(),
        preferredTimes: this.analyzePreferredTimes(),
        difficultyProgression: this.getDifficultyProgression()
      };

      logger.debug('AdaptiveLearning', 'Generated metrics successfully', metrics);

      return metrics;

    } catch (error) {
      logger.error('AdaptiveLearning', 'Error generating metrics', error);
      throw new Error('Failed to generate adaptive metrics');
    }
  }

  private calculateLongestStreak(): number {
    logger.debug('AdaptiveLearning', 'Calculating longest streak', {
      hasTestResults: !!this.testResults,
      testCount: Object.keys(this.testResults || {}).length
    });

    if (!this.testResults || Object.keys(this.testResults).length === 0) {
      logger.debug('AdaptiveLearning', 'No test results available for streak calculation');
      return 0;
    }

    try {
      let currentStreak = 0;
      let longestStreak = 0;
      
      // Sort tests by date
      const sortedTests = Object.values(this.testResults)
        .filter(test => {
          // Validate test object
          const isValid = test && 
            test.questions && 
            Array.isArray(test.questions) && 
            typeof test.score === 'number';

          if (!isValid) {
            logger.warn('AdaptiveLearning', 'Invalid test data found', { test });
          }

          return isValid;
        })
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      logger.debug('AdaptiveLearning', 'Processing sorted tests for streak', {
        totalTests: sortedTests.length,
        firstTest: sortedTests[0]?.date,
        lastTest: sortedTests[sortedTests.length - 1]?.date
      });
      
      sortedTests.forEach(test => {
        // Calculate success rate with safety checks
        const questionsLength = test.questions?.length || 0;
        const successRate = questionsLength > 0 ? (test.score || 0) / questionsLength : 0;

        logger.debug('AdaptiveLearning', 'Processing test for streak', {
          testId: test.id,
          questionsLength,
          score: test.score,
          successRate
        });

        if (successRate >= 0.7) { // Consider 70% or better as maintaining streak
          currentStreak++;
          longestStreak = Math.max(longestStreak, currentStreak);
        } else {
          currentStreak = 0;
        }
      });

      logger.debug('AdaptiveLearning', 'Streak calculation complete', {
        longestStreak,
        finalCurrentStreak: currentStreak
      });

      return longestStreak;

    } catch (error) {
      logger.error('AdaptiveLearning', 'Error calculating longest streak', error);
      return 0; // Return safe default value
    }
  }

  private calculateWeeklyStreak(): number {
    const weeklyActivity = new Map<string, boolean>();
    let currentStreak = 0;
    
    // Get activity for each week
    Object.values(this.testResults).forEach(test => {
      const weekStart = new Date(test.date);
      weekStart.setHours(0, 0, 0, 0);
      weekStart.setDate(weekStart.getDate() - weekStart.getDay()); // Start of week
      const weekKey = weekStart.toISOString();
      weeklyActivity.set(weekKey, true);
    });

    // Calculate consecutive weeks
    const sortedWeeks = Array.from(weeklyActivity.keys())
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

    for (let i = 0; i < sortedWeeks.length; i++) {
      const currentWeek = new Date(sortedWeeks[i]);
      const expectedPrevWeek = new Date(currentWeek);
      expectedPrevWeek.setDate(expectedPrevWeek.getDate() - 7);
      
      if (i === 0 || weeklyActivity.has(expectedPrevWeek.toISOString())) {
        currentStreak++;
      } else {
        break;
      }
    }

    return currentStreak;
  }

  public calculateStudyTimeThisWeek(): number {
    const now = new Date();
    const weekStart = new Date(now);
    weekStart.setDate(now.getDate() - now.getDay());
    weekStart.setHours(0, 0, 0, 0);

    return Object.values(this.testResults)
      .filter(test => new Date(test.date) >= weekStart)
      .reduce((total, test) => total + (test.timeTaken || 0), 0);
  }

  public calculateExamReadiness(): number {
    logger.debug('AdaptiveLearning', 'Calculating exam readiness', {
      hasTestResults: !!this.testResults,
      hasAdaptiveState: !!this.adaptiveState
    });

    try {
      const weights = {
        RECENT_PERFORMANCE: 0.4,
        CATEGORY_COVERAGE: 0.2,
        DIFFICULTY_LEVEL: 0.2,
        CONSISTENCY: 0.2
      };

      // Recent performance (last 5 tests)
      const recentTests = this.testResults 
        ? Object.values(this.testResults)
            .filter(test => test && test.questions && test.percentage !== undefined)
            .slice(-5)
        : [];

      logger.debug('AdaptiveLearning', 'Recent tests for exam readiness', {
        totalTests: recentTests.length,
        tests: recentTests.map(test => ({
          id: test.id,
          percentage: test.percentage,
          questionCount: test.questions?.length
        }))
      });

      const recentPerformance = recentTests.length > 0
        ? recentTests.reduce((sum, test) => sum + (test.percentage || 0), 0) / recentTests.length
        : 0;

      // Category coverage
      const uniqueCategories = new Set<string>();
      const masteredCategories = new Set<string>();

      Object.values(this.testResults || {}).forEach(test => {
        if (!test?.questions) return;
        
        test.questions.forEach(q => {
          if (!q?.category) return;
          uniqueCategories.add(q.category);
          
          // Consider a category mastered if success rate > 80%
          const categorySuccess = this.calculateCategorySuccessRate(q.category);
          if (categorySuccess >= 0.8) {
            masteredCategories.add(q.category);
          }
        });
      });

      const categoryCoverage = uniqueCategories.size > 0 
        ? masteredCategories.size / uniqueCategories.size 
        : 0;

      logger.debug('AdaptiveLearning', 'Category coverage', {
        totalCategories: uniqueCategories.size,
        masteredCategories: masteredCategories.size,
        coverage: categoryCoverage
      });

      // Difficulty level progress
      const difficultyProgress = (this.adaptiveState?.currentDifficulty || 5) / 10;

      // Consistency (weekly streak relative to 8 weeks)
      const consistencyScore = Math.min(this.calculateWeeklyStreak() / 8, 1);
      const normalizedRecentPerformance = recentPerformance / 100;

      const readinessScore = Math.round(
        (normalizedRecentPerformance * weights.RECENT_PERFORMANCE +
        categoryCoverage * weights.CATEGORY_COVERAGE +
        difficultyProgress * weights.DIFFICULTY_LEVEL +
        consistencyScore * weights.CONSISTENCY) * 100
      );

      logger.debug('AdaptiveLearning', 'Exam readiness calculation', {
        recentPerformance,
        categoryCoverage,
        difficultyProgress,
        consistencyScore,
        finalScore: readinessScore
      });

      return readinessScore;

    } catch (error) {
      logger.error('AdaptiveLearning', 'Error calculating exam readiness', error);
      return 0; // Safe default
    }
  }

  private calculateCategorySuccessRate(category: string): number {
    try {
      if (!this.testResults) return 0;

      const categoryQuestions = Object.values(this.testResults)
        .filter(test => test?.questions)
        .flatMap(test => test.questions || [])
        .filter(q => q?.category === category);

      if (!categoryQuestions.length) return 0;

      const correctAnswers = categoryQuestions.filter(q => 
        !this.wrongAnswers?.some(wa => wa.question.id === q.id)
      ).length;

      return correctAnswers / categoryQuestions.length;
    } catch (error) {
      logger.error('AdaptiveLearning', 'Error calculating category success rate', {
        error,
        category
      });
      return 0;
    }
  }

  private getCompletedCategories(): string[] {
    logger.debug('AdaptiveLearning', 'Getting completed categories', {
      hasTestResults: !!this.testResults,
      testResultsCount: Object.keys(this.testResults || {}).length
    });

    try {
      const categoryProgress = new Map<string, {
        total: number;
        correct: number;
      }>();

      // Process test results
      Object.values(this.testResults || {}).forEach(test => {
        // Handle tests with questions array
        if (test?.questions?.length) {
          test.questions.forEach(question => {
            if (!question?.category) return;
            
            const progress = categoryProgress.get(question.category) || { total: 0, correct: 0 };
            progress.total++;
            
            // Check if question is in wrongAnswers
            const isWrong = test.wrongAnswers?.some(wa => 
              wa.question?.id === question.id
            );
            
            if (!isWrong) {
              progress.correct++;
            }
            
            categoryProgress.set(question.category, progress);
          });
        }
        // Handle tests with only wrongAnswers
        else if (test?.wrongAnswers?.length) {
          const uniqueCategories = new Set(
            test.wrongAnswers
              .map(wa => wa.question?.category)
              .filter(Boolean)
          );

          uniqueCategories.forEach(category => {
            if (!category) return;
            
            const wrongCount = test.wrongAnswers?.filter(wa => 
              wa.question?.category === category
            ).length || 0;

            const progress = categoryProgress.get(category) || { total: 0, correct: 0 };
            progress.total += wrongCount;
            categoryProgress.set(category, progress);
          });
        }
      });

      logger.debug('AdaptiveLearning', 'Category progress calculated', {
        categories: Array.from(categoryProgress.entries()).map(([category, progress]) => ({
          category,
          total: progress.total,
          correct: progress.correct,
          percentage: (progress.correct / progress.total) * 100
        }))
      });

      // Consider a category completed if success rate is >= 80%
      const completedCategories = Array.from(categoryProgress.entries())
        .filter(([_, progress]) => {
          const successRate = progress.total > 0 
            ? (progress.correct / progress.total) 
            : 0;
          return successRate >= 0.8;
        })
        .map(([category]) => category);

      logger.debug('AdaptiveLearning', 'Completed categories identified', {
        total: completedCategories.length,
        categories: completedCategories
      });

      return completedCategories;

    } catch (error) {
      logger.error('AdaptiveLearning', 'Error getting completed categories', error);
      return [];
    }
  }

  public calculateMasteryLevels(): Mastery {
    const categoryPerformance: Record<string, { correct: number; total: number }> = {};

    // Calculate performance for each category
    Object.values(this.testResults).forEach(test => {
      test.questions?.forEach(question => {
        const category = question.category;
        const current = categoryPerformance[category] || { correct: 0, total: 0 };
        const isCorrect = !test.wrongAnswers.some(wa => wa.question.id === question.id);
        
        categoryPerformance[category] = {
          correct: current.correct + (isCorrect ? 1 : 0),
          total: current.total + 1
        };
      });
    });

    const mastery: Mastery = {
      beginner: [] as string[],
      intermediate: [] as string[],
      advanced: [] as string[]
    };

    // Categorize based on success rate
    Object.entries(categoryPerformance).forEach(([category, stats]) => {
      const successRate = stats.correct / stats.total;
      if (successRate >= 0.8) {
        mastery.advanced.push(category);
      } else if (successRate >= 0.5) {
        mastery.intermediate.push(category);
      } else {
        mastery.beginner.push(category);
      }
    });

    return mastery;
  }

  public getRecentPerformance(): RecentPerformance[] {
    return Object.values(this.testResults)
      .slice(-10) // Last 10 tests
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      .map(test => {
        // Find dominant category in test
        const categoryCount = new Map<string, number>();
        test.questions?.forEach(q => {
          categoryCount.set(q.category, (categoryCount.get(q.category) || 0) + 1);
        });
        const dominantCategory = Array.from(categoryCount.entries())
          .sort((a, b) => b[1] - a[1])[0][0];

        return {
          date: new Date(test.date).toISOString(),
          score: test.percentage,
          category: dominantCategory,
          questionsAttempted: test.questions?.length || 0
        };
      });
  }

  public analyzePreferredTimes(): PreferredTime[] {
    const performanceByTime = new Map<string, { total: number; count: number }>();
    
    Object.values(this.testResults).forEach(test => {
      const date = new Date(test.date);
      const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
      const hour = date.getHours();
      const timeOfDay = hour < 12 ? 'morning' : hour < 17 ? 'afternoon' : 'evening';
      
      const key = `${dayOfWeek}-${timeOfDay}`;
      const current = performanceByTime.get(key) || { total: 0, count: 0 };
      performanceByTime.set(key, {
        total: current.total + test.percentage,
        count: current.count + 1
      });
    });

    return Array.from(performanceByTime.entries())
      .map(([key, stats]) => {
        const [dayOfWeek, timeOfDay] = key.split('-');
        return {
          dayOfWeek,
          timeOfDay,
          averagePerformance: stats.total / stats.count
        };
      });
  }

  public getDifficultyProgression(): DifficultyProgress[] {
    return Object.values(this.testResults)
      .slice(-10) // Last 10 tests
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .map(test => ({
        date: new Date(test.date).toISOString(),
        difficulty: this.adaptiveState.currentDifficulty,
        successRate: test.score / (test.questions?.length || 0)
      }));
  }

  /**
   * Gets the date of the most recent test session
   */
  public getLastSessionDate(): string {
    if (Object.keys(this.testResults).length === 0) {
      return new Date().toISOString(); // Default to current date if no tests
    }

    // Sort tests by date descending and get the most recent
    const mostRecentTest = Object.values(this.testResults)
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0];

    return new Date(mostRecentTest.date).toISOString();
  }

  // Add a new method for getting enhanced performance data
  public getEnhancedCategoryStrengths(): Record<string, EnhancedCategoryPerformance> {
    const strengths: Record<string, EnhancedCategoryPerformance> = {};
    
    Object.entries(this.adaptiveState.categoryStrengths).forEach(([category, performance]) => {
      strengths[category] = {
        category: performance.category,
        correctCount: performance.correctCount,
        totalCount: performance.totalCount,
        averageTime: performance.averageTime,
        lastAttempted: performance.lastAttempted,
        improvement: this.determineImprovement(Object.values(this.testResults)),
        successRate: performance.correctCount / Math.max(1, performance.totalCount),
        difficultyLevel: this.getRecommendedDifficulty()
      };
    });

    return strengths;
  }

  // Add missing methods
  public getSuggestedPracticeFrequency(): string {
    const recentTests = Object.values(this.testResults)
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      .slice(0, 10); // Look at last 10 tests

    if (recentTests.length === 0) {
      return 'daily'; // Default suggestion for new users
    }

    const averageScore = recentTests.reduce((sum, test) => sum + test.percentage, 0) / recentTests.length;
    const trend = this.determineImprovement(recentTests);

    // Determine frequency based on performance and trend
    if (averageScore < 60) {
      return 'daily';
    } else if (averageScore < 75) {
      return trend === 'improving' ? 'every other day' : 'daily';
    } else if (averageScore < 85) {
      return trend === 'improving' ? 'twice a week' : 'every other day';
    } else {
      return trend === 'improving' ? 'weekly' : 'twice a week';
    }
  }

  public determineImprovement(recentTests: TestResult[]): ImprovementStatus {
    if (recentTests.length < 2) {
      return 'steady';
    }

    const recentScores = recentTests
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      .map(test => test.percentage);

    // Calculate average change between consecutive scores
    let totalChange = 0;
    for (let i = 0; i < recentScores.length - 1; i++) {
      totalChange += recentScores[i] - recentScores[i + 1];
    }
    const averageChange = totalChange / (recentScores.length - 1);

    // Determine trend based on average change
    if (averageChange > 2) { // More than 2% improvement on average
      return 'improving';
    } else if (averageChange < -2) { // More than 2% decline on average
      return 'declining';
    } else {
      return 'steady';
    }
  }
}