import { db, auth } from '../../firebase';
import { 
  doc, 
  getDoc, 
  setDoc, 
  updateDoc,
  onSnapshot,
  Unsubscribe 
} from 'firebase/firestore';
import { 
  User, 
  UserProfile 
} from '../quiz/types';
import { logger } from '../logger';

export const createUserInFirestore = async (
  uid: string, 
  email: string
): Promise<void> => {
  const userRef = doc(db, 'users', uid);
  const initialProfile: UserProfile = {
      name: '',
      examDate: '',
      studyGoal: '',
      onboardingCompleted: false,
      lastUpdated: ''
  };
  
  try {
    await setDoc(userRef, {
      email: email,
      profile: initialProfile,
      createdAt: new Date().toISOString(),
      subscriptionStatus: 'inactive',
      quizzes: {},
      testResults: {},
      wrongAnswers: []
    });
  } catch (error) {
    console.error('Error creating user in Firestore:', error);
    throw new Error('Failed to create user profile');
  }
};

export const loadUserData = async (): Promise<User | null> => {
  try {
    const user = auth.currentUser;
    if (!user) {
      console.log('No authenticated user');
      return null;
    }

    const userDoc = await getDoc(doc(db, 'users', user.uid));
    if (!userDoc.exists()) {
      console.log('No user document found');
      return null;
    }

    const userData = userDoc.data();
    
    // Debug logs
    console.log('Raw Firebase Data:', {
      testResults: userData.testResults?.length || 0,
      wrongAnswers: userData.wrongAnswers?.length || 0,
      profile: userData.profile ? 'exists' : 'null',
      quizProgress: userData.quizProgress ? 'exists' : 'null'
    });

    const userInstance = new User({
      id: user.uid,
      email: user.email || '',
      profile: userData.profile || null,
      quizProgress: userData.quizProgress || null,
      testResults: userData.testResults || {},
      wrongAnswers: userData.wrongAnswers || [],
      subscriptionStatus: userData.subscriptionStatus || 'inactive',
      adaptiveLearning: userData.adaptiveLearning,
      stripeCustomerId: userData.stripeCustomerId,
      subscriptionId: userData.subscriptionId,
      quizzes: userData.quizzes || {}
    });

    // Debug logs
    console.log('User Instance Data:', {
      testResults: userInstance.testResults?.length || 0,
      wrongAnswers: userInstance.wrongAnswers?.length || 0,
      totalWrongAnswers: userInstance.totalWrongAnswers?.length || 0
    });

    return userInstance;
  } catch (error) {
    console.error('Error loading user data:', error);
    return null;
  }
};

export const updateUserProfile = async (
  userId: string,
  profile: Partial<UserProfile>
): Promise<void> => {
  if (!userId) throw new Error('No user ID provided');

  const userDocRef = doc(db, 'users', userId);
  try {
    // Get current profile first
    const userDoc = await getDoc(userDocRef);
    if (!userDoc.exists()) {
      throw new Error('User document not found');
    }

    const currentProfile = userDoc.data().profile || {};
    
    // Merge current profile with updates
    await updateDoc(userDocRef, {
      profile: {
        ...currentProfile,
        ...profile,
        lastUpdated: new Date().toISOString()
      }
    });
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw new Error('Failed to update user profile');
  }
};

export const loadUserProfile = async (
  userId: string
): Promise<UserProfile | null> => {
  if (!userId) throw new Error('No user ID provided');

  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    
    if (!userDoc.exists()) {
      console.log('No user document found');
      return null;
    }
    
    const data = userDoc.data();
    return data?.profile || null;
  } catch (error) {
    console.error('Error loading user profile:', error);
    throw new Error('Failed to load user profile');
  }
};

export const checkSubscriptionStatus = async (
  userId: string
): Promise<boolean> => {
  if (!userId) {
    logger.warn('No user ID provided', 'User ID:', false);
    return false;
  }

  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    
    if (!userDoc.exists()) {
      logger.warn('User document not found', 'User ID:', userId);
      return false;
    }
    
    const userData = userDoc.data();
    const isSubscribed = userData.subscriptionStatus === 'active' || userData.subscriptionStatus === 'trialing';
    logger.info('Subscription active.', 'Checking status:', isSubscribed);
    return isSubscribed;
  } catch (error) {
    console.error('Error checking subscription status:', error);
    return false;
  }
};

export const setupUserStatusListener = (
  userId: string,
  onUpdate: (isSubscribed: boolean) => void
): Unsubscribe => {
  const userDocRef = doc(db, 'users', userId);
  
  return onSnapshot(userDocRef, 
    (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        const isSubscribed = userData.subscriptionStatus === 'active' || userData.subscriptionStatus === 'trialing';
        onUpdate(isSubscribed);
      } else {
        onUpdate(false);
      }
    }, 
    (error) => {
      console.error("Error listening to user status:", error);
      onUpdate(false);
    }
  );
};

// Helper function to get current user ID safely
export const getCurrentUserId = (): string => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');
  return user.uid;
}; 