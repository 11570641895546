import React from 'react';
import { Question } from '../../utils/quiz/types';
import { CheckCircle, XCircle, BookOpen, ArrowRight } from 'lucide-react';

interface ResultDisplayProps {
  question: Question;
  selectedAnswer: string;
  currentExplanation: string;
  handleExplanationChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSaveAndContinue: () => void;
  showContinueButton: boolean;
  continueButtonText: string;
}

export const ResultDisplay: React.FC<ResultDisplayProps> = ({
  question,
  selectedAnswer,
  currentExplanation,
  handleExplanationChange,
  handleSaveAndContinue,
  showContinueButton,
  continueButtonText
}) => {
  const isCorrect = selectedAnswer === question.correct_answer;
  
  return (
    <div className="mt-4">
      {/* Result Status Card */}
      <div className={`rounded-lg p-6 shadow-sm ${
        isCorrect 
          ? 'bg-green-50 dark:bg-green-900/30 border border-green-200 dark:border-green-800' 
          : 'bg-white dark:bg-gray-800'
      }`}>
        <div className="flex items-start space-x-4">
          {isCorrect ? (
            <CheckCircle className="w-6 h-6 text-green-500 flex-shrink-0" />
          ) : (
            <XCircle className="w-6 h-6 text-red-500 flex-shrink-0" />
          )}
          <div className="flex-1 space-y-4">
            {/* Result Header */}
            <div>
              <h3 className={`text-lg font-semibold ${
                isCorrect ? 'text-green-700 dark:text-green-300' : 'text-red-700 dark:text-red-300'
              }`}>
                {isCorrect ? 'Correct!' : 'Incorrect'}
              </h3>
              {!isCorrect && (
                <p className="text-gray-600 dark:text-gray-300 mt-1">
                  The correct answer is: <span className="font-medium">{question.correct_answer}</span>
                </p>
              )}
            </div>

            {/* Explanation Section */}
            {(isCorrect || question.explanation) && (
              <div className="bg-white dark:bg-gray-700/50 rounded-lg p-4 border border-gray-100 dark:border-gray-600">
                <div className="flex items-center space-x-2 mb-2">
                  <BookOpen className="w-4 h-4 text-gray-400" />
                  <span className="font-medium text-gray-700 dark:text-gray-200">Explanation</span>
                </div>
                <p className="text-gray-600 dark:text-gray-300">
                  {question.explanation}
                </p>
              </div>
            )}

            {/* Notes Section - Only show for incorrect answers */}
            {!isCorrect && (
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <span className="font-medium text-blue-600 dark:text-blue-400">
                    Create a note to help remember this concept:
                  </span>
                </div>
                <textarea
                  value={currentExplanation}
                  onChange={handleExplanationChange}
                  placeholder="Write your own notes about this question and answer..."
                  rows={4}
                  className="w-full p-4 bg-white dark:bg-gray-700 text-gray-800 dark:text-white rounded-lg border border-gray-200 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                />
              </div>
            )}

            {/* Continue Button */}
            {showContinueButton && (
              <div className="flex justify-end pt-2">
                <button 
                  onClick={handleSaveAndContinue}
                  className={`inline-flex items-center px-6 py-2.5 rounded-lg font-medium text-white transition-colors duration-200 ${
                    isCorrect 
                      ? 'bg-green-500 hover:bg-green-600' 
                      : 'bg-blue-500 hover:bg-blue-600'
                  }`}
                >
                  <span>{continueButtonText}</span>
                  <ArrowRight className="ml-2 w-4 h-4" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}; 