import React from 'react';
import { Question } from '../../utils/quiz/types';
import { AdaptiveState } from '../../models/AdaptiveState';
import { Clock, Target, TrendingUp, BookOpen, AlertCircle } from 'lucide-react';

interface QuestionDisplayProps {
  question: Question;
  showHint: boolean;
  setShowHint: (show: boolean) => void;
  adaptiveState?: AdaptiveState;
  timeSpent?: number;
}

const formatTime = (seconds: number): string => {
  if (seconds < 60) return `${seconds}s`;
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return remainingSeconds === 0 ? 
    `${minutes}m` : 
    `${minutes}m ${remainingSeconds}s`;
};

export const QuestionDisplay: React.FC<QuestionDisplayProps> = ({
  question,
  showHint,
  setShowHint,
  adaptiveState,
  timeSpent
}) => (
  <div className="space-y-4">
    {/* Question Header with Stats */}
    <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 p-4 bg-white dark:bg-gray-800 rounded-lg shadow-sm">
      {/* Category and Difficulty */}
      <div className="flex items-center space-x-4">
        <div className="flex flex-col">
          <span className="text-sm text-gray-500 dark:text-gray-400">Category</span>
          <span className="font-medium text-gray-900 dark:text-gray-100">{question.category}</span>
        </div>
        {question.difficulty_level && (
          <div className="flex flex-col">
            <span className="text-sm text-gray-500 dark:text-gray-400">Difficulty</span>
            <div className="flex items-center space-x-2">
              <div className="h-2 w-24 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-blue-500 dark:bg-blue-600 transition-all duration-500"
                  style={{ width: `${(Number(question.difficulty_level) * 100)}%` }}
                />
              </div>
              <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
                {Math.round(Number(question.difficulty_level) * 10)}/10
              </span>
            </div>
          </div>
        )}
      </div>

      {/* Time Management */}
      {timeSpent !== undefined && (
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <Clock className="w-4 h-4 text-gray-400" />
            <div className="flex flex-col">
              <span className="text-sm text-gray-500 dark:text-gray-400">Time</span>
              <span className={`font-medium ${
                timeSpent > (question.recommendedTime || 120) 
                  ? 'text-yellow-600 dark:text-yellow-400' 
                  : 'text-gray-900 dark:text-gray-100'
              }`}>
                {formatTime(timeSpent)}
              </span>
            </div>
          </div>
          {question.recommendedTime && (
            <div className="flex items-center space-x-2">
              <Target className="w-4 h-4 text-gray-400" />
              <div className="flex flex-col">
                <span className="text-sm text-gray-500 dark:text-gray-400">Target</span>
                <span className="font-medium text-gray-900 dark:text-gray-100">
                  {formatTime(question.recommendedTime)}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>

    {/* Adaptive Insights Panel */}
    {adaptiveState?.insights && (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {/* Performance Trend */}
        {adaptiveState.insights.performanceTrend && (
          <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm">
            <div className="flex items-center space-x-2 mb-2">
              <TrendingUp className="w-4 h-4 text-gray-400" />
              <span className="text-sm text-gray-500 dark:text-gray-400">Learning Trend</span>
            </div>
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
              ${adaptiveState.insights.performanceTrend === 'improving' 
                ? 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300' 
                : adaptiveState.insights.performanceTrend === 'declining' 
                  ? 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-300' 
                  : 'bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300'
              }`}>
              {adaptiveState.insights.performanceTrend === 'improving' ? '↗ Improving' :
               adaptiveState.insights.performanceTrend === 'declining' ? '↘ Needs Focus' :
               '→ Steady'}
            </span>
          </div>
        )}
        
        {/* Category Mastery */}
        {adaptiveState.categoryStrengths && question.category && adaptiveState.categoryStrengths[question.category] && (
          <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm">
            <div className="flex items-center space-x-2 mb-2">
              <BookOpen className="w-4 h-4 text-gray-400" />
              <span className="text-sm text-gray-500 dark:text-gray-400">Category Mastery</span>
            </div>
            <div className="flex items-center space-x-2">
              <div className="h-2 w-full bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-green-500 dark:bg-green-600 transition-all duration-500"
                  style={{ 
                    width: `${(adaptiveState.categoryStrengths[question.category].correctCount / 
                      (adaptiveState.categoryStrengths[question.category].totalCount || 1)) * 100}%` 
                  }}
                />
              </div>
              <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
                {Math.round((adaptiveState.categoryStrengths[question.category].correctCount / 
                  (adaptiveState.categoryStrengths[question.category].totalCount || 1)) * 100)}%
              </span>
            </div>
          </div>
        )}
      </div>
    )}

    {/* Question Text */}
    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
      <p className="text-lg text-gray-900 dark:text-white leading-relaxed">
        {question.question}
      </p>
    </div>
    
    {/* Hint Section */}
    {question.hint && question.hint.trim() !== "" && (
      <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm mt-4">
        <div className="flex items-start space-x-3">
          <AlertCircle className="w-5 h-5 text-blue-500 flex-shrink-0" />
          <div className="flex-1 space-y-2">
            <button 
              className="text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300 font-medium transition-colors"
              onClick={() => setShowHint(!showHint)}
            >
              {showHint ? 'Hide Hint' : 'Show Hint'}
            </button>
            {showHint && (
              <p className="text-gray-600 dark:text-gray-300">
                {question.hint}
              </p>
            )}
          </div>
        </div>
      </div>
    )}
  </div>
); 