import React, { useEffect, useCallback, useRef } from 'react';
import { QuizProgress } from '../utils/quiz/types';
import QuizComponent from './QuizComponent';
import QuizErrorBoundary from './QuizErrorBoundary';
import { useQuizContext } from './quiz/state/QuizContext';
import { useQuiz } from '../hooks/useQuiz';
import { logger } from '../utils/logger';

interface QuizContainerProps {
  quizId: string;
  initialQuizProgress: QuizProgress;
}

const QuizContainerContent: React.FC<QuizContainerProps> = ({
  initialQuizProgress,
}) => {
  const { state, dispatch, saveAndContinue } = useQuizContext();
  const { adaptiveEngine } = useQuiz();
  const questionContainerRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    if (questionContainerRef.current) {
      questionContainerRef.current.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleAnswer = useCallback((answer: string) => {
    const timeSpent = Date.now() - (state.questionStartTime || Date.now());
    
    dispatch({ 
      type: 'SET_ANSWER_WITH_TIME', 
      payload: {
        answer,
        timeSpent,
        questionId: state.currentQuestion?.id ?? '0'
      }
    });
  }, [dispatch, state.questionStartTime, state.currentQuestion]);

  const handleSaveAndContinue = useCallback(async () => {
    await saveAndContinue();
    scrollToTop();
  }, [saveAndContinue]);

  const handleExplanationChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch({ type: 'SET_EXPLANATION', payload: e.target.value });
  }, [dispatch]);

  // Initialize quiz state
  useEffect(() => {
    if (!state.progress && initialQuizProgress) {
      // Initialize with adaptive engine if available
      const progressWithAdaptive = {
        ...initialQuizProgress,
        adaptiveState: {
          ...initialQuizProgress.adaptiveState,
          engine: adaptiveEngine
        }
      };
      
      dispatch({ type: 'SET_PROGRESS', payload: progressWithAdaptive });
    }
  }, [initialQuizProgress, state.progress, dispatch, adaptiveEngine]);

  // Add initialization effect for adaptive engine
  useEffect(() => {
    if (adaptiveEngine && state.progress && !state.progress.adaptiveState?.engine) {
      logger.debug('QuizContainer', 'Initializing adaptive engine state');
      dispatch({
        type: 'INITIALIZE_ADAPTIVE_ENGINE_WITH_STATE',
        payload: {
          engine: adaptiveEngine,
          preservedProgress: state.progress
        }
      });
    }
  }, [adaptiveEngine, state.progress, dispatch]);

  // Add time tracking for questions
  useEffect(() => {
    if (state.progress && !state.questionStartTime) {
      dispatch({ 
        type: 'UPDATE_QUESTION_START_TIME', 
        payload: Date.now() 
      });
    }
  }, [state.progress, state.questionStartTime, dispatch]);

  // Show loading state if we don't have progress yet
  if (!state.progress) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <QuizErrorBoundary>
      <div className="quiz-container" ref={questionContainerRef}>
        <QuizComponent
          onAnswerSelect={handleAnswer}
          showHint={state.showHint}
          setShowHint={() => dispatch({ type: 'TOGGLE_HINT' })}
          selectedAnswer={state.selectedAnswer}
          showResult={state.showResult}
          currentExplanation={state.currentExplanation ?? ''}
          handleExplanationChange={handleExplanationChange}
          handleSaveAndContinue={handleSaveAndContinue}
          showContinueButton={state.showResult}
          continueButtonText={(() => {
            const totalQuestions = state.progress.questionIds?.length ?? 0;
            const currentIndex = state.progress.currentQuestionIndex ?? 0;
            const isLastQuestion = currentIndex >= totalQuestions - 1;
            
            return isLastQuestion ? "Complete Quiz" : "Next Question";
          })()}
        />
      </div>
    </QuizErrorBoundary>
  );
};

export const QuizContainer: React.FC<QuizContainerProps> = (props) => {
  return <QuizContainerContent {...props} />;
};

export default React.memo(QuizContainer);

