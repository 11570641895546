export enum TestType {
  LCSW = 'lcsw',
  LCSW_FREE = 'lcsw_free',
  LBSW = 'lbsw',
  LMSW = 'lmsw'
}

export enum QuizMode {
  PRACTICE = 'practice',
  FULL = 'full',
  CATEGORY = 'category'
} 