import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from './utils/Analytics';
import LoadingSpinner from './components/LoadingSpinner';
import ErrorBoundary from './components/ErrorBoundary';
import { FiUser, FiCreditCard, FiLock, FiMessageSquare, FiLogOut, FiShoppingCart } from 'react-icons/fi';
import { getStripeCustomerPortal } from './services/stripe';

const UserSettings: React.FC = () => {
  const { currentUser, userData, isLoading, logOut } = useAuth();
  const navigate = useNavigate();
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [isSendingFeedback, setIsSendingFeedback] = useState(false);

  useEffect(() => {
    if (currentUser) {
      logAnalyticsEvent(ANALYTICS_EVENTS.SETTINGS_PAGE_VIEWED, {
        userId: currentUser.uid,
        isSubscribed: userData?.subscriptionStatus === 'active' || userData?.subscriptionStatus === 'trialing',
        timestamp: Date.now()
      });
    }
  }, [currentUser, userData]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  const handleUpdateProfile = () => {
    logAnalyticsEvent(ANALYTICS_EVENTS.SETTINGS_UPDATE_PROFILE, {
      userId: currentUser?.uid || '',
      timestamp: Date.now()
    });
    navigate('/onboarding', { 
      state: { 
        isUpdate: true,
        existingProfile: userData?.profile || null
      } 
    });
  };

  const handleManageSubscription = async () => {
    if (!userData?.stripeCustomerId) {
      console.error('No Stripe customer ID found');
      return;
    }

    try {
      logAnalyticsEvent(ANALYTICS_EVENTS.SETTINGS_MANAGE_SUBSCRIPTION, {
        userId: currentUser?.uid || '',
        currentStatus: userData?.subscriptionStatus || 'inactive',
        timestamp: Date.now()
      });

      // Get secure portal URL from our backend
      const portalUrl = await getStripeCustomerPortal(userData.stripeCustomerId);
      
      // Redirect to the secure portal URL
      window.location.href = portalUrl;
    } catch (error) {
      console.error('Error accessing subscription management:', error);
      // TODO: Show error notification to user
    }
  };

  const handleSubscribe = () => {
    logAnalyticsEvent(ANALYTICS_EVENTS.SUBSCRIPTION_CLICKED, {
      subscriptionStatus: userData?.subscriptionStatus || null,
      isLoggedIn: !!currentUser,
      source: 'settings_page',
      timestamp: Date.now()
    });
    navigate('/pricing');
  };

  const handleChangePassword = () => {
    logAnalyticsEvent(ANALYTICS_EVENTS.SETTINGS_CHANGE_PASSWORD, {
      userId: currentUser?.uid || '',
      timestamp: Date.now()
    });
    navigate('/forgot-password');
  };

  const handleSendFeedback = async () => {
    if (!feedbackMessage.trim()) return;
    
    setIsSendingFeedback(true);
    try {
      logAnalyticsEvent(ANALYTICS_EVENTS.SETTINGS_SEND_FEEDBACK, {
        userId: currentUser?.uid || '',
        feedbackLength: feedbackMessage.length,
        timestamp: Date.now()
      });
      setFeedbackMessage('');
      // TODO: Implement feedback submission
    } catch (error) {
      console.error('Error sending feedback:', error);
    } finally {
      setIsSendingFeedback(false);
    }
  };

  const handleLogOut = async () => {
    try {
      logAnalyticsEvent(ANALYTICS_EVENTS.SETTINGS_USER_LOGOUT, {
        userId: currentUser?.uid || '',
        timestamp: Date.now()
      });
      await logOut();
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <ErrorBoundary>
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800">
        <div className="max-w-lg mx-auto px-4 py-6 sm:py-12">
          <h1 className="text-2xl sm:text-3xl font-bold mb-6 text-gray-800 dark:text-gray-100">
            Account Settings
          </h1>

          <div className="space-y-4">
            {/* Profile Section */}
            <div className="bg-white dark:bg-gray-800 rounded-2xl p-4 sm:p-6 shadow-sm hover:shadow-md transition-all duration-200">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
                <div className="flex items-center gap-4">
                  <div className="p-3 bg-blue-50 dark:bg-blue-900/30 rounded-xl">
                    <FiUser className="w-6 h-6 text-blue-500 dark:text-blue-400" />
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                      Profile Information
                    </h2>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Manage your personal information
                    </p>
                  </div>
                </div>
                <button
                  onClick={handleUpdateProfile}
                  className="w-full sm:w-auto px-4 py-2.5 text-sm font-medium text-blue-600 dark:text-blue-400 
                    hover:bg-blue-50 dark:hover:bg-blue-900/30 rounded-xl transition-colors duration-200
                    border border-blue-200 dark:border-blue-800"
                >
                  Edit Profile
                </button>
              </div>
            </div>

            {/* Subscription Section */}
            <div className="bg-white dark:bg-gray-800 rounded-2xl p-4 sm:p-6 shadow-sm hover:shadow-md transition-all duration-200">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
                <div className="flex items-center gap-4">
                  <div className={`p-3 rounded-xl ${
                    userData?.subscriptionStatus === 'active' || userData?.subscriptionStatus === 'trialing'
                      ? 'bg-green-50 dark:bg-green-900/30' 
                      : 'bg-blue-50 dark:bg-blue-900/30'
                  }`}>
                    {userData?.subscriptionStatus === 'active' || userData?.subscriptionStatus === 'trialing' ? (
                      <FiCreditCard className="w-6 h-6 text-green-500 dark:text-green-400" />
                    ) : (
                      <FiShoppingCart className="w-6 h-6 text-blue-500 dark:text-blue-400" />
                    )}
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                      {userData?.subscriptionStatus === 'active' || userData?.subscriptionStatus === 'trialing' ? 'Subscription' : 'Subscribe'}
                    </h2>
                    <div className="flex items-center gap-2 mt-1">
                      <span className="text-sm text-gray-500 dark:text-gray-400">Status:</span>
                      {userData?.subscriptionStatus === 'active' ? (
                        <span className="text-sm font-medium px-2 py-1 bg-green-50 dark:bg-green-900/30 text-green-600 dark:text-green-400 rounded-lg">
                          Active
                        </span>
                      ) : userData?.subscriptionStatus === 'trialing' ? (
                        <span className="text-sm font-medium px-2 py-1 bg-green-50 dark:bg-green-900/30 text-green-600 dark:text-green-400 rounded-lg">
                          Trial
                        </span>
                      ) : (
                        <span className="text-sm font-medium px-2 py-1 bg-gray-50 dark:bg-gray-700 text-gray-600 dark:text-gray-400 rounded-lg">
                          Inactive
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {userData?.subscriptionStatus === 'active' || userData?.subscriptionStatus === 'trialing' ? (
                  <button
                    onClick={handleManageSubscription}
                    className="w-full sm:w-auto px-4 py-2.5 bg-green-500 hover:bg-green-600 text-white 
                      text-sm font-medium rounded-xl transition-colors duration-200 min-w-[140px]"
                  >
                    Manage Subscription
                  </button>
                ) : (
                  <button
                    onClick={handleSubscribe}
                    className="w-full sm:w-auto px-4 py-2.5 bg-blue-500 hover:bg-blue-600 text-white 
                      text-sm font-medium rounded-xl transition-colors duration-200 flex items-center 
                      justify-center gap-2 min-w-[140px]"
                  >
                    <FiShoppingCart className="w-5 h-5" />
                    <span>Subscribe Now</span>
                  </button>
                )}
              </div>
            </div>

            {/* Password Section */}
            <div className="bg-white dark:bg-gray-800 rounded-2xl p-4 sm:p-6 shadow-sm hover:shadow-md transition-all duration-200">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
                <div className="flex items-center gap-4">
                  <div className="p-3 bg-purple-50 dark:bg-purple-900/30 rounded-xl">
                    <FiLock className="w-6 h-6 text-purple-500 dark:text-purple-400" />
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                      Password
                    </h2>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Update your password
                    </p>
                  </div>
                </div>
                <button
                  onClick={handleChangePassword}
                  className="w-full sm:w-auto px-4 py-2.5 text-sm font-medium text-purple-600 dark:text-purple-400 
                    hover:bg-purple-50 dark:hover:bg-purple-900/30 rounded-xl transition-colors duration-200
                    border border-purple-200 dark:border-purple-800"
                >
                  Change Password
                </button>
              </div>
            </div>

            {/* Help Section */}
            <div className="bg-white dark:bg-gray-800 rounded-2xl p-4 sm:p-6 shadow-sm hover:shadow-md transition-all duration-200">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
                <div className="flex items-center gap-4">
                  <div className="p-3 bg-teal-50 dark:bg-teal-900/30 rounded-xl">
                    <FiMessageSquare className="w-6 h-6 text-teal-500 dark:text-teal-400" />
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                      Need Help?
                    </h2>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      We&apos;re here to assist you
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-end gap-1">
                  <a 
                    href={`mailto:support@socialworktestprepacademy.com`}
                    className="w-full sm:w-auto px-4 py-2.5 text-sm font-medium text-teal-600 dark:text-teal-400 
                      hover:bg-teal-50 dark:hover:bg-teal-900/30 rounded-xl transition-colors duration-200
                      border border-teal-200 dark:border-teal-800 text-center"
                    onClick={() => {
                      logAnalyticsEvent(ANALYTICS_EVENTS.SETTINGS_SUPPORT_CONTACT_CLICKED, {
                        userId: currentUser?.uid || '',
                        method: 'email',
                        timestamp: Date.now()
                      });
                    }}
                  >
                    Email Support
                  </a>
                  <span className="text-xs text-gray-500 dark:text-gray-400">
                    support@socialworktestprepacademy.com
                  </span>
                </div>
              </div>
            </div>

            {/* Logout Section */}
            <div className="bg-white dark:bg-gray-800 rounded-2xl p-4 sm:p-6 shadow-sm hover:shadow-md transition-all duration-200">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
                <div className="flex items-center gap-4">
                  <div className="p-3 bg-red-50 dark:bg-red-900/30 rounded-xl">
                    <FiLogOut className="w-6 h-6 text-red-500 dark:text-red-400" />
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                      Logout
                    </h2>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Sign out of your account
                    </p>
                  </div>
                </div>
                <button
                  onClick={handleLogOut}
                  className="w-full sm:w-auto px-4 py-2.5 text-sm font-medium text-red-600 dark:text-red-400 
                    hover:bg-red-50 dark:hover:bg-red-900/30 rounded-xl transition-colors duration-200
                    border border-red-200 dark:border-red-800"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default UserSettings; 