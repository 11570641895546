import { AdaptiveMetrics, AdaptiveState, CategoryMetrics } from '@/models/AdaptiveState';
import { AdaptiveLearningEngine } from '../adaptiveLearning';
import { 
  Question, 
  QuizProgress, 
  QuizSummary,
  TestResult 
} from './types';
import { TestType, QuizMode, QuizType } from '../../models/SharedTypes';
import { QuestionTiming, calculateAverageResponseTime } from './../../components/dashboard/utils';
import { logger } from '../../utils/logger';
import { nanoid } from 'nanoid';

const generateQuizId = (): string => {
  // Use nanoid for unique, URL-friendly IDs
  // Format: timestamp-random to ensure uniqueness and temporal ordering
  return `${Date.now()}-${nanoid(8)}`;
};

export const initializeNewQuiz = (
  questions: Question[],
  testType: TestType,
  mode: QuizMode,
  category?: string,
  engine?: AdaptiveLearningEngine
): QuizProgress => {
  logger.debug('Quiz', 'Initializing new quiz', {
    questionCount: questions.length,
    testType,
    mode,
    category,
    hasEngine: !!engine
  });

  // Validate input
  if (!questions || questions.length === 0) {
    logger.error('Quiz', 'Cannot initialize quiz without questions');
    throw new Error('Cannot initialize quiz without questions');
  }

  if (!testType || !mode) {
    logger.error('Quiz', 'Missing required quiz parameters', {
      hasTestType: !!testType,
      hasMode: !!mode
    });
    throw new Error('Missing required quiz parameters');
  }

  // Generate a unique quiz ID
  const quizId = generateQuizId();

  // Create initial quiz progress
  const adaptiveState: AdaptiveState = {
    engine: engine!,
    currentDifficulty: 5,
    categoryStrengths: {},
    consecutiveCorrect: 0,
    consecutiveIncorrect: 0,
    totalQuestionsAttempted: 0,
    recentlyAskedQuestions: [],
    insights: {
      weakCategories: [],
      recommendedDifficulty: 5,
      suggestedPracticeFrequency: 'daily',
      performanceTrend: 'steady',
      timeManagement: {
        averageQuestionTime: 0,
        recommendedTimePerQuestion: 90,
        timeManagementAdvice: 'Take your time to read each question carefully'
      }
    }
  };

  const progress: QuizProgress = {
    quizId,
    currentQuestionIndex: 0,
    score: 0,
    questions,
    questionIds: questions.map(q => q.id),
    wrongAnswers: [],
    date: new Date().toISOString(),
    category: category || 'unknown',
    type: QuizType.SAMPLE,
    testType,
    mode,
    adaptiveState,
    lastUpdated: new Date().toISOString()
  };

  logger.debug('Quiz', 'Created quiz progress', {
    quizId,
    questionCount: questions.length,
    hasQuestionIds: !!progress.questionIds,
    questionIdsLength: progress.questionIds?.length,
    sampleQuestionIds: progress.questionIds?.slice(0, 3)
  });

  return progress;
};

export const calculateQuizScore = (progress: QuizProgress): number => {
  if (!progress.questions?.length) return 0;
  
  const answeredQuestions = progress.questions?.filter(q => q.selected_answer !== null) ?? [];
  const correctAnswers = answeredQuestions.filter(q => 
    q.selected_answer === q.correct_answer
  );
  
  return correctAnswers.length;
};

export const processQuizAnswer = (
  progress: QuizProgress,
  questionIndex: number,
  selectedAnswer: string
): { 
  updatedProgress: QuizProgress,
  isCorrect: boolean 
} => {
  const question = progress.questions?.[questionIndex];
  if (!question) throw new Error('Invalid question index');

  const isCorrect = selectedAnswer === question.correct_answer;
  const updatedQuestions = progress.questions?.map((q, idx) =>
    idx === questionIndex ? { ...q, selected_answer: selectedAnswer } : q
  );

  const updatedWrongAnswers = [...(progress.wrongAnswers ?? [])];
  if (!isCorrect) {
    updatedWrongAnswers.push({
      question,
      explanation: question.explanation || '',
      date: new Date().toISOString(),
      isReviewed: false
    });
  }

  const updatedProgress: QuizProgress = {
    ...progress,
    questions: updatedQuestions,
    questionIds: progress.questionIds || updatedQuestions?.map(q => q.id),
    wrongAnswers: updatedWrongAnswers,
    score: calculateQuizScore({ ...progress, questions: updatedQuestions })
  };

  return { updatedProgress, isCorrect };
};

export const isQuizComplete = (progress: QuizProgress): boolean => {
  const totalQuestions = progress.questionIds?.length ?? 0;
  return (progress.currentQuestionIndex ?? 0) >= totalQuestions - 1;
};

export const getQuizSummary = (progress: QuizProgress): QuizSummary => {
  const totalQuestions = progress.questionIds?.length ?? 0;
  const answeredQuestions = progress.questions?.filter(q => q.selected_answer !== null) ?? [];
  const correctAnswers = answeredQuestions.filter(q => 
    q.selected_answer === q.correct_answer
  );

  const summary: QuizSummary = {
    totalQuestions,
    answeredQuestions: answeredQuestions.length,
    correctAnswers: correctAnswers.length,
    wrongAnswers: progress.wrongAnswers?.length ?? 0,
    score: progress.score ?? 0,
    completionPercentage: (correctAnswers.length / totalQuestions) * 100,
    categoryBreakdown: getCategoryBreakdown(progress) ?? {}
  };

  return summary;
};

export const getCategoryBreakdown = (progress: QuizProgress) => {
  return progress.questions?.reduce((acc, question) => {
    const category = question.category || 'Uncategorized';
    if (!acc[category]) {
      acc[category] = { total: 0, correct: 0, incorrect: 0 };
    }
    
    acc[category].total++;
    if (question.selected_answer) {
      if (question.selected_answer === question.correct_answer) {
        acc[category].correct++;
      } else {
        acc[category].incorrect++;
      }
    }
    
    return acc;
  }, {} as Record<string, { total: number; correct: number; incorrect: number; }>);
};

// Add this to track timing for questions
export const startQuestionTimer = (): QuestionTiming => ({
  startTime: Date.now()
});

export const endQuestionTimer = (timing: QuestionTiming): number => {
  timing.endTime = Date.now();
  return (timing.endTime - timing.startTime) / 1000; // Returns seconds
};

export const prepareTestResult = (
  progress: QuizProgress,
  questionTimings?: Record<number, QuestionTiming>
): TestResult => {
  if (!progress.quizId) {
    logger.error('Quiz', 'Missing quiz ID in progress', { progress });
    throw new Error('Missing quiz ID in progress');
  }

  const summary = getQuizSummary(progress);
  const engine = progress.adaptiveState?.engine;
  
  // Generate adaptive metrics using the engine if available, otherwise create default metrics
  const adaptiveMetrics: AdaptiveMetrics = engine 
    ? engine.generateAdaptiveMetrics()
    : {
        startingDifficulty: progress.adaptiveState?.currentDifficulty ?? 5,
        endingDifficulty: progress.adaptiveState?.currentDifficulty ?? 5,
        averageResponseTime: calculateAverageResponseTime(questionTimings),
        categoryPerformance: generateCategoryPerformance(progress, questionTimings),
        overallSuccessRate: summary.correctAnswers / summary.totalQuestions,
        totalQuestionsAttempted: summary.totalQuestions,
        currentStreak: progress.adaptiveState?.consecutiveCorrect ?? 0,
        longestStreak: progress.adaptiveState?.consecutiveCorrect ?? 0,
        lastSessionDate: progress.date ?? new Date().toISOString(),
        weeklyActivityStreak: 1,
        studyTimeThisWeek: 1,
        estimatedExamReadiness: calculateEstimatedReadiness(summary),
        completedCategories: getCompletedCategories(progress),
        mastery: {
          beginner: [],
          intermediate: [],
          advanced: []
        },
        recentPerformance: [{
          date: progress.date || new Date().toISOString(),
          score: summary.correctAnswers,
          category: progress.category || 'unknown',
          questionsAttempted: summary.totalQuestions
        }],
        preferredTimes: [],
        difficultyProgression: [{
          date: progress.date || new Date().toISOString(),
          difficulty: progress.adaptiveState?.currentDifficulty ?? 5,
          successRate: summary.correctAnswers / summary.totalQuestions
        }]
      };

  // Ensure we have a valid quiz ID
  const quizId = String(progress.quizId);
  
  logger.debug('Quiz', 'Preparing test result', {
    quizId,
    hasQuestions: !!progress.questions,
    questionCount: progress.questions?.length ?? 0,
    hasQuestionIds: !!progress.questionIds,
    questionIdsCount: progress.questionIds?.length ?? 0
  });

  const testResult: TestResult = {
    id: quizId,
    questionIds: progress.questionIds ?? [],
    type: progress.type ?? QuizType.SAMPLE,
    date: progress.date ?? new Date().toISOString(),
    score: progress.score ?? 0,
    totalQuestions: summary.totalQuestions,
    percentage: summary.completionPercentage,
    questions: progress.questions ?? [],
    wrongAnswers: progress.wrongAnswers ?? [],
    adaptiveMetrics,
    category: progress.category || 'unknown',
    timeTaken: progress.questionTimes ? 
      Object.values(progress.questionTimes).reduce((sum, time) => sum + time, 0) : 
      0
  };

  return testResult;
};

const generateCategoryPerformance = (
  progress: QuizProgress,
  timings?: Record<number, QuestionTiming>
): Record<string, CategoryMetrics> => {
  const categoryMetrics: Record<string, CategoryMetrics> = {};
  
  progress.questions?.forEach((question, index) => {
    const category = question.category || 'uncategorized';
    if (!categoryMetrics[category]) {
      categoryMetrics[category] = {
        correct: 0,
        total: 0,
        averageTime: 0,
        successRate: 0
      };
    }
    
    categoryMetrics[category].total++;
    if (question.selected_answer === question.correct_answer) {
      categoryMetrics[category].correct++;
    }
    
    // Add timing data if available
    if (timings?.[index]?.endTime) {
      const timeSpent = ((timings[index].endTime ?? 0) - timings[index].startTime) / 1000;
      const currentTotal = categoryMetrics[category].averageTime * (categoryMetrics[category].total - 1);
      categoryMetrics[category].averageTime = (currentTotal + timeSpent) / categoryMetrics[category].total;
    }
  });
  
  // Calculate success rates
  Object.values(categoryMetrics).forEach(metric => {
    metric.successRate = metric.correct / metric.total;
  });
  
  return categoryMetrics;
};

const calculateEstimatedReadiness = (summary: ReturnType<typeof getQuizSummary>): number => {
  const baseReadiness = summary.completionPercentage / 100;
  const categoryCount = Object.keys(summary.categoryBreakdown).length;
  const categoryFactor = Math.min(categoryCount / 10, 1); // Assumes 10 categories is complete coverage
  
  return Math.round(baseReadiness * categoryFactor * 100);
};

const getCompletedCategories = (progress: QuizProgress): string[] => {
  const categoryBreakdown = getCategoryBreakdown(progress);
  return Object.entries(categoryBreakdown ?? {})
    .filter(([_, stats]) => stats.correct / stats.total >= 0.8)
    .map(([category]) => category);
}; 