import { useAuth } from '../contexts/AuthContext';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateUserProfile } from '../utils/firebase/user';
import { removeWrongAnswer, updateWrongAnswerExplanation } from '../utils/firebase/testResults';
import TestScores from './dashboard/TestScores';
import FlashCards from './dashboard/FlashCards';
import QuizActions from './dashboard/QuizActions';
import OnboardingModal from './OnboardingModal';
import { buildShareText, shareToSocial } from '../utils/shareUtils';
import { Calendar as CalendarIcon, Share2, BookOpen, Target, ChevronRight, BarChart2, Clock, Brain } from 'lucide-react';
import AdaptiveInsightsCard from './dashboard/AdaptiveInsightsCard';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from '../utils/Analytics';
import { useQuiz } from '../hooks/useQuiz';
import { logger } from '../utils/logger';
import StudyOptionsComponent from './dashboard/StudyOptionsComponent';
import { calculateExamCountdown, formatExamDate } from './dashboard/utils';
import QuizErrorBoundary from './QuizErrorBoundary';

const Dashboard: React.FC = () => {
    const { currentUser, userData, isLoading } = useAuth();
    const navigate = useNavigate();
    const renderCount = useRef(0);
    const { initializeQuizWithParams } = useQuiz();
    const pageLoadTime = useRef(Date.now());

    const [state, setState] = useState(() => ({
        isLoading: !userData,
        wrongAnswers: userData?.totalWrongAnswers ?? [],
        showOnboarding: userData?.profile?.onboardingCompleted === false,
        latestResult: userData?.latestTestResult ?? null,
        showDatePicker: false,
        selectedTab: 'study' as 'study' | 'progress' | 'practice'
    }));

    useEffect(() => {
        if (!isLoading && !currentUser) {
            logger.info('Dashboard', 'No user found, redirecting to signin');
            navigate('/signin', { replace: true, state: { from: '/dashboard' } });
            return;
        }

        // Track dashboard view
        logAnalyticsEvent(ANALYTICS_EVENTS.DASHBOARD_VIEWED, {
            userId: currentUser?.uid || '',
            isSubscribed: userData?.isSubscribed || false,
            hasOngoingQuiz: !!userData?.quizzes && Object.keys(userData.quizzes).length > 0,
            examDate: userData?.profile?.examDate,
            daysUntilExam: userData?.profile?.examDate ? calculateExamCountdown(userData.profile.examDate).daysRemaining : null,
            totalQuestionsAttempted: userData?.totalQuestionsAttempted || 0,
            studyStreak: userData?.studyStreak || 0,
            masteryLevel: userData?.masteryLevel || 0,
            timeOnPlatform: Date.now() - pageLoadTime.current
        });
    }, [currentUser, isLoading, navigate, userData]);

    // Track tab changes
    useEffect(() => {
        logAnalyticsEvent(ANALYTICS_EVENTS.DASHBOARD_INTERACTION, {
            userId: currentUser?.uid || '',
            action: 'tab_change',
            tab: state.selectedTab,
            timestamp: Date.now()
        });
    }, [state.selectedTab, currentUser?.uid]);

    const handleDateSelect = useCallback(async (date: Date) => {
        if (!currentUser) return;
        try {
            const updatedProfile = {
                ...(userData?.profile || {}),
                examDate: date.toISOString()
            };

            await updateUserProfile(currentUser.uid, updatedProfile);
            logAnalyticsEvent(ANALYTICS_EVENTS.EXAM_DATE_UPDATED, {
                userId: currentUser.uid,
                oldDate: userData?.profile?.examDate,
                newDate: date.toISOString(),
                daysUntilExam: calculateExamCountdown(date.toISOString()).daysRemaining,
                isSubscribed: userData?.isSubscribed,
                timestamp: Date.now()
            });
            
            setState(prev => ({ ...prev, showDatePicker: false }));
        } catch (error) {
            logger.error('Dashboard', 'Error updating exam date', { error });
            logAnalyticsEvent(ANALYTICS_EVENTS.ERROR_OCCURRED, {
                error_type: 'exam_date_update_error',
                error_message: error instanceof Error ? error.message : 'Unknown error',
                component: 'Dashboard',
                userId: currentUser.uid
            });
        }
    }, [currentUser, userData]);

    // Track study options interactions
    const handleStudyOptionClick = (optionType: string) => {
        logAnalyticsEvent(ANALYTICS_EVENTS.DASHBOARD_INTERACTION, {
            userId: currentUser?.uid || '',
            action: 'study_option_selected',
            optionType,
            timestamp: Date.now()
        });
    };

    // Track flashcard interactions
    const handleFlashcardInteraction = (action: 'view' | 'flip' | 'mark_complete', cardId: string) => {
        const eventName = action === 'view' 
            ? ANALYTICS_EVENTS.FLASHCARD_VIEWED 
            : action === 'flip' 
                ? ANALYTICS_EVENTS.FLASHCARD_FLIPPED 
                : ANALYTICS_EVENTS.FLASHCARD_MARKED_COMPLETE;

        logAnalyticsEvent(eventName, {
            userId: currentUser?.uid || '',
            cardId,
            timestamp: Date.now()
        });
    };

    const handleShare = useCallback(async (platform: 'x' | 'facebook' | 'linkedin', text: string) => {
        try {
            await shareToSocial(platform, text);
            logAnalyticsEvent(ANALYTICS_EVENTS.SHARE_RESULT, {
                platform,
                hasResult: !!state.latestResult,
                isSubscribed: userData?.isSubscribed
            });
        } catch (error) {
            logger.error('Dashboard', 'Error sharing to social', { error });
        }
    }, [state.latestResult, userData?.isSubscribed]);

    if (isLoading || !userData) {
        return <div className="flex items-center justify-center min-h-screen">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>;
    }

    const renderStudyTab = () => (
        <div className="space-y-6">
            {/* Test Summary Preview Button - Development Only */}
            {process.env.NODE_ENV === 'development' && (
                <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 border-l-4 border-purple-500">
                    <div className="flex items-center justify-between">
                        <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                            Test Summary Preview
                        </h2>
                        <span className="text-xs font-medium text-purple-600 dark:text-purple-400 bg-purple-100 dark:bg-purple-900/30 px-2 py-1 rounded">
                            Development Only
                        </span>
                    </div>
                    <p className="text-sm text-gray-600 dark:text-gray-400 mt-2 mb-4">
                        This preview button is only visible in development mode.
                    </p>
                    <button
                        onClick={() => navigate('/quiz-summary-preview')}
                        className="inline-flex items-center px-4 py-2 bg-purple-500 hover:bg-purple-600 text-white rounded-lg transition-colors"
                    >
                        <BarChart2 className="w-4 h-4 mr-2" />
                        View Sample Quiz Summary
                    </button>
                </div>
            )}

            {/* Active Quizzes - Moved to top */}
            {userData?.quizzes && Object.keys(userData.quizzes).length > 0 && (
                <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 border-l-4 border-blue-500">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                        Continue Your Progress
                    </h2>
                    <QuizActions isSubscribed={userData.isSubscribed} />
                </div>
            )}

            {/* Study Stats Overview */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <StatCard
                    title="Questions Attempted"
                    value={userData.totalQuestionsAttempted || 0}
                    icon={<Target className="w-6 h-6" />}
                />
                <StatCard
                    title="Study Streak"
                    value={`${userData.studyStreak || 0} days`}
                    icon={<Clock className="w-6 h-6" />}
                />
                <StatCard
                    title="Mastery Level"
                    value={`${Math.round((userData.masteryLevel || 0) * 100)}%`}
                    icon={<Brain className="w-6 h-6" />}
                />
            </div>

            {/* Quick Actions */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <QuickActionCard
                    icon={<Target className="w-6 h-6" />}
                    title="Practice Test"
                    description="Take a full-length practice exam"
                    onClick={() => setState(prev => ({ ...prev, selectedTab: 'practice' }))}
                />
                <QuickActionCard
                    icon={<Brain className="w-6 h-6" />}
                    title="Flashcards"
                    description="Review key concepts and terms"
                    onClick={() => document.getElementById('flashcards')?.scrollIntoView({ behavior: 'smooth' })}
                />
                <QuickActionCard
                    icon={<BarChart2 className="w-6 h-6" />}
                    title="Performance"
                    description="Track your progress"
                    onClick={() => setState(prev => ({ ...prev, selectedTab: 'progress' }))}
                />
            </div>

            {/* Study Options */}
            <QuizErrorBoundary>
                <StudyOptionsComponent 
                    userData={userData}
                    initializeQuizWithParams={initializeQuizWithParams}
                />
            </QuizErrorBoundary>

            {/* Flashcards Section */}
            <div id="flashcards">
                <FlashCards 
                    wrongAnswers={state.wrongAnswers}
                    onUpdateExplanation={(questionId: number, explanation: string) => 
                        updateWrongAnswerExplanation(currentUser!.uid, questionId, explanation)
                    }
                    onMarkComplete={(questionId: number) => 
                        removeWrongAnswer(currentUser!.uid, questionId)
                    }
                />
            </div>
        </div>
    );

    const renderProgressTab = () => (
        <div className="space-y-6">
            {/* Progress Overview */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <StatCard
                    title="Average Score"
                    value={`${Math.round(userData.averageScore || 0)}%`}
                    icon={<BarChart2 className="w-6 h-6" />}
                    trend={userData.scoreTrend}
                />
                <StatCard
                    title="Tests Completed"
                    value={userData.completedTests || 0}
                    icon={<Target className="w-6 h-6" />}
                />
                <StatCard
                    title="Study Hours"
                    value={`${userData.totalStudyHours || 0}h`}
                    icon={<Clock className="w-6 h-6" />}
                    trend={userData.studyHoursTrend}
                />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Test Scores */}
                <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
                    <TestScores testResults={userData.latestTestResult || undefined} />
                </div>

                {/* Exam Countdown */}
                <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
                    <div className="flex justify-between items-start mb-4">
                        <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">Exam Countdown</h2>
                        {userData?.profile?.examDate && (
                            <button
                                onClick={() => setState(prev => ({ ...prev, showDatePicker: true }))}
                                className="text-sm text-blue-600 hover:text-blue-700 dark:text-blue-400"
                            >
                                Change Date
                            </button>
                        )}
                    </div>
                    {userData?.profile?.examDate ? (
                        <>
                            {(() => {
                                const countdown = calculateExamCountdown(userData.profile.examDate!);
                                return (
                                    <div>
                                        <div className={`flex items-baseline ${
                                            countdown.status === 'past' 
                                                ? 'text-gray-500 dark:text-gray-400'
                                                : countdown.status === 'today'
                                                ? 'text-green-600 dark:text-green-400'
                                                : 'text-blue-600 dark:text-blue-400'
                                        }`}>
                                            <p className="text-4xl font-bold mr-2">
                                                {countdown.daysRemaining}
                                            </p>
                                            <p className="text-xl">
                                                {countdown.status === 'past' ? 'days ago' : 'days'}
                                            </p>
                                        </div>
                                        <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                                            Exam date: {formatExamDate(userData.profile.examDate)}
                                        </p>
                                        <p className={`mt-4 text-sm ${
                                            countdown.status === 'past'
                                                ? 'text-gray-500 dark:text-gray-400'
                                                : countdown.status === 'today'
                                                ? 'text-green-600 dark:text-green-400 font-medium'
                                                : 'text-blue-600 dark:text-blue-400'
                                        }`}>
                                            {countdown.message}
                                        </p>
                                        {countdown.status === 'past' && (
                                            <button
                                                onClick={() => setState(prev => ({ ...prev, showDatePicker: true }))}
                                                className="mt-4 inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                            >
                                                Set New Exam Date
                                            </button>
                                        )}
                                    </div>
                                );
                            })()}
                        </>
                    ) : (
                        <div className="text-center py-4">
                            <p className="text-2xl font-bold text-gray-500 dark:text-gray-400 mb-4">Not scheduled</p>
                            <button
                                onClick={() => setState(prev => ({ ...prev, showDatePicker: true }))}
                                className="inline-flex items-center px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors"
                            >
                                <CalendarIcon className="w-4 h-4 mr-2" />
                                Set Exam Date
                            </button>
                        </div>
                    )}
                </div>
            </div>

            {/* Adaptive Insights */}
            <AdaptiveInsightsCard adaptiveLearning={userData.adaptiveLearning} />

            {/* Share Progress */}
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
                <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200 flex items-center">
                    <Share2 className="mr-2" /> Share Your Progress
                </h2>
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                    {state.latestResult 
                        ? `Share your ${state.latestResult.score}/${state.latestResult.totalQuestions} score!`
                        : 'Take a quiz to share your results!'}
                </p>
                <div className="flex space-x-2">
                    <SocialShareButton
                        platform="x"
                        onClick={() => handleShare('x', buildShareText(state.latestResult))}
                        disabled={!state.latestResult}
                    />
                    <SocialShareButton
                        platform="facebook"
                        onClick={() => handleShare('facebook', buildShareText(state.latestResult))}
                        disabled={!state.latestResult}
                    />
                    <SocialShareButton
                        platform="linkedin"
                        onClick={() => handleShare('linkedin', buildShareText(state.latestResult))}
                        disabled={!state.latestResult}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
                <header className="mb-8">
                    <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100">
                        Welcome Back, {userData?.profile?.name || 'Future ASWB Graduate'}!
                    </h1>
                    <p className="mt-2 text-gray-600 dark:text-gray-400">
                        Your journey to ASWB success continues here.
                    </p>
                </header>

                {/* Tab Navigation */}
                <nav className="flex space-x-4 mb-8 border-b border-gray-200 dark:border-gray-700">
                    <TabButton
                        active={state.selectedTab === 'study'}
                        onClick={() => setState(prev => ({ ...prev, selectedTab: 'study' }))}
                        icon={<BookOpen className="w-4 h-4" />}
                        label="Study"
                    />
                    <TabButton
                        active={state.selectedTab === 'progress'}
                        onClick={() => setState(prev => ({ ...prev, selectedTab: 'progress' }))}
                        icon={<BarChart2 className="w-4 h-4" />}
                        label="Progress"
                    />
                    <TabButton
                        active={state.selectedTab === 'practice'}
                        onClick={() => setState(prev => ({ ...prev, selectedTab: 'practice' }))}
                        icon={<Target className="w-4 h-4" />}
                        label="Practice Tests"
                    />
                </nav>

                {/* Tab Content */}
                <div className="space-y-6">
                    {state.selectedTab === 'study' && renderStudyTab()}
                    {state.selectedTab === 'progress' && renderProgressTab()}
                    {state.selectedTab === 'practice' && (
                        <QuizErrorBoundary>
                            <StudyOptionsComponent 
                                userData={userData}
                                initializeQuizWithParams={initializeQuizWithParams}
                            />
                        </QuizErrorBoundary>
                    )}
                </div>
            </div>

            {/* Modals */}
            {state.showOnboarding && (
                <OnboardingModal 
                    onClose={() => setState(prev => ({ ...prev, showOnboarding: false }))} 
                />
            )}

            {state.showDatePicker && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
                    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full">
                        <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">
                            Select Exam Date
                        </h3>
                        <input
                            type="date"
                            min={new Date().toISOString().split('T')[0]}
                            onChange={(e) => handleDateSelect(new Date(e.target.value))}
                            className="w-full p-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100"
                        />
                        <button
                            onClick={() => setState(prev => ({ ...prev, showDatePicker: false }))}
                            className="mt-4 w-full px-4 py-2 bg-gray-200 dark:bg-gray-700 rounded-lg"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

// Helper Components
const QuickActionCard: React.FC<{
    icon: React.ReactNode;
    title: string;
    description: string;
    onClick: () => void;
}> = ({ icon, title, description, onClick }) => (
    <button
        onClick={onClick}
        className="flex items-center p-4 bg-white dark:bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow border border-gray-200 dark:border-gray-700"
    >
        <div className="mr-4 text-blue-600 dark:text-blue-400">
            {icon}
        </div>
        <div className="text-left">
            <h3 className="font-semibold text-gray-900 dark:text-gray-100">{title}</h3>
            <p className="text-sm text-gray-600 dark:text-gray-400">{description}</p>
        </div>
        <ChevronRight className="ml-auto w-5 h-5 text-gray-400" />
    </button>
);

const TabButton: React.FC<{
    active: boolean;
    onClick: () => void;
    icon: React.ReactNode;
    label: string;
}> = ({ active, onClick, icon, label }) => (
    <button
        onClick={onClick}
        className={`flex items-center space-x-2 px-4 py-2 font-medium text-sm rounded-t-lg
            ${active
                ? 'text-blue-600 dark:text-blue-400 border-b-2 border-blue-600 dark:border-blue-400'
                : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
            }`}
    >
        {icon}
        <span>{label}</span>
    </button>
);

const SocialShareButton: React.FC<{
    platform: 'x' | 'facebook' | 'linkedin';
    onClick: () => void;
    disabled: boolean;
}> = ({ platform, onClick, disabled }) => {
    const getIcon = () => {
        switch (platform) {
            case 'x':
                return (
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                    </svg>
                );
            case 'facebook':
                return (
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                        <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                    </svg>
                );
            case 'linkedin':
                return (
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                    </svg>
                );
        }
    };

    const getBackgroundColor = () => {
        switch (platform) {
            case 'x': return 'bg-black hover:bg-gray-900';
            case 'facebook': return 'bg-blue-600 hover:bg-blue-700';
            case 'linkedin': return 'bg-blue-700 hover:bg-blue-800';
        }
    };

    return (
        <button 
            onClick={onClick}
            disabled={disabled}
            className={`${getBackgroundColor()} text-white p-2 rounded-full transition-colors disabled:opacity-50 disabled:cursor-not-allowed`}
        >
            {getIcon()}
        </button>
    );
};

const StatCard: React.FC<{
    title: string;
    value: string | number;
    icon: React.ReactNode;
    trend?: {
        direction: 'up' | 'down';
        percentage: number;
    };
}> = ({ title, value, icon, trend }) => (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 flex items-center">
        <div className="mr-4 text-blue-600 dark:text-blue-400">
            {icon}
        </div>
        <div>
            <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">{title}</h3>
            <div className="flex items-center">
                <p className="text-2xl font-bold text-gray-900 dark:text-gray-100">{value}</p>
                {trend && (
                    <span className={`ml-2 text-sm ${
                        trend.direction === 'up' 
                            ? 'text-green-500' 
                            : 'text-red-500'
                    }`}>
                        {trend.direction === 'up' ? '↑' : '↓'} {trend.percentage}%
                    </span>
                )}
            </div>
        </div>
    </div>
);

export default Dashboard;
