import React, { createContext, useContext, useReducer, useMemo, useCallback, useRef, useEffect, Dispatch } from 'react';
import { QuizState, QuizAction } from '../../../models/QuizTypes';
import { QuizType } from '../../../models/SharedTypes';
import { quizReducer, initialState } from './QuizReducer';
import { useAdaptiveEngine } from '../../../contexts/AdaptiveEngineContext';
import { logger } from '../../../utils/logger';
import { getQuizSummary } from '../../../utils/quiz/core';
import { saveQuizProgress, fetchQuestions } from '../../../utils/firebase/quiz';
import { QuizError } from '../../../utils/quiz/errors';
import { QuizProgress } from '../../../models/UserTypes';
import { Question } from '../../../utils/quiz/types';
import { AdaptiveState } from '../../../models/AdaptiveState';
import { AdaptiveLearningEngine } from '../../../utils/adaptiveLearning';

interface QuizContextType {
  state: QuizState;
  dispatch: Dispatch<QuizAction>;
  saveAndContinue: () => Promise<void>;
}

const QuizContext = createContext<QuizContextType | undefined>(undefined);

export const QuizProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { engine } = useAdaptiveEngine();
  const saveInProgress = useRef(false);
  const completedRef = useRef(false);

  const initialAdaptiveState: AdaptiveState = {
    engine: engine || new AdaptiveLearningEngine(null),
    currentDifficulty: 5,
    categoryStrengths: {},
    insights: {
      weakCategories: [],
      recommendedDifficulty: 5,
      suggestedPracticeFrequency: 'daily',
      performanceTrend: 'steady',
      timeManagement: {
        averageQuestionTime: 0,
        recommendedTimePerQuestion: 90,
        timeManagementAdvice: 'Take your time'
      }
    },
    recentlyAskedQuestions: [],
    consecutiveCorrect: 0,
    consecutiveIncorrect: 0,
    totalQuestionsAttempted: 0
  };

  const [state, dispatch] = useReducer<React.Reducer<QuizState, QuizAction>>(quizReducer, {
    ...initialState,
    adaptiveState: initialAdaptiveState
  });

  useEffect(() => {
    if (state.progress?.completed && !completedRef.current) {
      completedRef.current = true;
      logger.debug('QuizProvider', 'Quiz marked as completed, preventing reset');
    }
  }, [state.progress?.completed]);

  const wrappedDispatch = useCallback((action: QuizAction) => {
    if (completedRef.current && action.type !== 'QUIZ_COMPLETE') {
      logger.warn('QuizProvider', 'Attempted state change after completion', { actionType: action.type });
      return;
    }
    dispatch(action);
  }, []);

  const saveAndContinue = useCallback(async () => {
    if (saveInProgress.current) {
      logger.warn('QuizContext', 'Save already in progress, skipping');
      return;
    }
    
    saveInProgress.current = true;
    let retryCount = 0;
    const MAX_RETRIES = 3;
    
    try {
      if (!state.progress) {
        logger.warn('QuizContext', 'Cannot continue - missing progress');
        return;
      }

      const currentIndex = state.progress.currentQuestionIndex ?? 0;
      const totalQuestions = state.progress.questionIds?.length ?? 0;
      const loadedQuestions = state.progress.questions?.length ?? 0;
      const isLastQuestion = currentIndex >= totalQuestions - 1;
      const isLastLoadedQuestion = currentIndex >= loadedQuestions - 1;

      // Save current progress before proceeding
      const currentProgress = {
        ...state.progress,
        lastUpdated: new Date().toISOString(),
        completed: isLastQuestion
      };
      
      // Use METADATA for incremental updates, ANSWER for completed questions
      const updateType = state.showResult ? 'ANSWER' : 'METADATA';
      
      // Add retry logic for save operations
      while (retryCount < MAX_RETRIES) {
        try {
          await saveQuizProgress(currentProgress, updateType);
          break;
        } catch (error) {
          if (error instanceof Error && error.message.includes('Missing next question batch')) {
            // If we hit the batch transition error but have the next question, proceed anyway
            const nextIndex = currentIndex + 1;
            if (state.progress.questions?.[nextIndex]) {
              logger.info('QuizContext', 'Proceeding with available next question despite batch error');
              break;
            }
          }
          retryCount++;
          if (retryCount === MAX_RETRIES) throw error;
          await new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
        }
      }

      // Only process next question logic if we haven't completed the quiz
      if (!isLastQuestion && state.adaptiveState?.engine) {
        const questions = state.progress.questions ?? [];
        const currentQuestion = questions[currentIndex];
        if (!currentQuestion) {
          logger.error('QuizContext', 'Current question not found', {
            currentIndex,
            loadedQuestions: questions.length
          });
          throw new Error('Current question not found');
        }

        const isCorrect = currentQuestion.selected_answer === currentQuestion.correct_answer;
        const timeSpent = Date.now() - (state.progress.lastQuestionStartTime || 0);

        state.adaptiveState.engine.updateWithResult(currentQuestion, isCorrect, timeSpent);
        
        // Add wrong answer if incorrect
        const updatedWrongAnswers = [...(state.progress.wrongAnswers || [])];
        if (!isCorrect) {
          updatedWrongAnswers.push({
            question: {
              id: currentQuestion.id,
              category: currentQuestion.category,
              question: currentQuestion.question,
              correct_answer: currentQuestion.correct_answer,
              selected_answer: currentQuestion.selected_answer || ''
            },
            explanation: currentQuestion.explanation || '',
            date: new Date().toISOString(),
            isReviewed: false,
            attempts: 1
          });
        }
        
        const nextIndex = currentIndex + 1;
        const BATCH_SIZE = 10;
        const currentBatchIndex = Math.floor(currentIndex / BATCH_SIZE);
        const nextBatchIndex = Math.floor(nextIndex / BATCH_SIZE);
        
        // Check if we need to load the next batch
        let nextQuestions = [...questions];
        
        // Load next batch if we're crossing a batch boundary
        if (nextBatchIndex > currentBatchIndex) {
          const batchStartIndex = nextBatchIndex * BATCH_SIZE;
          const batchEndIndex = Math.min(batchStartIndex + BATCH_SIZE, totalQuestions);
          const batchIds = state.progress.questionIds?.slice(batchStartIndex, batchEndIndex) || [];

          if (batchIds.length > 0) {
            try {
              logger.debug('QuizContext', 'Loading next batch of questions', {
                currentIndex,
                nextIndex,
                batchStartIndex,
                batchEndIndex,
                totalQuestions,
                currentBatchSize: questions.length,
                nextBatchSize: batchIds.length
              });

              // Load next batch before saving current progress
              const newQuestions = await fetchQuestions({
                testType: state.progress.testType,
                questionIds: batchIds
              });

              // Validate loaded questions but don't block on full batch
              if (newQuestions.length === 0) {
                logger.error('QuizContext', 'Failed to load next batch - no questions returned', {
                  batchIds,
                  currentIndex,
                  nextIndex
                });
                // Only throw if we don't have the next question
                if (!nextQuestions[nextIndex]) {
                  throw new Error('Failed to load next question');
                }
              } else {
                // Add successfully loaded questions
                for (let i = 0; i < newQuestions.length; i++) {
                  nextQuestions[batchStartIndex + i] = newQuestions[i];
                }
              }

              logger.debug('QuizContext', 'Batch load status', {
                loadedCount: newQuestions.length,
                expectedCount: batchIds.length,
                hasNextQuestion: !!nextQuestions[nextIndex]
              });
            } catch (error) {
              logger.error('QuizContext', 'Failed to handle batch transition', {
                error,
                currentIndex,
                nextIndex,
                batchStartIndex,
                batchEndIndex
              });
              // Only throw if we don't have the next question
              if (!nextQuestions[nextIndex]) {
                throw error;
              }
            }
          }
        }

        // Create preserved progress with all required fields
        const preservedProgress = {
          ...state.progress,
          currentQuestionIndex: nextIndex,
          lastQuestionStartTime: Date.now(),
          date: state.progress.date || new Date().toISOString(),
          lastUpdated: new Date().toISOString(),
          quizId: state.progress.quizId,
          initialQuestionIndex: state.progress.initialQuestionIndex ?? 0,
          score: state.progress.score ?? 0,
          questions: nextQuestions,
          questionIds: state.progress.questionIds,
          wrongAnswers: updatedWrongAnswers,
          type: state.progress.type || QuizType.SAMPLE,
          category: state.progress.category || '',
          completed: false,
          adaptiveState: state.progress.adaptiveState ?? initialAdaptiveState,
          testType: state.progress.testType,
          mode: state.progress.mode
        };

        // Ensure we have the next question before proceeding
        if (!preservedProgress.questions?.[nextIndex]) {
          logger.error('QuizContext', 'Next question not available', {
            nextIndex,
            availableQuestions: preservedProgress.questions?.length
          });
          throw new Error('Next question not available');
        }

        // Now check if this is truly the last question based on total questionIds
        const isFinalQuestion = nextIndex >= totalQuestions - 1;
        if (isFinalQuestion) {
          logger.debug('QuizContext', 'Processing final question', {
            nextIndex,
            totalQuestions,
            currentBatchSize: nextQuestions.length,
            totalQuestionIds: preservedProgress.questionIds?.length
          });

          const correctCount = totalQuestions - (preservedProgress.wrongAnswers?.length || 0);
          const finalScore = (correctCount / totalQuestions) * 100;
          const summary = getQuizSummary(preservedProgress);

          // Ensure final state is saved before marking complete
          const finalProgress = {
            ...preservedProgress,
            completed: true,
            score: finalScore,
            lastUpdated: new Date().toISOString(),
            quizId: preservedProgress.quizId || String(Date.now()) // Ensure quiz ID exists
          };

          // Log the final state for debugging
          logger.debug('QuizContext', 'Preparing final quiz state', {
            quizId: finalProgress.quizId,
            score: finalProgress.score,
            totalQuestions,
            wrongAnswers: finalProgress.wrongAnswers?.length,
            hasAdaptiveState: !!finalProgress.adaptiveState
          });

          // Save final state with retries
          retryCount = 0;
          while (retryCount < MAX_RETRIES) {
            try {
              await saveQuizProgress(finalProgress, 'ANSWER');
              break;
            } catch (error) {
              retryCount++;
              if (retryCount === MAX_RETRIES) throw error;
              await new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
            }
          }

          dispatch({
            type: 'QUIZ_COMPLETE',
            payload: {
              finalScore,
              timestamp: Date.now(),
              adaptiveState: state.adaptiveState,
              summary
            }
          });

          logger.success('QuizContext', 'Quiz completed successfully', { 
            finalScore,
            questionCount: totalQuestions 
          });
          
          return;
        }

        // Save first, then dispatch on success
        await saveQuizProgress(preservedProgress, 'ANSWER');

        // Get the current question from the loaded batch, with improved edge case handling
        const batchIndex = Math.floor(nextIndex / BATCH_SIZE);
        const relativeIndex = nextIndex - (batchIndex * BATCH_SIZE);
        
        logger.debug('QuizContext', 'Calculating next question indices', {
          nextIndex,
          batchIndex,
          relativeIndex,
          batchSize: BATCH_SIZE,
          loadedQuestions: preservedProgress.questions?.length,
          totalQuestions
        });

        // Validate that we have the correct batch loaded
        if (!preservedProgress.questions || preservedProgress.questions.length === 0) {
          logger.error('QuizContext', 'No questions loaded in current batch', {
            nextIndex,
            totalQuestions,
            hasQuestions: !!preservedProgress.questions
          });
          throw new Error('No questions loaded in current batch');
        }

        const nextQuestion = preservedProgress.questions[relativeIndex];

        if (!nextQuestion) {
          // Additional context for debugging
          const availableIndices = preservedProgress.questions.map((_, idx) => idx);
          logger.error('QuizContext', 'Next question not found in current batch', {
            nextIndex,
            relativeIndex,
            batchIndex,
            batchSize: preservedProgress.questions.length,
            totalQuestions,
            availableIndices,
            questionIds: preservedProgress.questions.map(q => q.id)
          });

          // Check if we need to load the next batch
          if (relativeIndex >= preservedProgress.questions.length) {
            logger.warn('QuizContext', 'Attempting to access question beyond current batch', {
              relativeIndex,
              currentBatchSize: preservedProgress.questions.length
            });
            throw new Error('Need to load next batch of questions');
          }

          throw new Error('Next question not found in current batch');
        }

        // Validate the question data
        if (!nextQuestion.id) {
          logger.error('QuizContext', 'Invalid question data', {
            question: nextQuestion,
            index: relativeIndex
          });
          throw new Error('Invalid question data');
        }

        dispatch({
          type: 'SAVE_AND_CONTINUE',
          payload: {
            timestamp: Date.now(),
            nextQuestion,
            preservedProgress,
            questionId: nextQuestion.id
          }
        });

        logger.debug('QuizContext', 'Dispatched SAVE_AND_CONTINUE', {
          nextIndex,
          relativeIndex,
          currentBatchSize: preservedProgress.questions?.length ?? 0,
          totalQuestions,
          batchNumber: batchIndex,
          questionId: nextQuestion.id,
          hasNextQuestion: !!nextQuestion
        });
      }
    } catch (error) {
      // Add retry logic for recoverable errors
      if (error instanceof QuizError && error.recoverable) {
        logger.warn('QuizContext', 'Recoverable error in saveAndContinue, will retry', { error });
        // Implement retry logic here
      } else {
        logger.error('QuizContext', 'Error in saveAndContinue', error);
        dispatch({ 
          type: 'SET_ERROR', 
          payload: error instanceof Error ? error : new Error('Failed to save progress') 
        });
      }
    } finally {
      saveInProgress.current = false;
    }
  }, [state, dispatch]);

  const value = useMemo<QuizContextType>(() => ({
    state,
    dispatch: wrappedDispatch,
    saveAndContinue
  }), [state, wrappedDispatch, saveAndContinue]);

  return (
    <QuizContext.Provider value={value}>
      {children}
    </QuizContext.Provider>
  );
};

export const useQuizContext = () => {
  const context = useContext(QuizContext);
  if (!context) {
    throw new Error('useQuizContext must be used within a QuizProvider');
  }
  return context;
};